import { addMonths, startOfDay, subDays } from 'date-fns';

import {
  formatIsoLocalDate,
  formatIsoLocalDateTime,
  getUtcEndOfGoalMonth,
  parseGoalUpdatesFeedStartDate,
  tzDate,
  tzWorkspaceTimezoneDate,
} from '@townsquare/date-utils';
import { RelayResourceConfig, createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';
import { isNavRefreshEnabled } from '@townsquare/stat-sig/nav4';
import { tqlQueryFromInput } from '@townsquare/tql/query';
import { ComparatorOperator } from '@townsquare/tql/types';

import GoalUpdatesViewQuery, {
  type GoalUpdatesViewQuery as GoalUpdatesViewQueryType,
} from '../GoalUpdatesView/__generated__/GoalUpdatesViewQuery.graphql';
import { getUpdateStateFilterFromQueryParams } from '../components/UpdateStateFilter';

export const goalUpdatesResource = createRelayResource<GoalUpdatesViewQueryType>({
  getQuery: (routerContext, resourceContext): RelayResourceConfig<GoalUpdatesViewQueryType> => {
    const context = resourceContext as ResourceContext;
    const utcStartDate = parseGoalUpdatesFeedStartDate(routerContext.query.start);
    const utcPrevDate = addMonths(utcStartDate, -1);
    const utcEndDate = getUtcEndOfGoalMonth(utcStartDate);

    const sidebarStartDate = formatIsoLocalDateTime(subDays(startOfDay(tzDate()), 7));
    const sidebarEndDate = formatIsoLocalDateTime(tzDate());

    const ownGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'owner', fieldValue: context.user.accountId, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
    });
    const newGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'creationDate', fieldValue: sidebarStartDate, comparator: ComparatorOperator.GTE },
        { fieldName: 'creationDate', fieldValue: sidebarEndDate, comparator: ComparatorOperator.LTE },
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
      ],
    });
    const completedGoalsTqlQuery = tqlQueryFromInput({
      input: [
        { fieldName: 'archived', fieldValue: false, comparator: ComparatorOperator.EQUALS },
        { fieldName: 'phase', fieldValue: 'done', comparator: ComparatorOperator.EQUALS },
        {
          fieldName: 'targetDate',
          fieldValue: formatIsoLocalDate(tzWorkspaceTimezoneDate(sidebarStartDate)),
          comparator: ComparatorOperator.GTE,
        },
        {
          fieldName: 'targetDate',
          fieldValue: formatIsoLocalDate(tzWorkspaceTimezoneDate(sidebarEndDate)),
          comparator: ComparatorOperator.LTE,
        },
      ],
    });

    return {
      query: GoalUpdatesViewQuery,
      variables: {
        workspaceId: context.workspace.globalId || '',
        workspaceUUID: context.workspace.UUID || '',
        prevDate: formatIsoLocalDateTime(utcPrevDate),
        startDate: formatIsoLocalDateTime(utcStartDate),
        endDate: formatIsoLocalDateTime(utcEndDate),
        stateFilter: getUpdateStateFilterFromQueryParams(routerContext.query.filter),
        ownGoalsTqlQuery: ownGoalsTqlQuery,
        newGoalsTqlQuery: newGoalsTqlQuery,
        completedGoalsTqlQuery: completedGoalsTqlQuery,
        isNavRefreshEnabled: isNavRefreshEnabled(context),
      },
      cacheKey: 'goalUpdates',
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
