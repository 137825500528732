import { Grid, xcss } from '@atlaskit/primitives';
import { media } from '@atlaskit/primitives/responsive';
import React, { ReactNode } from 'react';
import { FallbackProps } from 'react-error-boundary';
import { LoadMoreFn } from 'react-relay';
import { OperationType } from 'relay-runtime';

import { LoadingState } from '@townsquare/loading-state';
import { PaginatedPage } from '@townsquare/pagination';
import { useIsHomeVisualRefreshEnabled } from '@townsquare/stat-sig/nav4';

import { ScrollingResultsContainer, SearchResultsContainer } from './styles';

export interface ScrollingResultsScreenProps<T extends OperationType> {
  loadMore: LoadMoreFn<T>;
  hasMore?: boolean;
  isLoading: boolean;
  resultCount: number;
  resultName: 'teams' | 'people' | 'kudos';
  resultList: JSX.Element[];
  renderFallback: (fallbackProps: FallbackProps) => ReactNode;
  pageSize: number;
  loadingState?: JSX.Element;
}

const gridStyles = xcss({
  gridTemplateColumns: 'repeat(auto-fill, minmax(320px, 1fr))',
  gap: 'space.100',
  [media.above.md]: {
    gap: 'space.200',
  },
});

export const ScrollingResultsScreen = <QueryType extends OperationType>({
  loadMore,
  hasMore,
  isLoading,
  resultList,
  resultName,
  loadingState = <LoadingState />,
  renderFallback,
  pageSize,
}: ScrollingResultsScreenProps<QueryType>) => {
  const enableHomeVisualRefresh = useIsHomeVisualRefreshEnabled();

  return (
    <ScrollingResultsContainer>
      <PaginatedPage<QueryType>
        loadMore={loadMore}
        hasMore={hasMore}
        isLoading={isLoading}
        renderLoader={loadingState}
        pageSize={pageSize}
        renderFallback={renderFallback}
      >
        {enableHomeVisualRefresh && resultName === 'teams' ? (
          <Grid xcss={gridStyles} testId="staff-dir-results-container">
            {resultList}
          </Grid>
        ) : (
          <SearchResultsContainer data-testid="staff-dir-results-container">{resultList}</SearchResultsContainer>
        )}
      </PaginatedPage>
    </ScrollingResultsContainer>
  );
};
