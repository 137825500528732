export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  DateTime: string;
  URL: string;
  UUID: string;
  Date: string;
  Instant: any;
  URI: any;
  JSONString: string;
};

export enum AccessControlCapability {
  Access = 'ACCESS',
  Administer = 'ADMINISTER',
  Create = 'CREATE'
}

export enum AccountIdentifierType {
  AtlassianId = 'ATLASSIAN_ID',
  Base64Hash = 'BASE64_HASH',
  Unknown = 'UNKNOWN'
}

export enum AccountStatus {
  Active = 'active',
  Closed = 'closed',
  Inactive = 'inactive'
}

export type addGoalProjectLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type addGoalProjectLinkPayload = {
  __typename?: 'addGoalProjectLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  project?: Maybe<Project>;
};

export type addGoalTeamLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalKey: Scalars['String'];
  teamId: Scalars['ID'];
};

export type addGoalTeamLinkPayload = {
  __typename?: 'addGoalTeamLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goalTeamEdge?: Maybe<GoalTeamEdge>;
};

export type AddMembersResult = {
  __typename?: 'AddMembersResult';
  aaid?: Maybe<Scalars['String']>;
  addedAsMember: Scalars['Boolean'];
  addedAsWatcher: Scalars['Boolean'];
  error?: Maybe<Scalars['String']>;
};

export type addMetricTargetInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  createMetric?: Maybe<createMetricInput>;
  metricId?: Maybe<Scalars['ID']>;
  owningEntityId: Scalars['ID'];
  startValue: Scalars['Float'];
  targetValue: Scalars['Float'];
};

export type addMetricTargetPayload = {
  __typename?: 'addMetricTargetPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  owningNode: Node;
};

export type addProjectMemberInput = {
  accountId?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  inviteToTeam?: Maybe<Scalars['String']>;
  member?: Maybe<UserIdentifierInput>;
  projectKey: Scalars['String'];
};

export type addProjectMemberPayload = {
  __typename?: 'addProjectMemberPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type addProjectMembersInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<UserIdentifierInput>>>;
  projectKey: Scalars['String'];
};

export type addProjectMembersPayload = {
  __typename?: 'addProjectMembersPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  results?: Maybe<Array<Maybe<AddMembersResult>>>;
};

export type addProjectTeamLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  members?: Maybe<Array<Maybe<Scalars['String']>>>;
  projectKey: Scalars['String'];
  teamId: Scalars['String'];
};

export type addProjectTeamLinkPayload = {
  __typename?: 'addProjectTeamLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  team?: Maybe<Team>;
};

export type addProjectViewersInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  viewers: Array<Maybe<UserIdentifierInput>>;
};

export type addProjectViewersPayload = {
  __typename?: 'addProjectViewersPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type addTagsByNameInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tagNames: Array<Scalars['String']>;
};

export type addTagsByNamePayload = {
  __typename?: 'addTagsByNamePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type addTagsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tagUuids: Array<Maybe<Scalars['String']>>;
};

export type addTagsPayload = {
  __typename?: 'addTagsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type addTeamHealthRatingsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitorUUID: Scalars['UUID'];
  teamHealthRatings: Array<Maybe<TeamHealthMonitorRatingInput>>;
};

export type addTeamHealthRatingsPayload = {
  __typename?: 'addTeamHealthRatingsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitor?: Maybe<TeamHealthMonitor>;
};

export type addTeamMembersInput = {
  accountIds: Array<Maybe<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  teamId: Scalars['String'];
};

export type addTeamMembersPayload = {
  __typename?: 'addTeamMembersPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<MembershipMutationError>>>;
  team?: Maybe<Team>;
};

export type addTeamWatcherInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type addTeamWatcherPayload = {
  __typename?: 'addTeamWatcherPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type addUserTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  tagUuid: Scalars['UUID'];
};

export type addUserTagPayload = {
  __typename?: 'addUserTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type addWatcherByAccountIdInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type addWatcherByAccountIdPayload = {
  __typename?: 'addWatcherByAccountIdPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isPendingMember: Scalars['Boolean'];
  isWatching: Scalars['Boolean'];
};

export type addWatcherByAccountIdsAggInput = {
  accountIds: Array<Maybe<Scalars['String']>>;
  ari: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type addWatcherByAccountIdsInput = {
  accountIds: Array<Maybe<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type addWatcherByAccountIdsPayload = {
  __typename?: 'addWatcherByAccountIdsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<AddWatcherResult>>>;
};

export type addWatcherInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type addWatcherPayload = {
  __typename?: 'addWatcherPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type AddWatcherResult = {
  __typename?: 'AddWatcherResult';
  accountId?: Maybe<Scalars['String']>;
  isPendingMember: Scalars['Boolean'];
  isWatching: Scalars['Boolean'];
};

export type AdminUserOrganization = Node & {
  __typename?: 'AdminUserOrganization';
  id: Scalars['ID'];
  orgId?: Maybe<Scalars['String']>;
};

export type AdminUserOrganizationConnection = {
  __typename?: 'AdminUserOrganizationConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<AdminUserOrganizationEdge>>>;
  pageInfo: PageInfo;
};

export type AdminUserOrganizationEdge = {
  __typename?: 'AdminUserOrganizationEdge';
  cursor: Scalars['String'];
  node?: Maybe<AdminUserOrganization>;
};

export type AggregatedInfoDto = {
  __typename?: 'AggregatedInfoDto';
  numContributors: Scalars['Int'];
  numProjects: Scalars['Int'];
};

export type allowedValueChangesInput = {
  text?: Maybe<Array<customFieldTextAllowedValueChange>>;
};

export type archiveGoalAggInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type AtlassianHomeBootstrap = {
  __typename?: 'AtlassianHomeBootstrap';
  cloudIds?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  orgId: Scalars['String'];
  userIsOrgAdmin: Scalars['Boolean'];
  workspaces?: Maybe<Array<Maybe<WorkspaceSummary>>>;
};

export type BaseStatus = {
  __typename?: 'BaseStatus';
  localizedLabel?: Maybe<LocalizationField>;
  score?: Maybe<Scalars['Float']>;
  value?: Maybe<Scalars['String']>;
};

export type Boost = Node & {
  __typename?: 'Boost';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  editDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isOwner?: Maybe<Scalars['Boolean']>;
  palette?: Maybe<BoostPalette>;
  text?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type BoostConnection = {
  __typename?: 'BoostConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<BoostEdge>>>;
  pageInfo: PageInfo;
};

export type BoostEdge = {
  __typename?: 'BoostEdge';
  cursor: Scalars['String'];
  node?: Maybe<Boost>;
};

export enum BoostPalette {
  Default = 'DEFAULT',
  Rainbow = 'RAINBOW'
}

export enum CanCreateFusionResult {
  CanCreate = 'CAN_CREATE',
  IntegrationNotInstalled = 'INTEGRATION_NOT_INSTALLED',
  IssueAlreadyLinked = 'ISSUE_ALREADY_LINKED',
  NotInSameOrg = 'NOT_IN_SAME_ORG',
  PermissionDenied = 'PERMISSION_DENIED',
  UnexpectedIssueType = 'UNEXPECTED_ISSUE_TYPE',
  UnknownError = 'UNKNOWN_ERROR'
}

export type CanCreateProjectFusionResponse = {
  __typename?: 'CanCreateProjectFusionResponse';
  canCreateFusionResult?: Maybe<CanCreateFusionResult>;
  issue?: Maybe<GqlJiraIssue>;
};

export type Capability = {
  __typename?: 'Capability';
  capability?: Maybe<AccessControlCapability>;
  capabilityContainer?: Maybe<CapabilityContainer>;
};

export enum CapabilityContainer {
  GoalsApp = 'GOALS_APP',
  ProjectsApp = 'PROJECTS_APP'
}

export type cloneGoalInput = {
  addProjects?: Maybe<Scalars['Boolean']>;
  addWatchers?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  name: Scalars['String'];
  ownerId: Scalars['String'];
};

export type cloneGoalPayload = {
  __typename?: 'cloneGoalPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type cloneProjectInput = {
  addLinks?: Maybe<Scalars['Boolean']>;
  addWatchers?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  projectId: Scalars['ID'];
};

export type cloneProjectPayload = {
  __typename?: 'cloneProjectPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type Comment = Node & {
  __typename?: 'Comment';
  ari: Scalars['String'];
  commentText?: Maybe<Scalars['String']>;
  container?: Maybe<CommentContainer>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  editDate?: Maybe<Scalars['DateTime']>;
  goal?: Maybe<Goal>;
  goalUpdate?: Maybe<GoalUpdate>;
  id: Scalars['ID'];
  project?: Maybe<Project>;
  projectUpdate?: Maybe<ProjectUpdate>;
  url?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type CommentConnection = {
  __typename?: 'CommentConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CommentEdge>>>;
  pageInfo: PageInfo;
};

export type CommentContainer = Goal | Kudos | Project;

export type CommentEdge = {
  __typename?: 'CommentEdge';
  cursor: Scalars['String'];
  node?: Maybe<Comment>;
};

export enum CommunicationHealth {
  Dormant = 'DORMANT',
  Healthy = 'HEALTHY',
  Unwell = 'UNWELL'
}

export type ComparatorsInput = {
  gt?: Maybe<Scalars['Int']>;
};

export type CompletionGoalState = {
  __typename?: 'CompletionGoalState';
  label?: Maybe<Scalars['String']>;
  localizedLabel?: Maybe<LocalizationField>;
  score?: Maybe<Scalars['Float']>;
  value?: Maybe<GoalStateValue>;
};


export type CompletionGoalState_labelArgs = {
  includeScore?: Maybe<Scalars['Boolean']>;
};

export type CompletionProjectState = {
  __typename?: 'CompletionProjectState';
  label?: Maybe<Scalars['String']>;
  localizedLabel?: Maybe<LocalizationField>;
  value?: Maybe<GoalStateValue>;
};

export type ConfluenceProductActivation = ProductActivation & {
  __typename?: 'ConfluenceProductActivation';
  cloudId?: Maybe<Scalars['String']>;
};

export type connectMsteamsChannelsByAriInput = {
  channels: Array<Maybe<connectMsteamsChannelsByAriInputChannel>>;
  clientMutationId?: Maybe<Scalars['String']>;
  installBot: Scalars['Boolean'];
  msTeamsTenantId: Scalars['String'];
  resourceAris: Array<Maybe<Scalars['String']>>;
  resourceType: Scalars['String'];
  userAzureId?: Maybe<Scalars['String']>;
};

export type connectMsteamsChannelsByAriInputChannel = {
  aadGroupId: Scalars['String'];
  msTeamsChannelId: Scalars['String'];
  msTeamsTeamId: Scalars['String'];
};

export type connectMsteamsChannelsByAriPayload = {
  __typename?: 'connectMsteamsChannelsByAriPayload';
  appInstallResults?: Maybe<Array<Maybe<connectMsteamsChannelsByAriPayloadAppInstallResult>>>;
  clientMutationId?: Maybe<Scalars['String']>;
  failedItems?: Maybe<Array<Maybe<connectMsteamsChannelsByAriPayloadFailedItem>>>;
  items?: Maybe<Array<Maybe<connectMsteamsChannelsByAriPayloadItem>>>;
};

export type connectMsteamsChannelsByAriPayloadAppInstallResult = {
  __typename?: 'connectMsteamsChannelsByAriPayloadAppInstallResult';
  aadGroupId?: Maybe<Scalars['String']>;
  error?: Maybe<connectMsteamsChannelsByAriPayloadAppInstallResultError>;
};

export type connectMsteamsChannelsByAriPayloadAppInstallResultError = {
  __typename?: 'connectMsteamsChannelsByAriPayloadAppInstallResultError';
  message?: Maybe<Scalars['String']>;
  reasonType?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type connectMsteamsChannelsByAriPayloadFailedItem = {
  __typename?: 'connectMsteamsChannelsByAriPayloadFailedItem';
  error?: Maybe<Scalars['String']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
};

export type connectMsteamsChannelsByAriPayloadFailedItemChannel = {
  __typename?: 'connectMsteamsChannelsByAriPayloadFailedItemChannel';
  aadGroupId?: Maybe<Scalars['String']>;
  msTeamsChannelId?: Maybe<Scalars['String']>;
  msTeamsTeamId?: Maybe<Scalars['String']>;
};

export type connectMsteamsChannelsByAriPayloadItem = {
  __typename?: 'connectMsteamsChannelsByAriPayloadItem';
  aadGroupId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['String']>;
  msTeamsChannelId?: Maybe<Scalars['String']>;
  msTeamsTeamId?: Maybe<Scalars['String']>;
  msTeamsTenantId?: Maybe<Scalars['String']>;
  resourceAri?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  subscriberAaid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
};

export type connectSlackChannelByAriInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  metadata?: Maybe<connectSlackChannelInputMetadata>;
  resourceAri: Scalars['String'];
  resourceType: Scalars['String'];
  slackChannelIds: Array<Maybe<Scalars['String']>>;
  slackTeamId: Scalars['String'];
  types: Array<Maybe<Scalars['String']>>;
};

export type connectSlackChannelByAriPayload = {
  __typename?: 'connectSlackChannelByAriPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  slackConnections?: Maybe<Array<Maybe<PostSlackConnectionByAri>>>;
};

export type connectSlackChannelInputMetadata = {
  fieldTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type Contributor = Team | User;

export type ContributorConnection = {
  __typename?: 'ContributorConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ContributorEdge>>>;
  pageInfo: PageInfo;
};

export type ContributorEdge = {
  __typename?: 'ContributorEdge';
  cursor: Scalars['String'];
  node?: Maybe<Contributor>;
};

export type CpusSearchUser = {
  __typename?: 'CpusSearchUser';
  account_id?: Maybe<Scalars['String']>;
  account_status?: Maybe<Scalars['String']>;
  department?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  highResolutionProfilePicture?: Maybe<HighResolutionProfilePicture>;
  job_title?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  reportCounts?: Maybe<ReportCounts>;
  teams?: Maybe<TeamConnection>;
  zoneinfo?: Maybe<Scalars['String']>;
};


export type CpusSearchUser_highResolutionProfilePictureArgs = {
  size?: Maybe<ProfilePictureSize>;
};


export type CpusSearchUser_teamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  organisationId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

export type CpusSearchUserConnection = {
  __typename?: 'CpusSearchUserConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CpusSearchUserEdge>>>;
  pageInfo: PageInfo;
};

export type CpusSearchUserEdge = {
  __typename?: 'CpusSearchUserEdge';
  cursor: Scalars['String'];
  node?: Maybe<CpusSearchUser>;
};

export type createBoostInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  entityId: Scalars['ID'];
  palette?: Maybe<BoostPalette>;
  text: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type createBoostPayload = {
  __typename?: 'createBoostPayload';
  boost?: Maybe<Boost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type createCommentInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  commentText: Scalars['JSONString'];
  entityId: Scalars['ID'];
};

export type createCommentPayload = {
  __typename?: 'createCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
};

export type createCustomFieldDefinitionDataInput = {
  number?: Maybe<createNumberCustomFieldDefinitionInput>;
  text?: Maybe<createTextCustomFieldDefinitionInput>;
  textSelect?: Maybe<createTextSelectCustomFieldDefinitionInput>;
  user?: Maybe<createUserCustomFieldDefinitionInput>;
};

export type CreateCustomFieldDefinitionError = CustomFieldNameAlreadyExistsError | CustomFieldsLimitReachedError;

export type createCustomFieldDefinitionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  data: createCustomFieldDefinitionDataInput;
};

export type createCustomFieldDefinitionPayload = {
  __typename?: 'createCustomFieldDefinitionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
  error?: Maybe<CreateCustomFieldDefinitionError>;
};

export type createDecisionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  entityId: Scalars['ID'];
  summary: Scalars['String'];
};

export type createDecisionPayload = {
  __typename?: 'createDecisionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  decision?: Maybe<Decision>;
};

export type createGoalAggInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  containerId: Scalars['String'];
  goalTypeAri?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  parentGoalAri?: Maybe<Scalars['String']>;
  targetDate?: Maybe<TargetDateInput>;
};

export type createGoalAndGoalFusionInput = {
  clientKey: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  issueId: Scalars['Int'];
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createGoalAndGoalFusionPayload = {
  __typename?: 'createGoalAndGoalFusionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<GoalAndGoalFusionCreationResult>;
};

export type createGoalAndGoalFusionV2Input = {
  issueAri: Scalars['String'];
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createGoalFusionInput = {
  clientKey?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  goalUuid: Scalars['UUID'];
  issueId: Scalars['Int'];
};

export type createGoalFusionPayload = {
  __typename?: 'createGoalFusionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<GoalFusionCreationResult>;
};

export type createGoalFusionV2Input = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalAri: Scalars['String'];
  issueAri: Scalars['String'];
};

export type createGoalInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalTypeId?: Maybe<Scalars['ID']>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  parentGoalId?: Maybe<Scalars['ID']>;
  startDate?: Maybe<Scalars['Date']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  workspaceId: Scalars['ID'];
};

export type createGoalPayload = {
  __typename?: 'createGoalPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type createGoalUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  goalId: Scalars['ID'];
  metricUpdate?: Maybe<Array<Maybe<metricUpdateInput>>>;
  phase?: Maybe<GoalPhase>;
  score?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  updateNotes?: Maybe<Array<Maybe<UpdateNoteInput>>>;
};

export type createGoalUpdatePayload = {
  __typename?: 'createGoalUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  update?: Maybe<GoalUpdate>;
};

export type createHelpPointerInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<IconInput>;
  link: Scalars['String'];
  name: Scalars['String'];
  newTeamName?: Maybe<Scalars['String']>;
  tagUuids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  teamId?: Maybe<Scalars['String']>;
  type: HelpPointerType;
};

export type createHelpPointerPayload = {
  __typename?: 'createHelpPointerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  helpPointer?: Maybe<HelpPointer>;
};

export type createIndividualKudosInput = {
  bgTheme?: Maybe<KudosBgThemeType>;
  clientMutationId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  jiraKudosFields?: Maybe<JiraKudosFields>;
  recipientId?: Maybe<Scalars['String']>;
  recipientIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  stillImageUrl?: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type createIndividualKudosPayload = {
  __typename?: 'createIndividualKudosPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  kudos?: Maybe<Kudos>;
};

export type createLearningInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  entityId: Scalars['ID'];
  summary: Scalars['String'];
  type?: Maybe<LearningType>;
};

export type createLearningPayload = {
  __typename?: 'createLearningPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  learning?: Maybe<Learning>;
};

export type createMetricInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
  subType?: Maybe<Scalars['String']>;
  type: MetricType;
  value: Scalars['Float'];
};

export type createMilestoneInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: Maybe<MilestoneStatus>;
  targetDate: Scalars['Date'];
  targetDateType?: Maybe<TargetDateType>;
  title: Scalars['String'];
};

export type createMilestonePayload = {
  __typename?: 'createMilestonePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  milestone?: Maybe<Milestone>;
};

export type createNumberCustomFieldDefinitionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entity: CustomFieldEntity;
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createProjectFromJiraIssueImportOptions = {
  assignees?: Maybe<Scalars['Boolean']>;
  watchers?: Maybe<Scalars['Boolean']>;
};

export type createProjectFromJiraIssueInput = {
  createProjectFusion?: Maybe<Scalars['Boolean']>;
  importFields?: Maybe<createProjectFromJiraIssueImportOptions>;
  jiraIssueAri: Scalars['String'];
};

export type createProjectFusionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  options?: Maybe<ProjectFusionOptionsInput>;
  projectKey: Scalars['String'];
  target: ProjectFusionTargetInput;
};

export type createProjectFusionPayload = {
  __typename?: 'createProjectFusionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<ProjectFusionCreationResult>;
};

export type createProjectFusionV2Input = {
  clientMutationId?: Maybe<Scalars['String']>;
  issueAri: Scalars['String'];
  options?: Maybe<ProjectFusionOngoingSyncOptionsInput>;
  projectAri: Scalars['String'];
};

export type createProjectInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  icon?: Maybe<IconInput>;
  import?: Maybe<createProjectFromJiraIssueInput>;
  name: Scalars['String'];
  ownerId: Scalars['String'];
  private?: Maybe<Scalars['Boolean']>;
  targetDate?: Maybe<Scalars['String']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  theMeasurement?: Maybe<Scalars['String']>;
  theWhat?: Maybe<Scalars['String']>;
  theWhy?: Maybe<Scalars['String']>;
  type?: Maybe<ProjectType>;
  workspaceGlobalId?: Maybe<Scalars['String']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};

export type createProjectLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
  url: Scalars['String'];
};

export type createProjectLinkPayload = {
  __typename?: 'createProjectLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type createProjectPayload = {
  __typename?: 'createProjectPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  createProjectFusionResponse?: Maybe<createProjectFusionPayload>;
  project?: Maybe<Project>;
};

export type createProjectUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  highlights?: Maybe<Array<Maybe<UpdateHighlightInput>>>;
  newPhase?: Maybe<ProjectPhase>;
  phase?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  updateNotes?: Maybe<Array<Maybe<UpdateNoteInput>>>;
};

export type createProjectUpdatePayload = {
  __typename?: 'createProjectUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  update?: Maybe<ProjectUpdate>;
};

export type createRelationshipsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  relationships: Array<RelationshipInput>;
};

export type CreateRelationshipsMutationError = MutationError & {
  __typename?: 'CreateRelationshipsMutationError';
  extensions?: Maybe<CreateRelationshipsMutationErrorExtension>;
  message?: Maybe<Scalars['String']>;
};

export type CreateRelationshipsMutationErrorExtension = MutationErrorExtension & {
  __typename?: 'CreateRelationshipsMutationErrorExtension';
  errorType?: Maybe<Scalars['String']>;
  relationship: Relationship;
  statusCode?: Maybe<Scalars['Int']>;
};

export type createRelationshipsPayload = {
  __typename?: 'createRelationshipsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<CreateRelationshipsMutationError>>;
  relationships?: Maybe<Array<Relationship>>;
  success: Scalars['Boolean'];
};

export type createRiskInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  entityId: Scalars['ID'];
  summary: Scalars['String'];
};

export type createRiskPayload = {
  __typename?: 'createRiskPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  risk?: Maybe<Risk>;
};

export type createTagForCloudIdInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
};

export type createTagForCloudIdPayload = {
  __typename?: 'createTagForCloudIdPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type createTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type createTagPayload = {
  __typename?: 'createTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type createTeamHealthMonitorInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  uuid?: Maybe<Scalars['UUID']>;
};

export type createTeamHealthMonitorPayload = {
  __typename?: 'createTeamHealthMonitorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitor?: Maybe<TeamHealthMonitor>;
};

export type createTeamInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  name: Scalars['String'];
  projectKey?: Maybe<Scalars['String']>;
};

export type createTeamKudosInput = {
  bgTheme?: Maybe<KudosBgThemeType>;
  clientMutationId?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  imageUrl?: Maybe<Scalars['String']>;
  jiraKudosFields?: Maybe<JiraKudosFields>;
  stillImageUrl?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type createTeamKudosPayload = {
  __typename?: 'createTeamKudosPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  kudos?: Maybe<Kudos>;
};

export type createTeamPayload = {
  __typename?: 'createTeamPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  team?: Maybe<Team>;
};

export type createTextCustomFieldDefinitionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entity: CustomFieldEntity;
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createTextSelectCustomFieldDefinitionInput = {
  allowedValues?: Maybe<Array<Scalars['String']>>;
  canSetMultipleValues?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entity: CustomFieldEntity;
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createUserCustomFieldDefinitionInput = {
  canSetMultipleValues?: Maybe<Scalars['Boolean']>;
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  entity: CustomFieldEntity;
  name: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};

export type createUserTagForCloudIdInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['ID'];
  matchable: Scalars['Boolean'];
  name: Scalars['String'];
};

export type createUserTagForCloudIdPayload = {
  __typename?: 'createUserTagForCloudIdPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type createUserTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  name: Scalars['String'];
  workspaceId: Scalars['ID'];
};

export type createUserTagPayload = {
  __typename?: 'createUserTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type createWorkspaceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type createWorkspacePayload = {
  __typename?: 'createWorkspacePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type CustomDirectoryViewColumn = Node & {
  __typename?: 'CustomDirectoryViewColumn';
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
};

export type CustomField = NumberCustomField | TextCustomField | TextSelectCustomField | UserCustomField;

export enum CustomFieldAllowedValueOperation {
  Add = 'ADD',
  Remove = 'REMOVE',
  Rename = 'RENAME'
}

export type CustomFieldConnection = {
  __typename?: 'CustomFieldConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CustomFieldEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldDefinition = NumberCustomFieldDefinition | TextCustomFieldDefinition | TextSelectCustomFieldDefinition | UserCustomFieldDefinition;

export type CustomFieldDefinitionConnection = {
  __typename?: 'CustomFieldDefinitionConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CustomFieldDefinitionEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldDefinitionEdge = {
  __typename?: 'CustomFieldDefinitionEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomFieldDefinition>;
};

export type CustomFieldDefinitionNode = {
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  linkedEntityTypes?: Maybe<Array<CustomFieldEntity>>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomFieldType>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type CustomFieldEdge = {
  __typename?: 'CustomFieldEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomField>;
};

export enum CustomFieldEntity {
  Goal = 'GOAL',
  Project = 'PROJECT'
}

export type CustomFieldNameAlreadyExistsError = {
  __typename?: 'CustomFieldNameAlreadyExistsError';
  name: Scalars['String'];
};

export type CustomFieldNode = {
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  definition?: Maybe<CustomFieldDefinition>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type CustomFieldNumberSavedValueConnection = {
  __typename?: 'CustomFieldNumberSavedValueConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CustomFieldNumberSavedValueEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldNumberSavedValueEdge = {
  __typename?: 'CustomFieldNumberSavedValueEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomFieldNumberSavedValueNode>;
};

export type CustomFieldNumberSavedValueNode = Node & {
  __typename?: 'CustomFieldNumberSavedValueNode';
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['Float']>;
};

export type CustomFieldsLimitReachedError = {
  __typename?: 'CustomFieldsLimitReachedError';
  limit: Scalars['Int'];
};

export enum CustomFieldSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type customFieldTextAllowedValueChange = {
  id?: Maybe<Scalars['ID']>;
  operation: CustomFieldAllowedValueOperation;
  value?: Maybe<Scalars['String']>;
};

export type CustomFieldTextAllowedValueConnection = {
  __typename?: 'CustomFieldTextAllowedValueConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CustomFieldTextAllowedValueEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldTextAllowedValueEdge = {
  __typename?: 'CustomFieldTextAllowedValueEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomFieldTextAllowedValueNode>;
};

export type CustomFieldTextAllowedValueNode = Node & {
  __typename?: 'CustomFieldTextAllowedValueNode';
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};

export type CustomFieldTextSavedValueConnection = {
  __typename?: 'CustomFieldTextSavedValueConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<CustomFieldTextSavedValueEdge>>>;
  pageInfo: PageInfo;
};

export type CustomFieldTextSavedValueEdge = {
  __typename?: 'CustomFieldTextSavedValueEdge';
  cursor: Scalars['String'];
  node?: Maybe<CustomFieldTextSavedValueNode>;
};

export type CustomFieldTextSavedValueNode = Node & {
  __typename?: 'CustomFieldTextSavedValueNode';
  id: Scalars['ID'];
  uuid?: Maybe<Scalars['UUID']>;
  value?: Maybe<Scalars['String']>;
};

export enum CustomFieldType {
  Number = 'NUMBER',
  Text = 'TEXT',
  TextSelect = 'TEXT_SELECT',
  User = 'USER'
}

export type CustomFieldTypeConfig = {
  __typename?: 'CustomFieldTypeConfig';
  canSetAllowedValues?: Maybe<Scalars['Boolean']>;
  canSetMultipleValues?: Maybe<Scalars['Boolean']>;
  type?: Maybe<CustomFieldType>;
};



export type Decision = Highlight & Node & {
  __typename?: 'Decision';
  ari: Scalars['String'];
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Project>;
  summary?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export type DecisionConnection = {
  __typename?: 'DecisionConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<DecisionEdge>>>;
  pageInfo: PageInfo;
};

export type DecisionEdge = {
  __typename?: 'DecisionEdge';
  cursor: Scalars['String'];
  node?: Maybe<Decision>;
};

export type deleteBoostInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteBoostPayload = {
  __typename?: 'deleteBoostPayload';
  boost?: Maybe<Boost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type deleteCommentInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['ID']>;
};

export type deleteCommentPayload = {
  __typename?: 'deleteCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
  deleted: Scalars['Boolean'];
};

export type DeleteCustomFieldDefinitionError = {
  __typename?: 'DeleteCustomFieldDefinitionError';
  error?: Maybe<Scalars['String']>;
};

export type deleteCustomFieldDefinitionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteCustomFieldDefinitionPayload = {
  __typename?: 'deleteCustomFieldDefinitionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<DeleteCustomFieldDefinitionResult>;
};

export type DeleteCustomFieldDefinitionResult = DeleteCustomFieldDefinitionError | DeleteCustomFieldDefinitionSuccess;

export type DeleteCustomFieldDefinitionSuccess = {
  __typename?: 'DeleteCustomFieldDefinitionSuccess';
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
};

export type deleteDecisionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteDecisionPayload = {
  __typename?: 'deleteDecisionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  decision?: Maybe<Decision>;
};

export type deleteDirectoryViewInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  directoryViewUuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};

export type deleteDirectoryViewPayload = {
  __typename?: 'deleteDirectoryViewPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  deleted: Scalars['Boolean'];
  directoryView?: Maybe<DirectoryView>;
};

export type deleteDraftProjectUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
};

export type deleteDraftProjectUpdatePayload = {
  __typename?: 'deleteDraftProjectUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  draftUpdate?: Maybe<DraftUpdate>;
};

export type deleteGoalFusionInput = {
  clientKey?: Maybe<Scalars['String']>;
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  goalUuid: Scalars['UUID'];
  issueId: Scalars['Int'];
};

export type deleteGoalFusionPayload = {
  __typename?: 'deleteGoalFusionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goalFusion?: Maybe<GoalFusion>;
};

export type deleteGoalFusionV2Input = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalAri: Scalars['String'];
  issueAri: Scalars['String'];
};

export type deleteGoalFusionV2Payload = {
  __typename?: 'deleteGoalFusionV2Payload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type deleteGoalProjectLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  projectId: Scalars['ID'];
};

export type deleteGoalProjectLinkPayload = {
  __typename?: 'deleteGoalProjectLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isRemoved: Scalars['Boolean'];
  project?: Maybe<Project>;
};

export type deleteHelpPointerInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteHelpPointerPayload = {
  __typename?: 'deleteHelpPointerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  helpPointer?: Maybe<HelpPointer>;
  success: Scalars['Boolean'];
};

export type deleteKudosInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteKudosPayload = {
  __typename?: 'deleteKudosPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  kudos?: Maybe<Kudos>;
  success: Scalars['Boolean'];
};

export type deleteLatestGoalUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  updateId: Scalars['ID'];
};

export type deleteLatestGoalUpdatePayload = {
  __typename?: 'deleteLatestGoalUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type deleteLatestProjectUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  updateId: Scalars['ID'];
};

export type deleteLatestProjectUpdatePayload = {
  __typename?: 'deleteLatestProjectUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type deleteLearningInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteLearningPayload = {
  __typename?: 'deleteLearningPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  learning?: Maybe<Learning>;
};

export type deleteMilestoneInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  milestoneId: Scalars['ID'];
};

export type deleteMilestonePayload = {
  __typename?: 'deleteMilestonePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  milestone?: Maybe<Milestone>;
  success: Scalars['Boolean'];
};

export type deleteProjectDependencyInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  incomingProjectId: Scalars['ID'];
  outgoingProjectId: Scalars['ID'];
};

export type deleteProjectDependencyPayload = {
  __typename?: 'deleteProjectDependencyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type deleteProjectFusionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
};

export type deleteProjectFusionPayload = {
  __typename?: 'deleteProjectFusionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type deleteProjectFusionV2Input = {
  clientMutationId?: Maybe<Scalars['String']>;
  deleteRelationship?: Maybe<Scalars['Boolean']>;
  issueAri?: Maybe<Scalars['String']>;
  projectAri: Scalars['String'];
};

export type deleteProjectLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['String'];
};

export type deleteProjectLinkPayload = {
  __typename?: 'deleteProjectLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type deleteProjectRoleInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
};

export type deleteProjectRolePayload = {
  __typename?: 'deleteProjectRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type deleteRelationshipsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  relationships: Array<RelationshipInput>;
};

export type DeleteRelationshipsMutationError = MutationError & {
  __typename?: 'DeleteRelationshipsMutationError';
  extensions?: Maybe<DeleteRelationshipsMutationErrorExtension>;
  message?: Maybe<Scalars['String']>;
};

export type DeleteRelationshipsMutationErrorExtension = MutationErrorExtension & {
  __typename?: 'DeleteRelationshipsMutationErrorExtension';
  errorType?: Maybe<Scalars['String']>;
  relationship: Relationship;
  statusCode?: Maybe<Scalars['Int']>;
};

export type deleteRelationshipsPayload = {
  __typename?: 'deleteRelationshipsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<DeleteRelationshipsMutationError>>;
  relationships?: Maybe<Array<Relationship>>;
  success: Scalars['Boolean'];
};

export type deleteRiskInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type deleteRiskPayload = {
  __typename?: 'deleteRiskPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  risk?: Maybe<Risk>;
};

export type deleteTeamHealthMonitorInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitorUUID: Scalars['UUID'];
};

export type deleteTeamHealthMonitorPayload = {
  __typename?: 'deleteTeamHealthMonitorPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitor?: Maybe<TeamHealthMonitor>;
};

export type deleteUserOnboardingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  onboardingKey?: Maybe<OnboardingItemKey>;
};

export type deleteUserOnboardingPayload = {
  __typename?: 'deleteUserOnboardingPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Onboarding>;
};

export enum DirectorySortEnum {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum DirectoryTeamSortEnum {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type DirectoryView = Node & {
  __typename?: 'DirectoryView';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  directoryViewColumns?: Maybe<Array<Maybe<DirectoryViewColumn>>>;
  entityType?: Maybe<DirectoryViewEntityType>;
  favouriteCount?: Maybe<Scalars['Int']>;
  id: Scalars['ID'];
  isFavourite?: Maybe<Scalars['Boolean']>;
  lastEditedBy?: Maybe<User>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  sort?: Maybe<DirectoryViewSort>;
  storedTql?: Maybe<Scalars['String']>;
  uiMetadata?: Maybe<DirectoryViewUi>;
  uiViewType?: Maybe<DirectoryViewUiType>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type DirectoryViewColumn = CustomDirectoryViewColumn | StandardDirectoryViewColumn;

export type directoryViewColumnInput = {
  customFieldUuid?: Maybe<Scalars['UUID']>;
  standardFieldName?: Maybe<StandardDirectoryViewColumnName>;
};

export type DirectoryViewConnection = {
  __typename?: 'DirectoryViewConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<DirectoryViewEdge>>>;
  pageInfo: PageInfo;
};

export type DirectoryViewEdge = {
  __typename?: 'DirectoryViewEdge';
  cursor: Scalars['String'];
  node?: Maybe<DirectoryView>;
};

export enum DirectoryViewEntityType {
  Goal = 'GOAL',
  Project = 'PROJECT'
}

export type directoryViewInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  directoryViewColumns?: Maybe<Array<Maybe<directoryViewColumnInput>>>;
  entityType: DirectoryViewEntityType;
  goalSort?: Maybe<Array<Maybe<GoalSortEnum>>>;
  name: Scalars['String'];
  projectSort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
  timelineDateSpan?: Maybe<TimelineDateSpan>;
  tql: Scalars['String'];
  uiViewType: DirectoryViewUiType;
  workspaceUuid: Scalars['UUID'];
};

export type directoryViewPayload = {
  __typename?: 'directoryViewPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  directoryView?: Maybe<DirectoryView>;
};

export type DirectoryViewSort = {
  __typename?: 'DirectoryViewSort';
  goalSort?: Maybe<Array<Maybe<GoalSortEnum>>>;
  projectSort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
};

export enum DirectoryViewSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  FavouritedDateAsc = 'FAVOURITED_DATE_ASC',
  FavouritedDateDesc = 'FAVOURITED_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type DirectoryViewUi = {
  __typename?: 'DirectoryViewUi';
  timelineDateSpan?: Maybe<TimelineDateSpan>;
};

export enum DirectoryViewUiType {
  List = 'list',
  Timeline = 'timeline'
}

export type disconnectSlackChannelInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  connectionUuid: Scalars['String'];
};

export type disconnectSlackChannelPayload = {
  __typename?: 'disconnectSlackChannelPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type disconnectTeamsChannelByAriInput = {
  channelId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  resourceAri: Scalars['String'];
  resourceType: Scalars['String'];
  tenantId: Scalars['String'];
};

export type disconnectTeamsChannelByAriPayload = {
  __typename?: 'disconnectTeamsChannelByAriPayload';
  channelId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  resourceAri: Scalars['String'];
  resourceType: Scalars['String'];
  tenantId: Scalars['String'];
};

export enum DomainEnabledSignupLocation {
  CoreInvites = 'CORE_INVITES'
}

export type DraftUpdate = Node & {
  __typename?: 'DraftUpdate';
  author?: Maybe<User>;
  id: Scalars['ID'];
  input?: Maybe<Scalars['String']>;
  modifiedDate?: Maybe<Scalars['DateTime']>;
};

export type editBoostInput = {
  boostId: Scalars['ID'];
  clientMutationId?: Maybe<Scalars['String']>;
  palette?: Maybe<BoostPalette>;
  text: Scalars['String'];
};

export type editBoostPayload = {
  __typename?: 'editBoostPayload';
  boost?: Maybe<Boost>;
  clientMutationId?: Maybe<Scalars['String']>;
};

export type editDirectoryViewInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  directoryViewColumns?: Maybe<Array<Maybe<directoryViewColumnInput>>>;
  directoryViewUuid: Scalars['UUID'];
  goalSort?: Maybe<Array<Maybe<GoalSortEnum>>>;
  name?: Maybe<Scalars['String']>;
  projectSort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
  timelineDateSpan?: Maybe<TimelineDateSpan>;
  tql?: Maybe<Scalars['String']>;
  uiViewType?: Maybe<DirectoryViewUiType>;
  workspaceUuid: Scalars['UUID'];
};

export type editGoalAggInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  targetDate?: Maybe<TargetDateInput>;
};

export type editGoalInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  ownerId?: Maybe<Scalars['String']>;
  startDate?: Maybe<Scalars['Date']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
};

export type editGoalPayload = {
  __typename?: 'editGoalPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type editGoalTypeAggInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalTypeAri: Scalars['String'];
  state?: Maybe<GoalTypeState>;
};

export type editGoalTypePayload = {
  __typename?: 'editGoalTypePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goalType?: Maybe<GoalType>;
};

export type editGoalUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  goalId: Scalars['ID'];
  metricUpdate?: Maybe<Array<Maybe<metricUpdateEditInput>>>;
  phase?: Maybe<GoalPhase>;
  score?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  updateId: Scalars['ID'];
  updateNotes?: Maybe<Array<Maybe<UpdateNoteInput>>>;
};

export type editGoalUpdatePayload = {
  __typename?: 'editGoalUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  update?: Maybe<GoalUpdate>;
};

export enum Edition {
  Beta = 'BETA',
  Custom = 'CUSTOM',
  Free = 'FREE',
  Premium = 'PREMIUM',
  Standard = 'STANDARD'
}

export type editMetricInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  metricId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  subType?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['Float']>;
};

export type editMetricPayload = {
  __typename?: 'editMetricPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  metric?: Maybe<Metric>;
};

export type editMetricTargetInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  currentValue?: Maybe<Scalars['Float']>;
  metricTargetId: Scalars['ID'];
  owningEntityId: Scalars['ID'];
  startValue?: Maybe<Scalars['Float']>;
  targetValue?: Maybe<Scalars['Float']>;
};

export type editMetricTargetPayload = {
  __typename?: 'editMetricTargetPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  owningEntity?: Maybe<Node>;
};

export type editProjectIconInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  icon?: Maybe<IconInput>;
  projectId: Scalars['ID'];
};

export type editProjectIconPayload = {
  __typename?: 'editProjectIconPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type editProjectLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<Scalars['String']>;
  linkId: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  provider?: Maybe<Scalars['String']>;
  url: Scalars['String'];
};

export type editProjectLinkPayload = {
  __typename?: 'editProjectLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type editProjectMetadataInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  startDate?: Maybe<Scalars['Date']>;
  theMeasurement?: Maybe<Scalars['String']>;
  theWhat?: Maybe<Scalars['String']>;
  theWhy?: Maybe<Scalars['String']>;
};

export type editProjectMetadataPayload = {
  __typename?: 'editProjectMetadataPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type editProjectUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  highlights?: Maybe<Array<Maybe<UpdateHighlightInput>>>;
  newPhase?: Maybe<ProjectPhase>;
  phase?: Maybe<Scalars['String']>;
  projectId: Scalars['ID'];
  status?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  updateId: Scalars['ID'];
  updateNotes?: Maybe<Array<Maybe<UpdateNoteInput>>>;
};

export type editProjectUpdatePayload = {
  __typename?: 'editProjectUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  update?: Maybe<ProjectUpdate>;
};

export type editTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  tagUuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};

export type editTagPayload = {
  __typename?: 'editTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tag?: Maybe<Tag>;
};

export type editWorkspaceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalScoringMode?: Maybe<GoalScoringMode>;
  keyPrefix?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  timezone?: Maybe<Scalars['String']>;
  workspaceUuid: Scalars['UUID'];
};

export type editWorkspacePayload = {
  __typename?: 'editWorkspacePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  workspace?: Maybe<Workspace>;
};

export type EmailUserIdentifier = {
  addAsWatcher?: Maybe<Scalars['Boolean']>;
  createPendingWorkspaceMembership: Scalars['Boolean'];
  email?: Maybe<Scalars['String']>;
};

export type enableCustomFieldForEntityInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldId: Scalars['ID'];
  entity?: Maybe<CustomFieldEntity>;
};

export type enableCustomFieldForEntityPayload = {
  __typename?: 'enableCustomFieldForEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customField?: Maybe<CustomField>;
};

export type ExtendedProfile = {
  __typename?: 'ExtendedProfile';
  department?: Maybe<Scalars['String']>;
  jobTitle?: Maybe<Scalars['String']>;
  location?: Maybe<Scalars['String']>;
  organization?: Maybe<Scalars['String']>;
  teamType?: Maybe<Scalars['String']>;
};

export type favouriteDirectoryViewInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  directoryViewUuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};

export type FeatureContext = {
  __typename?: 'FeatureContext';
  edition?: Maybe<Edition>;
  entitlementId?: Maybe<Scalars['String']>;
  features?: Maybe<WorkspaceFeatures>;
  transactionAccountId?: Maybe<Scalars['String']>;
};

export enum FusionCreationErrorType {
  IntegrationDisabled = 'INTEGRATION_DISABLED',
  IntegrationNotInstalled = 'INTEGRATION_NOT_INSTALLED',
  IssueAlreadyLinked = 'ISSUE_ALREADY_LINKED',
  NotInSameOrg = 'NOT_IN_SAME_ORG',
  PermissionDenied = 'PERMISSION_DENIED',
  UnexpectedIssueType = 'UNEXPECTED_ISSUE_TYPE',
  UnknownError = 'UNKNOWN_ERROR'
}

export type FusionDetails = {
  __typename?: 'FusionDetails';
  issue?: Maybe<GqlJiraIssue>;
  issueAri: Scalars['String'];
  synced: Scalars['Boolean'];
};

export type Goal = Node & {
  __typename?: 'Goal';
  aggregatedInfo?: Maybe<AggregatedInfoDto>;
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  canEditMembers?: Maybe<Scalars['Boolean']>;
  canEditUpdate?: Maybe<Scalars['Boolean']>;
  canPostUpdate?: Maybe<Scalars['Boolean']>;
  comments?: Maybe<CommentConnection>;
  creationDate: Scalars['DateTime'];
  customFields?: Maybe<CustomFieldConnection>;
  decisions?: Maybe<DecisionConnection>;
  decisionsCount: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  dueDate?: Maybe<TargetDate>;
  goalDepthData?: Maybe<Array<Maybe<GoalDepthData>>>;
  goalType?: Maybe<GoalType>;
  hasSubgoalMatches?: Maybe<Scalars['Boolean']>;
  hierarchy: Scalars['String'];
  hierarchyLevel?: Maybe<Scalars['Int']>;
  iconData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  key: Scalars['String'];
  latestUpdateDate?: Maybe<Scalars['DateTime']>;
  latestUserUpdate?: Maybe<GoalUpdate>;
  learnings?: Maybe<LearningConnection>;
  learningsCount: Scalars['Int'];
  managerData?: Maybe<ManagerDataResponse>;
  metricTargets?: Maybe<MetricTargetConnection>;
  msteamsChannels?: Maybe<MsteamsConnections>;
  name: Scalars['String'];
  number: Scalars['Int'];
  numberOfParents?: Maybe<Scalars['Int']>;
  owner?: Maybe<User>;
  parentGoal?: Maybe<Goal>;
  parentGoals?: Maybe<GoalConnection>;
  phase?: Maybe<GoalPhaseDetails>;
  phaseId?: Maybe<Scalars['Int']>;
  progress?: Maybe<GoalProgress>;
  projects?: Maybe<ProjectConnection>;
  risks?: Maybe<RiskConnection>;
  risksCount: Scalars['Int'];
  score?: Maybe<Scalars['Int']>;
  slackChannels?: Maybe<SlackConnectionConnection>;
  startDate?: Maybe<Scalars['Date']>;
  state?: Maybe<GoalState>;
  status?: Maybe<BaseStatus>;
  subGoals?: Maybe<GoalConnection>;
  tags?: Maybe<TagConnection>;
  tagsWatchedByUser?: Maybe<TagConnection>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  teams?: Maybe<TeamConnection>;
  teamsV2?: Maybe<GoalTeamConnection>;
  teamsWatchedByUser?: Maybe<TeamConnection>;
  updates?: Maybe<GoalUpdateConnection>;
  updateStreak?: Maybe<Scalars['Int']>;
  url?: Maybe<Scalars['String']>;
  userUpdateCount: Scalars['Int'];
  uuid: Scalars['UUID'];
  watcherCount: Scalars['Int'];
  watchers?: Maybe<UserConnection>;
  watching: Scalars['Boolean'];
  workspace: Workspace;
};


export type Goal_commentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_customFieldsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includedCustomFieldUuids?: Maybe<Array<Scalars['UUID']>>;
};


export type Goal_decisionsArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  noUpdateAttached?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
};


export type Goal_goalDepthDataArgs = {
  managerAaids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Goal_learningsArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  noUpdateAttached?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
};


export type Goal_metricTargetsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_msteamsChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_parentGoalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_projectsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  tql?: Maybe<Scalars['String']>;
};


export type Goal_risksArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  noUpdateAttached?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
};


export type Goal_slackChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_subGoalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_tagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_tagsWatchedByUserArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_teamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_teamsV2Args = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_teamsWatchedByUserArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_updatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Goal_watchersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type GoalAndGoalFusionCreationError = {
  __typename?: 'GoalAndGoalFusionCreationError';
  errorType?: Maybe<GoalFusionCreationErrorType>;
};

export type GoalAndGoalFusionCreationResult = GoalAndGoalFusionCreationError | GoalAndGoalFusionCreationSuccess | GoalAndGoalFusionV2CreationSuccess;

export type GoalAndGoalFusionCreationSuccess = {
  __typename?: 'GoalAndGoalFusionCreationSuccess';
  goal?: Maybe<Goal>;
  goalFusion?: Maybe<GoalFusion>;
};

export type GoalAndGoalFusionV2CreationSuccess = {
  __typename?: 'GoalAndGoalFusionV2CreationSuccess';
  goal?: Maybe<Goal>;
};

export type GoalConnection = {
  __typename?: 'GoalConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalEdge>>>;
  pageInfo: PageInfo;
};

export type GoalDepthData = {
  __typename?: 'GoalDepthData';
  depth: Scalars['Int'];
  managerProfile?: Maybe<UserPII>;
};

export type GoalEdge = {
  __typename?: 'GoalEdge';
  cursor: Scalars['String'];
  node?: Maybe<Goal>;
};

export type GoalFusion = Node & {
  __typename?: 'GoalFusion';
  creationDate: Scalars['DateTime'];
  creator?: Maybe<User>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  issue?: Maybe<GqlJiraIssue>;
  issueId: Scalars['Int'];
};

export type GoalFusionConnection = {
  __typename?: 'GoalFusionConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalFusionEdge>>>;
  pageInfo: PageInfo;
};

export type GoalFusionCreationError = {
  __typename?: 'GoalFusionCreationError';
  errorType?: Maybe<GoalFusionCreationErrorType>;
};

export enum GoalFusionCreationErrorType {
  IntegrationDisabled = 'INTEGRATION_DISABLED',
  IntegrationNotInstalled = 'INTEGRATION_NOT_INSTALLED',
  IssueAlreadyLinked = 'ISSUE_ALREADY_LINKED',
  NotInSameOrg = 'NOT_IN_SAME_ORG',
  PermissionDenied = 'PERMISSION_DENIED',
  UnexpectedIssueType = 'UNEXPECTED_ISSUE_TYPE',
  UnknownError = 'UNKNOWN_ERROR'
}

export type GoalFusionCreationResult = GoalFusionCreationError | GoalFusionCreationSuccess | GoalFusionV2CreationSuccess;

export type GoalFusionCreationSuccess = {
  __typename?: 'GoalFusionCreationSuccess';
  goalFusion?: Maybe<GoalFusion>;
};

export type GoalFusionEdge = {
  __typename?: 'GoalFusionEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalFusion>;
};

export type GoalFusionV2CreationSuccess = {
  __typename?: 'GoalFusionV2CreationSuccess';
  goal?: Maybe<Goal>;
};

export type GoalMetricUpdate = {
  __typename?: 'GoalMetricUpdate';
  metric?: Maybe<Metric>;
  newStartValue?: Maybe<Scalars['Float']>;
  newTargetValue?: Maybe<Scalars['Float']>;
  newValue?: Maybe<MetricValue>;
  oldStartValue?: Maybe<Scalars['Float']>;
  oldTargetValue?: Maybe<Scalars['Float']>;
  oldValue?: Maybe<MetricValue>;
};

export type GoalMetricUpdateConnection = {
  __typename?: 'GoalMetricUpdateConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalMetricUpdateEdge>>>;
  pageInfo: PageInfo;
};

export type GoalMetricUpdateEdge = {
  __typename?: 'GoalMetricUpdateEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalMetricUpdate>;
};

export enum GoalPhase {
  Cancelled = 'cancelled',
  Done = 'done',
  InProgress = 'in_progress',
  Paused = 'paused',
  Pending = 'pending'
}

export type GoalPhaseDetails = {
  __typename?: 'GoalPhaseDetails';
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<GoalPhase>;
};

export type GoalPhaseDetailsConnection = {
  __typename?: 'GoalPhaseDetailsConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalPhaseDetailsEdge>>>;
  pageInfo: PageInfo;
};

export type GoalPhaseDetailsEdge = {
  __typename?: 'GoalPhaseDetailsEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalPhaseDetails>;
};

export type GoalProgress = {
  __typename?: 'GoalProgress';
  percentage?: Maybe<Scalars['Float']>;
  type: GoalProgressType;
};

export enum GoalProgressType {
  AttachedMetric = 'ATTACHED_METRIC',
  AverageRollup = 'AVERAGE_ROLLUP',
  None = 'NONE'
}

export enum GoalScoringMode {
  Okr = 'OKR',
  Simple = 'SIMPLE'
}

export enum GoalSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  HierarchyAsc = 'HIERARCHY_ASC',
  HierarchyDesc = 'HIERARCHY_DESC',
  HierarchyLevelAsc = 'HIERARCHY_LEVEL_ASC',
  HierarchyLevelDesc = 'HIERARCHY_LEVEL_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LatestUpdateDateAsc = 'LATEST_UPDATE_DATE_ASC',
  LatestUpdateDateDesc = 'LATEST_UPDATE_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  PhaseIdAsc = 'PHASE_ID_ASC',
  PhaseIdDesc = 'PHASE_ID_DESC',
  ProjectCountAsc = 'PROJECT_COUNT_ASC',
  ProjectCountDesc = 'PROJECT_COUNT_DESC',
  ScoreAsc = 'SCORE_ASC',
  ScoreDesc = 'SCORE_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  TargetDateAsc = 'TARGET_DATE_ASC',
  TargetDateDesc = 'TARGET_DATE_DESC',
  WatcherCountAsc = 'WATCHER_COUNT_ASC',
  WatcherCountDesc = 'WATCHER_COUNT_DESC',
  WatchingAsc = 'WATCHING_ASC',
  WatchingDesc = 'WATCHING_DESC'
}

export type GoalState = {
  __typename?: 'GoalState';
  atCompletionState?: Maybe<CompletionGoalState>;
  label?: Maybe<Scalars['String']>;
  localizedLabel?: Maybe<LocalizationField>;
  score?: Maybe<Scalars['Float']>;
  value?: Maybe<GoalStateValue>;
};


export type GoalState_labelArgs = {
  includeScore?: Maybe<Scalars['Boolean']>;
};

export type GoalStateConnection = {
  __typename?: 'GoalStateConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalStateEdge>>>;
  pageInfo: PageInfo;
};

export type GoalStateEdge = {
  __typename?: 'GoalStateEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalState>;
};

export enum GoalStateValue {
  Archived = 'archived',
  AtRisk = 'at_risk',
  Cancelled = 'cancelled',
  Done = 'done',
  OffTrack = 'off_track',
  OnTrack = 'on_track',
  Paused = 'paused',
  Pending = 'pending'
}

export type GoalTeamConnection = {
  __typename?: 'GoalTeamConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalTeamEdge>>>;
  pageInfo: PageInfo;
};

export type GoalTeamEdge = {
  __typename?: 'GoalTeamEdge';
  canRemove?: Maybe<Scalars['Boolean']>;
  cursor: Scalars['String'];
  node?: Maybe<Team>;
};

export type GoalType = Node & {
  __typename?: 'GoalType';
  allowedChildTypes?: Maybe<GoalTypeConnection>;
  allowedParentTypes?: Maybe<GoalTypeConnection>;
  ari: Scalars['ID'];
  iconData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<GoalTypeName>;
  state?: Maybe<GoalTypeState>;
};


export type GoalType_allowedChildTypesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
};


export type GoalType_allowedParentTypesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
};

export type GoalTypeConnection = {
  __typename?: 'GoalTypeConnection';
  edges?: Maybe<Array<Maybe<GoalTypeEdge>>>;
  pageInfo: PageInfo;
};

export type GoalTypeCustomName = {
  __typename?: 'GoalTypeCustomName';
  value?: Maybe<Scalars['String']>;
};

export type GoalTypeEdge = {
  __typename?: 'GoalTypeEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalType>;
};

export type GoalTypeName = GoalTypeCustomName | LocalizationField;

export enum GoalTypeState {
  Disabled = 'DISABLED',
  Enabled = 'ENABLED'
}

export type GoalUpdate = Node & {
  __typename?: 'GoalUpdate';
  ari: Scalars['String'];
  comments?: Maybe<CommentConnection>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  editDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated removing this feature that was never launched */
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  metricUpdate?: Maybe<GoalMetricUpdateConnection>;
  missedUpdate: Scalars['Boolean'];
  newDueDate?: Maybe<TargetDate>;
  newPhase?: Maybe<GoalPhaseDetails>;
  newProgress?: Maybe<GoalProgress>;
  newScore: Scalars['Int'];
  newState?: Maybe<GoalState>;
  newTargetDate?: Maybe<Scalars['Date']>;
  newTargetDateConfidence: Scalars['Int'];
  notes?: Maybe<Array<Maybe<UpdateNote>>>;
  oldDueDate?: Maybe<TargetDate>;
  oldPhase?: Maybe<GoalPhaseDetails>;
  oldProgress?: Maybe<GoalProgress>;
  oldScore?: Maybe<Scalars['Int']>;
  oldState?: Maybe<GoalState>;
  oldTargetDate?: Maybe<Scalars['Date']>;
  oldTargetDateConfidence?: Maybe<Scalars['Int']>;
  summary?: Maybe<Scalars['String']>;
  updateType?: Maybe<UpdateType>;
  url?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  views?: Maybe<UserViews>;
};


export type GoalUpdate_commentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type GoalUpdate_metricUpdateArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type GoalUpdate_notesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type GoalUpdateConnection = {
  __typename?: 'GoalUpdateConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<GoalUpdateEdge>>>;
  pageInfo: PageInfo;
};

export type GoalUpdateEdge = {
  __typename?: 'GoalUpdateEdge';
  cursor: Scalars['String'];
  node?: Maybe<GoalUpdate>;
};

export type GqlJiraIssue = {
  __typename?: 'GqlJiraIssue';
  ari?: Maybe<Scalars['String']>;
  fields?: Maybe<GqlJiraIssueFields>;
  href?: Maybe<Scalars['String']>;
  integrations?: Maybe<GqlJiraIssueIntegrations>;
  issueId: Scalars['Int'];
  key?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['String']>;
};

export type GqlJiraIssueFields = {
  __typename?: 'GqlJiraIssueFields';
  duedate?: Maybe<Scalars['Date']>;
  issuetype?: Maybe<GqlJiraIssueType>;
  labels?: Maybe<Array<Maybe<Scalars['String']>>>;
  startDate?: Maybe<Scalars['Date']>;
  status?: Maybe<GqlJiraStatus>;
  summary?: Maybe<Scalars['String']>;
};

export type GqlJiraIssueIntegrations = {
  __typename?: 'GqlJiraIssueIntegrations';
  projectFusionConnected?: Maybe<Scalars['Boolean']>;
};

export type GqlJiraIssueType = {
  __typename?: 'GqlJiraIssueType';
  iconUrl?: Maybe<Scalars['String']>;
  issueTypeId: Scalars['Int'];
  name?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['String']>;
};

export type GqlJiraStatus = {
  __typename?: 'GqlJiraStatus';
  name?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['String']>;
  statusCategory?: Maybe<GqlJiraStatusCategory>;
  statusId: Scalars['Int'];
};

export type GqlJiraStatusCategory = {
  __typename?: 'GqlJiraStatusCategory';
  key: Scalars['String'];
  name?: Maybe<Scalars['String']>;
  self?: Maybe<Scalars['String']>;
  statusCategoryId: Scalars['Int'];
};

export type HasRecords = {
  __typename?: 'HasRecords';
  hasRecords: Scalars['Boolean'];
};

export type HelpPointer = Node & {
  __typename?: 'HelpPointer';
  cloudId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  creatorAaid?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editable?: Maybe<Scalars['Boolean']>;
  icon?: Maybe<Icon>;
  iconData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owner?: Maybe<HelpPointerOwner>;
  tags?: Maybe<TagConnection>;
  teamId?: Maybe<Scalars['String']>;
  type?: Maybe<HelpPointerType>;
  uuid?: Maybe<Scalars['UUID']>;
  workspace: Workspace;
};


export type HelpPointer_tagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type HelpPointerConnection = {
  __typename?: 'HelpPointerConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<HelpPointerEdge>>>;
  pageInfo: PageInfo;
};

export type HelpPointerEdge = {
  __typename?: 'HelpPointerEdge';
  cursor: Scalars['String'];
  node?: Maybe<HelpPointer>;
};

export type HelpPointerOwner = {
  __typename?: 'HelpPointerOwner';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  largeAvatarImageUrl?: Maybe<Scalars['String']>;
  smallAvatarImageUrl?: Maybe<Scalars['String']>;
};

export type HelpPointerSearchResponse = {
  __typename?: 'HelpPointerSearchResponse';
  results?: Maybe<HelpPointerConnection>;
  tags?: Maybe<Array<Maybe<Tag>>>;
  teams?: Maybe<Array<Maybe<Team>>>;
};

export enum HelpPointerSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  TeamIdAsc = 'TEAM_ID_ASC',
  TeamIdDesc = 'TEAM_ID_DESC'
}

export enum HelpPointerType {
  Action = 'ACTION',
  Information = 'INFORMATION'
}

export type Highlight = {
  ari: Scalars['String'];
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Project>;
  summary?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export type HighResolutionProfilePicture = {
  __typename?: 'HighResolutionProfilePicture';
  url?: Maybe<Scalars['String']>;
};

export type Icon = {
  __typename?: 'Icon';
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type IconInput = {
  color?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  shortName?: Maybe<Scalars['String']>;
};

export type IconURIs = {
  __typename?: 'IconURIs';
  roundedSquare?: Maybe<ThemeURIs>;
  square?: Maybe<ThemeURIs>;
};


export enum InvitationStatus {
  Failed = 'FAILED',
  Invited = 'INVITED',
  Pending = 'PENDING',
  UserExists = 'USER_EXISTS'
}

export type inviteTeamMembersInput = {
  accountIds: Array<Maybe<Scalars['String']>>;
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type inviteTeamMembersPayload = {
  __typename?: 'inviteTeamMembersPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
};

export enum InviteTypeEnum {
  GoalWatcher = 'goal_watcher',
  ProjectMember = 'project_member',
  ProjectWatcher = 'project_watcher',
  WorkspaceMember = 'workspace_member'
}

export type inviteUserInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  emailAddress: Scalars['String'];
  inviteAs?: Maybe<Scalars['String']>;
  inviteTo: Scalars['ID'];
};

export type inviteUserPayload = {
  __typename?: 'inviteUserPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  invite?: Maybe<UserInvite>;
};

export type IsFeatureEnabledContextInput = {
  key?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

export type IsFeatureEnabledResponse = {
  __typename?: 'IsFeatureEnabledResponse';
  enabled: Scalars['Boolean'];
};

export type JiraIssueConnection = {
  __typename?: 'JiraIssueConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<JiraIssueEdge>>>;
  pageInfo: PageInfo;
};

export type JiraIssueEdge = {
  __typename?: 'JiraIssueEdge';
  cursor: Scalars['String'];
  node?: Maybe<GqlJiraIssue>;
};

export enum JiraIssueSearchIssueTypeFilter {
  All = 'ALL',
  Epic = 'EPIC'
}

export type JiraKudosFields = {
  companyValue: KudosCompanyValue;
  jiraKudosType: JiraKudosType;
  recipientLocation?: Maybe<KudosLocation>;
  requestedAmount?: Maybe<Scalars['String']>;
};

export enum JiraKudosType {
  BetterKudos = 'BETTER_KUDOS',
  BigKudos = 'BIG_KUDOS',
  GiftCard = 'GIFT_CARD'
}

export type JiraProductActivation = ProductActivation & {
  __typename?: 'JiraProductActivation';
  atlasForJiraCloudEnabled?: Maybe<Scalars['Boolean']>;
  cloudId?: Maybe<Scalars['String']>;
};

export type joinWorkspaceBySiteInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
};

export type joinWorkspaceBySitePayload = {
  __typename?: 'joinWorkspaceBySitePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Array<Maybe<Workspace>>>;
};


export type Kudos = Node & {
  __typename?: 'Kudos';
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  bgTheme?: Maybe<KudosBgThemeType>;
  comments?: Maybe<CommentConnection>;
  creationDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  giver?: Maybe<User>;
  id: Scalars['ID'];
  imageUrl?: Maybe<Scalars['URL']>;
  mediaImageId?: Maybe<Scalars['UUID']>;
  recipients?: Maybe<KudosRecipientConnection>;
  relatedLinks?: Maybe<Array<Maybe<Scalars['String']>>>;
  stillImageUrl?: Maybe<Scalars['URL']>;
  type?: Maybe<KudosType>;
  uuid?: Maybe<Scalars['UUID']>;
};


export type Kudos_commentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Kudos_recipientsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export enum KudosBgThemeType {
  Blue = 'blue',
  Green = 'green',
  Pink = 'pink',
  Purple = 'purple'
}

export enum KudosCompanyValue {
  Btcs = 'BTCS',
  Bwhab = 'BWHAB',
  Dftc = 'DFTC',
  Ocnb = 'OCNB',
  Paat = 'PAAT'
}

export type KudosConnection = {
  __typename?: 'KudosConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<KudosEdge>>>;
  pageInfo: PageInfo;
};

export type KudosEdge = {
  __typename?: 'KudosEdge';
  cursor: Scalars['String'];
  node?: Maybe<Kudos>;
};

export enum KudosLocation {
  Australia = 'AUSTRALIA',
  Brazil = 'BRAZIL',
  Canada = 'CANADA',
  France = 'FRANCE',
  Germany = 'GERMANY',
  India = 'INDIA',
  Japan = 'JAPAN',
  Mexico = 'MEXICO',
  NewZealand = 'NEW_ZEALAND',
  OtherApac = 'OTHER_APAC',
  OtherEmea = 'OTHER_EMEA',
  OtherLatam = 'OTHER_LATAM',
  Poland = 'POLAND',
  Spain = 'SPAIN',
  TheNetherlands = 'THE_NETHERLANDS',
  ThePhilippines = 'THE_PHILIPPINES',
  Turkey = 'TURKEY',
  Ukraine = 'UKRAINE',
  UnitedKingdom = 'UNITED_KINGDOM',
  UnitedStates = 'UNITED_STATES'
}

export type KudosRecipient = Node & {
  __typename?: 'KudosRecipient';
  id: Scalars['ID'];
  recipient: User;
  team?: Maybe<Team>;
};

export type KudosRecipientConnection = {
  __typename?: 'KudosRecipientConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<KudosRecipientEdge>>>;
  pageInfo: PageInfo;
};

export type KudosRecipientEdge = {
  __typename?: 'KudosRecipientEdge';
  cursor: Scalars['String'];
  node?: Maybe<KudosRecipient>;
};

export enum KudosSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC'
}

export enum KudosType {
  Individual = 'INDIVIDUAL',
  Team = 'TEAM'
}

export type Learning = Highlight & Node & {
  __typename?: 'Learning';
  ari: Scalars['String'];
  /** @deprecated Field no longer supported */
  couldImprove?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated Field no longer supported */
  learned?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  resolvedDate?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<LearningType>;
  uuid: Scalars['UUID'];
  /** @deprecated Field no longer supported */
  wentWell?: Maybe<Scalars['String']>;
};

export type LearningConnection = {
  __typename?: 'LearningConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<LearningEdge>>>;
  pageInfo: PageInfo;
};

export type LearningEdge = {
  __typename?: 'LearningEdge';
  cursor: Scalars['String'];
  node?: Maybe<Learning>;
};

export enum LearningSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  SummaryAsc = 'SUMMARY_ASC',
  SummaryDesc = 'SUMMARY_DESC'
}

export enum LearningType {
  Decision = 'DECISION',
  Learning = 'LEARNING',
  Risk = 'RISK'
}

export type Link = Node & {
  __typename?: 'Link';
  iconUrl?: Maybe<Scalars['URL']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  provider?: Maybe<Scalars['String']>;
  type?: Maybe<LinkType>;
  url?: Maybe<Scalars['URL']>;
};

export type LinkConnection = {
  __typename?: 'LinkConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<LinkEdge>>>;
  pageInfo: PageInfo;
};

export type linkCustomFieldDefinitionToEntityInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldId: Scalars['ID'];
  entity: CustomFieldEntity;
};

export type linkCustomFieldDefinitionToEntityPayload = {
  __typename?: 'linkCustomFieldDefinitionToEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
};

export type LinkEdge = {
  __typename?: 'LinkEdge';
  cursor: Scalars['String'];
  node?: Maybe<Link>;
};

export enum LinkType {
  Related = 'RELATED',
  WorkTracking = 'WORK_TRACKING'
}

export type LocalizationField = {
  __typename?: 'LocalizationField';
  defaultValue?: Maybe<Scalars['String']>;
  messageId?: Maybe<Scalars['String']>;
};

export type ManagerData = {
  __typename?: 'ManagerData';
  directReports?: Maybe<Array<Maybe<Scalars['String']>>>;
  managerAaid: Scalars['String'];
  managerProfile?: Maybe<UserPII>;
};

export type ManagerDataRequestException = {
  __typename?: 'ManagerDataRequestException';
  message: Scalars['String'];
  statusCode?: Maybe<Scalars['Int']>;
};

export type ManagerDataResponse = {
  __typename?: 'ManagerDataResponse';
  errors?: Maybe<Array<Maybe<ManagerDataRequestException>>>;
  managers?: Maybe<Array<Maybe<ManagerData>>>;
};

export type markRiskAsActiveInput = {
  id: Scalars['ID'];
};

export type markRiskAsActivePayload = {
  __typename?: 'markRiskAsActivePayload';
  risk?: Maybe<Risk>;
};

export type markRiskAsResolvedInput = {
  details?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type markRiskAsResolvedPayload = {
  __typename?: 'markRiskAsResolvedPayload';
  risk?: Maybe<Risk>;
};

export type markUserAsOnboardedInput = {
  clientMutationId?: Maybe<Scalars['String']>;
};

export type markUserAsOnboardedPayload = {
  __typename?: 'markUserAsOnboardedPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type MembershipMutationError = MutationError & {
  __typename?: 'MembershipMutationError';
  extensions?: Maybe<MembershipMutationErrorExtensions>;
  message: Scalars['String'];
};

export type MembershipMutationErrorExtensions = MutationErrorExtension & {
  __typename?: 'MembershipMutationErrorExtensions';
  errorType?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export enum MembershipState {
  Alumni = 'ALUMNI',
  FullMember = 'FULL_MEMBER',
  Invited = 'INVITED',
  RequestingToJoin = 'REQUESTING_TO_JOIN'
}

export type Metric = Node & {
  __typename?: 'Metric';
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  id: Scalars['ID'];
  latestValue?: Maybe<MetricValue>;
  name: Scalars['String'];
  subType?: Maybe<Scalars['String']>;
  type: MetricType;
  uuid: Scalars['UUID'];
  values?: Maybe<MetricValueConnection>;
  workspace: Workspace;
};


export type Metric_valuesArgs = {
  after?: Maybe<Scalars['String']>;
  endDate?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<MetricValueSortEnum>>>;
  startDate?: Maybe<Scalars['DateTime']>;
};

export type MetricConnection = {
  __typename?: 'MetricConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<MetricEdge>>>;
  pageInfo: PageInfo;
};

export type MetricEdge = {
  __typename?: 'MetricEdge';
  cursor: Scalars['String'];
  node?: Maybe<Metric>;
};

export enum MetricSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export type MetricTarget = Node & {
  __typename?: 'MetricTarget';
  id: Scalars['ID'];
  metric: Metric;
  snapshotValue?: Maybe<MetricValue>;
  startValue: Scalars['Float'];
  targetValue: Scalars['Float'];
};

export type MetricTargetConnection = {
  __typename?: 'MetricTargetConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<MetricTargetEdge>>>;
  pageInfo: PageInfo;
};

export type MetricTargetEdge = {
  __typename?: 'MetricTargetEdge';
  cursor: Scalars['String'];
  node?: Maybe<MetricTarget>;
};

export enum MetricType {
  Currency = 'CURRENCY',
  Numeric = 'NUMERIC',
  Percentage = 'PERCENTAGE'
}

export type metricUpdateEditInput = {
  metricId: Scalars['ID'];
  newValue: Scalars['Float'];
};

export type metricUpdateInput = {
  newValue: Scalars['Float'];
  targetId: Scalars['ID'];
};

export type MetricValue = Node & {
  __typename?: 'MetricValue';
  id: Scalars['ID'];
  metric: Metric;
  time: Scalars['DateTime'];
  uuid: Scalars['UUID'];
  value: Scalars['Float'];
};

export type MetricValueConnection = {
  __typename?: 'MetricValueConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<MetricValueEdge>>>;
  pageInfo: PageInfo;
};

export type MetricValueEdge = {
  __typename?: 'MetricValueEdge';
  cursor: Scalars['String'];
  node?: Maybe<MetricValue>;
};

export enum MetricValueSortEnum {
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  TimeAsc = 'TIME_ASC',
  TimeDesc = 'TIME_DESC'
}

export type Milestone = Node & {
  __typename?: 'Milestone';
  completionDate?: Maybe<Scalars['Date']>;
  creationDatetime?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  lastModifiedDatetime?: Maybe<Scalars['DateTime']>;
  status: MilestoneStatus;
  targetDate: Scalars['Date'];
  targetDateType: TargetDateType;
  title: Scalars['String'];
  uuid?: Maybe<Scalars['UUID']>;
};

export type MilestoneConnection = {
  __typename?: 'MilestoneConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<MilestoneEdge>>>;
  pageInfo: PageInfo;
};

export type MilestoneEdge = {
  __typename?: 'MilestoneEdge';
  cursor: Scalars['String'];
  node?: Maybe<Milestone>;
};

export enum MilestoneStatus {
  Completed = 'COMPLETED',
  InProgress = 'IN_PROGRESS',
  NotStarted = 'NOT_STARTED'
}

export type MSTeamsChannelInfo = {
  __typename?: 'MSTeamsChannelInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  membershipType?: Maybe<Scalars['String']>;
};

export type MSTeamsChannelsResponse = {
  __typename?: 'MSTeamsChannelsResponse';
  data?: Maybe<MSTeamsChannelsSuccessResponse>;
  error?: Maybe<MSTeamsErrorResponse>;
};

export type MSTeamsChannelsSuccessResponse = {
  __typename?: 'MSTeamsChannelsSuccessResponse';
  channelAmount?: Maybe<Scalars['Int']>;
  failedTeamIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  teamsWithChannels?: Maybe<Array<Maybe<MSTeamsTeamWithChannels>>>;
};

export type MsteamsConnectedChannelChannelInfo = {
  __typename?: 'MsteamsConnectedChannelChannelInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  membershipType?: Maybe<Scalars['String']>;
};

export type MsteamsConnectedChannelEdge = {
  __typename?: 'MsteamsConnectedChannelEdge';
  cursor: Scalars['String'];
  node?: Maybe<MsteamsConnectedChannelInfo>;
};

export type MsteamsConnectedChannelInfo = {
  __typename?: 'MsteamsConnectedChannelInfo';
  channel?: Maybe<MsteamsConnectedChannelChannelInfo>;
  creationDate?: Maybe<Scalars['String']>;
  subscriberAaid?: Maybe<Scalars['String']>;
  team?: Maybe<MsteamsConnectedChannelTeamInfo>;
  tenant?: Maybe<MsteamsConnectedChannelTenantInfo>;
};

export type MsteamsConnectedChannelTeamInfo = {
  __typename?: 'MsteamsConnectedChannelTeamInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['String']>;
};

export type MsteamsConnectedChannelTenantInfo = {
  __typename?: 'MsteamsConnectedChannelTenantInfo';
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isUserLoggedIn?: Maybe<Scalars['Boolean']>;
};

export type MsteamsConnections = {
  __typename?: 'MsteamsConnections';
  data?: Maybe<MsteamsConnectionsRelayPage>;
  error?: Maybe<MSTeamsErrorResponse>;
};

export type MsteamsConnectionsRelayPage = {
  __typename?: 'MsteamsConnectionsRelayPage';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<MsteamsConnectedChannelEdge>>>;
  pageInfo: PageInfo;
};

export type MSTeamsErrorResponse = {
  __typename?: 'MSTeamsErrorResponse';
  reasonCode?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type MSTeamsTeamWithChannels = {
  __typename?: 'MSTeamsTeamWithChannels';
  channels?: Maybe<Array<Maybe<MSTeamsChannelInfo>>>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  internalId?: Maybe<Scalars['String']>;
  isPrivate?: Maybe<Scalars['Boolean']>;
  visibility?: Maybe<Scalars['String']>;
};

export type Mutation = {
  __typename?: 'Mutation';
  addGoalProjectLink?: Maybe<addGoalProjectLinkPayload>;
  addGoalTeamLink?: Maybe<addGoalTeamLinkPayload>;
  addMetricTarget?: Maybe<addMetricTargetPayload>;
  addProjectMember?: Maybe<addProjectMemberPayload>;
  addProjectMembers?: Maybe<addProjectMembersPayload>;
  addProjectTeamLink?: Maybe<addProjectTeamLinkPayload>;
  addProjectViewers?: Maybe<addProjectViewersPayload>;
  addTags?: Maybe<addTagsPayload>;
  addTagsByName?: Maybe<addTagsByNamePayload>;
  addTeamHealthRatings?: Maybe<addTeamHealthRatingsPayload>;
  addTeamMembers?: Maybe<addTeamMembersPayload>;
  addTeamWatcher?: Maybe<addTeamWatcherPayload>;
  addUserTag?: Maybe<addUserTagPayload>;
  addView?: Maybe<viewPayload>;
  addWatcher?: Maybe<addWatcherPayload>;
  addWatcherByAccountId?: Maybe<addWatcherByAccountIdPayload>;
  addWatcherByAccountIds?: Maybe<addWatcherByAccountIdsPayload>;
  cloneGoal?: Maybe<cloneGoalPayload>;
  cloneProject?: Maybe<cloneProjectPayload>;
  connectSlackChannelByAri?: Maybe<connectSlackChannelByAriPayload>;
  connectTeamsChannelsByAri?: Maybe<connectMsteamsChannelsByAriPayload>;
  createBoost?: Maybe<createBoostPayload>;
  createComment?: Maybe<createCommentPayload>;
  createCustomFieldDefinition?: Maybe<createCustomFieldDefinitionPayload>;
  createDecision?: Maybe<createDecisionPayload>;
  createDirectoryView?: Maybe<directoryViewPayload>;
  createGoal?: Maybe<createGoalPayload>;
  createGoalAndGoalFusion?: Maybe<createGoalAndGoalFusionPayload>;
  createGoalAndGoalFusionV2?: Maybe<createGoalAndGoalFusionPayload>;
  createGoalFusion?: Maybe<createGoalFusionPayload>;
  createGoalFusionV2?: Maybe<createGoalFusionPayload>;
  createGoalUpdate?: Maybe<createGoalUpdatePayload>;
  createHelpPointer?: Maybe<createHelpPointerPayload>;
  createIndividualKudos?: Maybe<createIndividualKudosPayload>;
  createLearning?: Maybe<createLearningPayload>;
  createMilestone?: Maybe<createMilestonePayload>;
  createProject?: Maybe<createProjectPayload>;
  createProjectFusion?: Maybe<createProjectFusionPayload>;
  createProjectFusionV2?: Maybe<createProjectFusionPayload>;
  createProjectLink?: Maybe<createProjectLinkPayload>;
  createProjectUpdate?: Maybe<createProjectUpdatePayload>;
  createRisk?: Maybe<createRiskPayload>;
  createTag?: Maybe<createTagPayload>;
  createTagForCloudId?: Maybe<createTagForCloudIdPayload>;
  createTeam?: Maybe<createTeamPayload>;
  createTeamHealthMonitor?: Maybe<createTeamHealthMonitorPayload>;
  createTeamKudos?: Maybe<createTeamKudosPayload>;
  createUserTag?: Maybe<createUserTagPayload>;
  createUserTagForCloudId?: Maybe<createUserTagForCloudIdPayload>;
  createWorkspace?: Maybe<createWorkspacePayload>;
  deleteBoost?: Maybe<deleteBoostPayload>;
  deleteComment?: Maybe<deleteCommentPayload>;
  deleteCustomFieldDefinition?: Maybe<deleteCustomFieldDefinitionPayload>;
  deleteDecision?: Maybe<deleteDecisionPayload>;
  deleteDirectoryView?: Maybe<deleteDirectoryViewPayload>;
  deleteDraftProjectUpdate?: Maybe<deleteDraftProjectUpdatePayload>;
  deleteGoalFusion?: Maybe<deleteGoalFusionPayload>;
  deleteGoalFusionV2?: Maybe<deleteGoalFusionV2Payload>;
  deleteGoalProjectLink?: Maybe<deleteGoalProjectLinkPayload>;
  deleteHelpPointer?: Maybe<deleteHelpPointerPayload>;
  deleteKudos?: Maybe<deleteKudosPayload>;
  deleteLatestGoalUpdate?: Maybe<deleteLatestGoalUpdatePayload>;
  deleteLatestProjectUpdate?: Maybe<deleteLatestProjectUpdatePayload>;
  deleteLearning?: Maybe<deleteLearningPayload>;
  deleteMilestone?: Maybe<deleteMilestonePayload>;
  deleteProjectDependency?: Maybe<deleteProjectDependencyPayload>;
  deleteProjectFusion?: Maybe<deleteProjectFusionPayload>;
  deleteProjectFusionV2?: Maybe<deleteProjectFusionPayload>;
  deleteProjectLink?: Maybe<deleteProjectLinkPayload>;
  deleteProjectRole?: Maybe<deleteProjectRolePayload>;
  deleteRisk?: Maybe<deleteRiskPayload>;
  deleteTeamHealthMonitor?: Maybe<deleteTeamHealthMonitorPayload>;
  deleteUserOnboarding?: Maybe<deleteUserOnboardingPayload>;
  disconnectSlackChannel?: Maybe<disconnectSlackChannelPayload>;
  disconnectTeamsChannelByAri?: Maybe<disconnectTeamsChannelByAriPayload>;
  editBoost?: Maybe<editBoostPayload>;
  editDirectoryView?: Maybe<directoryViewPayload>;
  editGoal?: Maybe<editGoalPayload>;
  editGoalUpdate?: Maybe<editGoalUpdatePayload>;
  editMetric?: Maybe<editMetricPayload>;
  editMetricTarget?: Maybe<editMetricTargetPayload>;
  editProjectIcon?: Maybe<editProjectIconPayload>;
  editProjectLink?: Maybe<editProjectLinkPayload>;
  editProjectMetadata?: Maybe<editProjectMetadataPayload>;
  editProjectUpdate?: Maybe<editProjectUpdatePayload>;
  editTag?: Maybe<editTagPayload>;
  editWorkspace?: Maybe<editWorkspacePayload>;
  enableCustomFieldForEntity?: Maybe<enableCustomFieldForEntityPayload>;
  favouriteDirectoryView?: Maybe<directoryViewPayload>;
  /** @deprecated please use addTeamMembers instead. */
  inviteTeamMembers?: Maybe<inviteTeamMembersPayload>;
  inviteUser?: Maybe<inviteUserPayload>;
  joinWorkspaceBySite?: Maybe<joinWorkspaceBySitePayload>;
  linkCustomFieldDefinitionToEntity?: Maybe<linkCustomFieldDefinitionToEntityPayload>;
  markRiskAsActive?: Maybe<markRiskAsActivePayload>;
  markRiskAsResolved?: Maybe<markRiskAsResolvedPayload>;
  markUserAsOnboarded?: Maybe<markUserAsOnboardedPayload>;
  removeCustomFieldDefinitionForEntity?: Maybe<removeCustomFieldDefinitionForEntityPayload>;
  removeGoalTeamLink?: Maybe<removeGoalTeamLinkPayload>;
  removeMetricTarget?: Maybe<removeMetricTargetPayload>;
  removeProjectMember?: Maybe<removeProjectMemberPayload>;
  removeProjectTeamLink?: Maybe<removeProjectTeamLinkPayload>;
  removeTags?: Maybe<removeTagsPayload>;
  removeTeamMember?: Maybe<removeTeamMemberPayload>;
  removeTeamWatcher?: Maybe<removeTeamWatcherPayload>;
  removeUserTag?: Maybe<removeUserTagPayload>;
  removeWatcher?: Maybe<removeWatcherPayload>;
  renameTeam?: Maybe<renameTeamPayload>;
  saveDraftProjectUpdate?: Maybe<saveDraftProjectUpdatePayload>;
  saveUserOnboarding?: Maybe<saveUserOnboardingPayload>;
  sendAtlasUpgradeRequest?: Maybe<sendAtlasUpgradeRequestPayload>;
  sendGoalSampleDigest?: Maybe<sendSampleDigestPayload>;
  sendSampleDigest?: Maybe<sendSampleDigestPayload>;
  setGoalRollupProgress?: Maybe<setGoalRollupProgressPayload>;
  setIsGoalArchived?: Maybe<setIsGoalArchivedPayload>;
  setIsMetricArchived?: Maybe<setIsMetricArchivedPayload>;
  setIsProjectArchived?: Maybe<setIsProjectArchivedPayload>;
  setParentGoal?: Maybe<setParentGoalPayload>;
  setProjectDependency?: Maybe<setProjectDependencyPayload>;
  setProjectOwner?: Maybe<setProjectOwnerPayload>;
  setProjectRole?: Maybe<setProjectRolePayload>;
  setTags?: Maybe<setTagsPayload>;
  setWorkspaceGlobalArchived?: Maybe<setWorkspaceGlobalArchivedStatePayload>;
  setWorkspaceOnboarded?: Maybe<setWorkspaceOnboardedPayload>;
  shareGoal?: Maybe<shareGoalPayload>;
  shareProject?: Maybe<shareProjectPayload>;
  shareUpdate?: Maybe<shareUpdatePayload>;
  townsquare?: Maybe<TownsquareMutationApi>;
  trackInvite?: Maybe<trackInvitePayload>;
  unfavouriteDirectoryView?: Maybe<directoryViewPayload>;
  updateComment?: Maybe<updateCommentPayload>;
  updateCustomField?: Maybe<updateCustomFieldPayload>;
  updateCustomFieldDefinition?: Maybe<updateCustomFieldDefinitionPayload>;
  updateDecision?: Maybe<updateDecisionPayload>;
  updateHelpPointer?: Maybe<updateHelpPointerPayload>;
  updateLearning?: Maybe<updateLearningPayload>;
  updateMilestone?: Maybe<updateMilestonePayload>;
  updateResourceInvitationSettings?: Maybe<updateResourceInvitationSettingsPayload>;
  updateRisk?: Maybe<updateRiskPayload>;
  updateSlackSubscription?: Maybe<updateSlackSubscriptionPayload>;
  updateTeamHealthMonitorStatus?: Maybe<updateTeamHealthMonitorStatusPayload>;
  updateUserOrganizationPreference?: Maybe<updateUserOrganizationPreferencePayload>;
  /** @deprecated use updateUserPreferencesGlobally instead */
  updateUserPreferences?: Maybe<updateUserPreferencesPayload>;
  updateUserPreferencesGlobally?: Maybe<updateUserPreferencesGloballyPayload>;
  /** @deprecated use updateUserPreferencesGlobally instead */
  updateUserShortcutsPreference?: Maybe<updateUserShortcutsPreferencePayload>;
  updateUserTag?: Maybe<updateUserTagPayload>;
  updateUserWorkspacePreference?: Maybe<updateUserWorkspacePreferencePayload>;
};


export type Mutation_addGoalProjectLinkArgs = {
  input: addGoalProjectLinkInput;
};


export type Mutation_addGoalTeamLinkArgs = {
  input: addGoalTeamLinkInput;
};


export type Mutation_addMetricTargetArgs = {
  input: addMetricTargetInput;
};


export type Mutation_addProjectMemberArgs = {
  input: addProjectMemberInput;
};


export type Mutation_addProjectMembersArgs = {
  input: addProjectMembersInput;
};


export type Mutation_addProjectTeamLinkArgs = {
  input: addProjectTeamLinkInput;
};


export type Mutation_addProjectViewersArgs = {
  input: addProjectViewersInput;
};


export type Mutation_addTagsArgs = {
  input: addTagsInput;
};


export type Mutation_addTagsByNameArgs = {
  input: addTagsByNameInput;
};


export type Mutation_addTeamHealthRatingsArgs = {
  input: addTeamHealthRatingsInput;
};


export type Mutation_addTeamMembersArgs = {
  input: addTeamMembersInput;
};


export type Mutation_addTeamWatcherArgs = {
  input: addTeamWatcherInput;
};


export type Mutation_addUserTagArgs = {
  input: addUserTagInput;
};


export type Mutation_addViewArgs = {
  input: viewInput;
};


export type Mutation_addWatcherArgs = {
  input: addWatcherInput;
};


export type Mutation_addWatcherByAccountIdArgs = {
  input: addWatcherByAccountIdInput;
};


export type Mutation_addWatcherByAccountIdsArgs = {
  input: addWatcherByAccountIdsInput;
};


export type Mutation_cloneGoalArgs = {
  input: cloneGoalInput;
};


export type Mutation_cloneProjectArgs = {
  input: cloneProjectInput;
};


export type Mutation_connectSlackChannelByAriArgs = {
  input: connectSlackChannelByAriInput;
};


export type Mutation_connectTeamsChannelsByAriArgs = {
  input: connectMsteamsChannelsByAriInput;
};


export type Mutation_createBoostArgs = {
  input: createBoostInput;
};


export type Mutation_createCommentArgs = {
  input: createCommentInput;
};


export type Mutation_createCustomFieldDefinitionArgs = {
  input: createCustomFieldDefinitionInput;
};


export type Mutation_createDecisionArgs = {
  input: createDecisionInput;
};


export type Mutation_createDirectoryViewArgs = {
  input: directoryViewInput;
};


export type Mutation_createGoalArgs = {
  input: createGoalInput;
};


export type Mutation_createGoalAndGoalFusionArgs = {
  input?: Maybe<createGoalAndGoalFusionInput>;
};


export type Mutation_createGoalAndGoalFusionV2Args = {
  input?: Maybe<createGoalAndGoalFusionV2Input>;
};


export type Mutation_createGoalFusionArgs = {
  input: createGoalFusionInput;
};


export type Mutation_createGoalFusionV2Args = {
  input: createGoalFusionV2Input;
};


export type Mutation_createGoalUpdateArgs = {
  input: createGoalUpdateInput;
};


export type Mutation_createHelpPointerArgs = {
  input: createHelpPointerInput;
};


export type Mutation_createIndividualKudosArgs = {
  input: createIndividualKudosInput;
};


export type Mutation_createLearningArgs = {
  input: createLearningInput;
};


export type Mutation_createMilestoneArgs = {
  input: createMilestoneInput;
};


export type Mutation_createProjectArgs = {
  input: createProjectInput;
};


export type Mutation_createProjectFusionArgs = {
  input: createProjectFusionInput;
};


export type Mutation_createProjectFusionV2Args = {
  input: createProjectFusionV2Input;
};


export type Mutation_createProjectLinkArgs = {
  input: createProjectLinkInput;
};


export type Mutation_createProjectUpdateArgs = {
  input: createProjectUpdateInput;
};


export type Mutation_createRiskArgs = {
  input: createRiskInput;
};


export type Mutation_createTagArgs = {
  input: createTagInput;
};


export type Mutation_createTagForCloudIdArgs = {
  input: createTagForCloudIdInput;
};


export type Mutation_createTeamArgs = {
  input: createTeamInput;
};


export type Mutation_createTeamHealthMonitorArgs = {
  input: createTeamHealthMonitorInput;
};


export type Mutation_createTeamKudosArgs = {
  input: createTeamKudosInput;
};


export type Mutation_createUserTagArgs = {
  input: createUserTagInput;
};


export type Mutation_createUserTagForCloudIdArgs = {
  input: createUserTagForCloudIdInput;
};


export type Mutation_createWorkspaceArgs = {
  input: createWorkspaceInput;
};


export type Mutation_deleteBoostArgs = {
  input: deleteBoostInput;
};


export type Mutation_deleteCommentArgs = {
  input: deleteCommentInput;
};


export type Mutation_deleteCustomFieldDefinitionArgs = {
  input: deleteCustomFieldDefinitionInput;
};


export type Mutation_deleteDecisionArgs = {
  input: deleteDecisionInput;
};


export type Mutation_deleteDirectoryViewArgs = {
  input: deleteDirectoryViewInput;
};


export type Mutation_deleteDraftProjectUpdateArgs = {
  input: deleteDraftProjectUpdateInput;
};


export type Mutation_deleteGoalFusionArgs = {
  input: deleteGoalFusionInput;
};


export type Mutation_deleteGoalFusionV2Args = {
  input: deleteGoalFusionV2Input;
};


export type Mutation_deleteGoalProjectLinkArgs = {
  input: deleteGoalProjectLinkInput;
};


export type Mutation_deleteHelpPointerArgs = {
  input: deleteHelpPointerInput;
};


export type Mutation_deleteKudosArgs = {
  input: deleteKudosInput;
};


export type Mutation_deleteLatestGoalUpdateArgs = {
  input: deleteLatestGoalUpdateInput;
};


export type Mutation_deleteLatestProjectUpdateArgs = {
  input: deleteLatestProjectUpdateInput;
};


export type Mutation_deleteLearningArgs = {
  input: deleteLearningInput;
};


export type Mutation_deleteMilestoneArgs = {
  input: deleteMilestoneInput;
};


export type Mutation_deleteProjectDependencyArgs = {
  input: deleteProjectDependencyInput;
};


export type Mutation_deleteProjectFusionArgs = {
  input: deleteProjectFusionInput;
};


export type Mutation_deleteProjectFusionV2Args = {
  input: deleteProjectFusionV2Input;
};


export type Mutation_deleteProjectLinkArgs = {
  input: deleteProjectLinkInput;
};


export type Mutation_deleteProjectRoleArgs = {
  input: deleteProjectRoleInput;
};


export type Mutation_deleteRiskArgs = {
  input: deleteRiskInput;
};


export type Mutation_deleteTeamHealthMonitorArgs = {
  input: deleteTeamHealthMonitorInput;
};


export type Mutation_deleteUserOnboardingArgs = {
  input: deleteUserOnboardingInput;
};


export type Mutation_disconnectSlackChannelArgs = {
  input: disconnectSlackChannelInput;
};


export type Mutation_disconnectTeamsChannelByAriArgs = {
  input: disconnectTeamsChannelByAriInput;
};


export type Mutation_editBoostArgs = {
  input: editBoostInput;
};


export type Mutation_editDirectoryViewArgs = {
  input: editDirectoryViewInput;
};


export type Mutation_editGoalArgs = {
  input: editGoalInput;
};


export type Mutation_editGoalUpdateArgs = {
  input: editGoalUpdateInput;
};


export type Mutation_editMetricArgs = {
  input?: Maybe<editMetricInput>;
};


export type Mutation_editMetricTargetArgs = {
  input: editMetricTargetInput;
};


export type Mutation_editProjectIconArgs = {
  input: editProjectIconInput;
};


export type Mutation_editProjectLinkArgs = {
  input: editProjectLinkInput;
};


export type Mutation_editProjectMetadataArgs = {
  input: editProjectMetadataInput;
};


export type Mutation_editProjectUpdateArgs = {
  input: editProjectUpdateInput;
};


export type Mutation_editTagArgs = {
  input: editTagInput;
};


export type Mutation_editWorkspaceArgs = {
  input: editWorkspaceInput;
};


export type Mutation_enableCustomFieldForEntityArgs = {
  input: enableCustomFieldForEntityInput;
};


export type Mutation_favouriteDirectoryViewArgs = {
  input: favouriteDirectoryViewInput;
};


export type Mutation_inviteTeamMembersArgs = {
  input: inviteTeamMembersInput;
};


export type Mutation_inviteUserArgs = {
  input: inviteUserInput;
};


export type Mutation_joinWorkspaceBySiteArgs = {
  input: joinWorkspaceBySiteInput;
};


export type Mutation_linkCustomFieldDefinitionToEntityArgs = {
  input: linkCustomFieldDefinitionToEntityInput;
};


export type Mutation_markRiskAsActiveArgs = {
  input: markRiskAsActiveInput;
};


export type Mutation_markRiskAsResolvedArgs = {
  input: markRiskAsResolvedInput;
};


export type Mutation_markUserAsOnboardedArgs = {
  input: markUserAsOnboardedInput;
};


export type Mutation_removeCustomFieldDefinitionForEntityArgs = {
  input: removeCustomFieldDefinitionForEntityInput;
};


export type Mutation_removeGoalTeamLinkArgs = {
  input: removeGoalTeamLinkInput;
};


export type Mutation_removeMetricTargetArgs = {
  input: removeMetricTargetInput;
};


export type Mutation_removeProjectMemberArgs = {
  input: removeProjectMemberInput;
};


export type Mutation_removeProjectTeamLinkArgs = {
  input: removeProjectTeamLinkInput;
};


export type Mutation_removeTagsArgs = {
  input: removeTagsInput;
};


export type Mutation_removeTeamMemberArgs = {
  input: removeTeamMemberInput;
};


export type Mutation_removeTeamWatcherArgs = {
  input: removeTeamWatcherInput;
};


export type Mutation_removeUserTagArgs = {
  input: removeUserTagInput;
};


export type Mutation_removeWatcherArgs = {
  input: removeWatcherInput;
};


export type Mutation_renameTeamArgs = {
  input: renameTeamInput;
};


export type Mutation_saveDraftProjectUpdateArgs = {
  input: createProjectUpdateInput;
};


export type Mutation_saveUserOnboardingArgs = {
  input: saveUserOnboardingInput;
};


export type Mutation_sendAtlasUpgradeRequestArgs = {
  input: sendAtlasUpgradeRequestInput;
};


export type Mutation_sendGoalSampleDigestArgs = {
  input: sendSampleDigestInput;
};


export type Mutation_sendSampleDigestArgs = {
  input: sendSampleDigestInput;
};


export type Mutation_setGoalRollupProgressArgs = {
  input: setGoalRollupProgressInput;
};


export type Mutation_setIsGoalArchivedArgs = {
  input: setIsGoalArchivedInput;
};


export type Mutation_setIsMetricArchivedArgs = {
  input: setIsMetricArchivedInput;
};


export type Mutation_setIsProjectArchivedArgs = {
  input: setIsProjectArchivedInput;
};


export type Mutation_setParentGoalArgs = {
  input: setParentGoalInput;
};


export type Mutation_setProjectDependencyArgs = {
  input: setProjectDependencyInput;
};


export type Mutation_setProjectOwnerArgs = {
  input: setProjectOwnerInput;
};


export type Mutation_setProjectRoleArgs = {
  input: setProjectRoleInput;
};


export type Mutation_setTagsArgs = {
  input: setTagsInput;
};


export type Mutation_setWorkspaceGlobalArchivedArgs = {
  input: setWorkspaceGlobalArchivedInput;
};


export type Mutation_setWorkspaceOnboardedArgs = {
  input: setWorkspaceOnboardedInput;
};


export type Mutation_shareGoalArgs = {
  input: shareGoalInput;
};


export type Mutation_shareProjectArgs = {
  input: shareProjectInput;
};


export type Mutation_shareUpdateArgs = {
  input: shareUpdateInput;
};


export type Mutation_trackInviteArgs = {
  input: trackInviteInput;
};


export type Mutation_unfavouriteDirectoryViewArgs = {
  input: favouriteDirectoryViewInput;
};


export type Mutation_updateCommentArgs = {
  input: updateCommentInput;
};


export type Mutation_updateCustomFieldArgs = {
  input: updateCustomFieldInput;
};


export type Mutation_updateCustomFieldDefinitionArgs = {
  input: updateCustomFieldDefinitionInput;
};


export type Mutation_updateDecisionArgs = {
  input: updateDecisionInput;
};


export type Mutation_updateHelpPointerArgs = {
  input: updateHelpPointerInput;
};


export type Mutation_updateLearningArgs = {
  input: updateLearningInput;
};


export type Mutation_updateMilestoneArgs = {
  input: updateMilestoneInput;
};


export type Mutation_updateResourceInvitationSettingsArgs = {
  input: updateResourceInvitationSettingsInput;
};


export type Mutation_updateRiskArgs = {
  input: updateRiskInput;
};


export type Mutation_updateSlackSubscriptionArgs = {
  input: updateSlackSubscriptionInput;
};


export type Mutation_updateTeamHealthMonitorStatusArgs = {
  input: updateTeamHealthMonitorStatusInput;
};


export type Mutation_updateUserOrganizationPreferenceArgs = {
  input: updateUserOrganizationPreferenceInput;
};


export type Mutation_updateUserPreferencesArgs = {
  input: updateUserPreferencesInput;
};


export type Mutation_updateUserPreferencesGloballyArgs = {
  input: updateUserPreferencesGloballyInput;
};


export type Mutation_updateUserShortcutsPreferenceArgs = {
  input: updateUserShortcutsPreferenceInput;
};


export type Mutation_updateUserTagArgs = {
  input: updateUserTagInput;
};


export type Mutation_updateUserWorkspacePreferenceArgs = {
  input: updateUserWorkspacePreferenceInput;
};

export type MutationError = {
  extensions?: Maybe<MutationErrorExtension>;
  message?: Maybe<Scalars['String']>;
};

export type MutationErrorExtension = {
  errorType?: Maybe<Scalars['String']>;
  statusCode?: Maybe<Scalars['Int']>;
};

export type Node = {
  id: Scalars['ID'];
};

export type NumberCustomField = CustomFieldNode & Node & {
  __typename?: 'NumberCustomField';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  definition?: Maybe<CustomFieldDefinition>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['UUID']>;
  value?: Maybe<CustomFieldNumberSavedValueNode>;
};

export type NumberCustomFieldDefinition = CustomFieldDefinitionNode & Node & {
  __typename?: 'NumberCustomFieldDefinition';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  linkedEntityTypes?: Maybe<Array<CustomFieldEntity>>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomFieldType>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type Onboarding = Node & {
  __typename?: 'Onboarding';
  completionDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  key?: Maybe<OnboardingItemKey>;
};

export type OnboardingConnection = {
  __typename?: 'OnboardingConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<OnboardingEdge>>>;
  pageInfo: PageInfo;
};

export type OnboardingEdge = {
  __typename?: 'OnboardingEdge';
  cursor: Scalars['String'];
  node?: Maybe<Onboarding>;
};

export type OnboardingFilterInput = {
  keys?: Maybe<Array<Maybe<OnboardingItemKey>>>;
};

export enum OnboardingItemKey {
  AddPeopleBanner = 'ADD_PEOPLE_BANNER',
  ClassicToAtlassianHomeModal = 'CLASSIC_TO_ATLASSIAN_HOME_MODAL',
  DiscoverIntegrations = 'DISCOVER_INTEGRATIONS',
  ExploreDirectoryBanner = 'EXPLORE_DIRECTORY_BANNER',
  GlobalHome = 'GLOBAL_HOME',
  GoalLearningsBanner = 'GOAL_LEARNINGS_BANNER',
  GoalsAboutTabBanner = 'GOALS_ABOUT_TAB_BANNER',
  GoalsDirectory = 'GOALS_DIRECTORY',
  GoalsDirectoryBanner = 'GOALS_DIRECTORY_BANNER',
  GoalsMetricsPopup = 'GOALS_METRICS_POPUP',
  GoalsUpdatesBanner = 'GOALS_UPDATES_BANNER',
  GoalsUpdatesTabBanner = 'GOALS_UPDATES_TAB_BANNER',
  LinkedGoalsInJiraBanner = 'LINKED_GOALS_IN_JIRA_BANNER',
  NavRefreshOptOut = 'NAV_REFRESH_OPT_OUT',
  NoWorkspaceBenefitsDialog = 'NO_WORKSPACE_BENEFITS_DIALOG',
  PasteWorkTrackingJiraLinkBanner = 'PASTE_WORK_TRACKING_JIRA_LINK_BANNER',
  ProductOverview = 'PRODUCT_OVERVIEW',
  ProjectAboutTabBanner = 'PROJECT_ABOUT_TAB_BANNER',
  ProjectDirectory = 'PROJECT_DIRECTORY',
  ProjectDirectoryBanner = 'PROJECT_DIRECTORY_BANNER',
  ProjectLearningsBanner = 'PROJECT_LEARNINGS_BANNER',
  ProjectSpotlight = 'PROJECT_SPOTLIGHT',
  ProjectUpdateBanner = 'PROJECT_UPDATE_BANNER',
  ProjectUpdatesTabBanner = 'PROJECT_UPDATES_TAB_BANNER',
  QuestionsFeedBanner = 'QUESTIONS_FEED_BANNER',
  ReadingModeFeedbackCollection = 'READING_MODE_FEEDBACK_COLLECTION',
  StaffDirectoryUploadHighResPic = 'STAFF_DIRECTORY_UPLOAD_HIGH_RES_PIC',
  SyncWithJiraBanner = 'SYNC_WITH_JIRA_BANNER',
  TeamsDirectory = 'TEAMS_DIRECTORY',
  TopicsDirectory = 'TOPICS_DIRECTORY',
  WritingModeFeedbackCollection = 'WRITING_MODE_FEEDBACK_COLLECTION'
}

export enum OpenInviteMode {
  DirectAccess = 'DIRECT_ACCESS',
  DomainAccess = 'DOMAIN_ACCESS',
  NotAllowed = 'NOT_ALLOWED',
  RequestAccess = 'REQUEST_ACCESS'
}

export type OpenInviteModeData = {
  __typename?: 'OpenInviteModeData';
  openInviteMode: OpenInviteMode;
  resource: Scalars['String'];
};

export type OpenInviteModeErrorResponse = {
  __typename?: 'OpenInviteModeErrorResponse';
  error: Scalars['String'];
  resource: Scalars['String'];
};

export type OpenInviteModeResponse = OpenInviteModeData | OpenInviteModeErrorResponse;

export type Organization = Node & {
  __typename?: 'Organization';
  cloudIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  workspaces?: Maybe<Array<Maybe<Workspace>>>;
};

export type OrganizationConnection = {
  __typename?: 'OrganizationConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<OrganizationEdge>>>;
  pageInfo: PageInfo;
};

export type OrganizationContextInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
};

export type OrganizationEdge = {
  __typename?: 'OrganizationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Organization>;
};

export type PageInfo = {
  __typename?: 'PageInfo';
  endCursor?: Maybe<Scalars['String']>;
  hasNextPage: Scalars['Boolean'];
  hasPreviousPage: Scalars['Boolean'];
  startCursor?: Maybe<Scalars['String']>;
};

export type PendingInviteStatus = {
  __typename?: 'PendingInviteStatus';
  inviteDate?: Maybe<Scalars['DateTime']>;
  workspaceName?: Maybe<Scalars['String']>;
  workspaceUuid?: Maybe<Scalars['String']>;
};

export type PeopleFieldAggregate = {
  __typename?: 'PeopleFieldAggregate';
  count: Scalars['Int'];
  imageDirectoryUrl?: Maybe<Scalars['String']>;
  name: Scalars['String'];
};

export type PeopleFieldAggregateError = {
  __typename?: 'PeopleFieldAggregateError';
  errorCode?: Maybe<Scalars['Int']>;
  message?: Maybe<Scalars['String']>;
};

export type PeopleFieldAggregateResponse = {
  __typename?: 'PeopleFieldAggregateResponse';
  cursor?: Maybe<Scalars['String']>;
  errors?: Maybe<Array<Maybe<PeopleFieldAggregateError>>>;
  results?: Maybe<Array<Maybe<PeopleFieldAggregate>>>;
};

export enum PeopleFieldAggregateSort {
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC'
}

export enum PeopleFieldType {
  Department = 'DEPARTMENT',
  JobTitle = 'JOB_TITLE',
  Location = 'LOCATION'
}

export enum PhaseSortEnum {
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC'
}

export type PostSlackConnection = {
  __typename?: 'PostSlackConnection';
  creationDate?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  metadata?: Maybe<PostSlackConnectionMetadata>;
  private?: Maybe<Scalars['Boolean']>;
  resourceId?: Maybe<Scalars['String']>;
  resourceType?: Maybe<Scalars['String']>;
  slackChannelId?: Maybe<Scalars['String']>;
  slackChannelName?: Maybe<Scalars['String']>;
  slackTeamId?: Maybe<Scalars['String']>;
  subscriberAaid?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PostSlackConnectionByAri = {
  __typename?: 'PostSlackConnectionByAri';
  creationDate?: Maybe<Scalars['String']>;
  error?: Maybe<Scalars['String']>;
  metadata?: Maybe<PostSlackConnectionMetadata>;
  private?: Maybe<Scalars['Boolean']>;
  resourceAri?: Maybe<Scalars['String']>;
  slackChannelId?: Maybe<Scalars['String']>;
  slackChannelName?: Maybe<Scalars['String']>;
  slackTeamId?: Maybe<Scalars['String']>;
  subscriberAaid?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  uuid?: Maybe<Scalars['String']>;
};

export type PostSlackConnectionMetadata = {
  __typename?: 'PostSlackConnectionMetadata';
  fieldTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type ProductActivation = {
  cloudId?: Maybe<Scalars['String']>;
};

export type ProductActivationConnection = {
  __typename?: 'ProductActivationConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProductActivationEdge>>>;
  pageInfo: PageInfo;
};

export type ProductActivationEdge = {
  __typename?: 'ProductActivationEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProductActivation>;
};

export enum ProfilePictureSize {
  Large = 'LARGE',
  Maximum = 'MAXIMUM',
  Medium = 'MEDIUM'
}

export type Project = Node & {
  __typename?: 'Project';
  archived: Scalars['Boolean'];
  ari: Scalars['String'];
  canEditMembers?: Maybe<Scalars['Boolean']>;
  changelog?: Maybe<ProjectChangelogItemConnection>;
  comments?: Maybe<CommentConnection>;
  contributors?: Maybe<ContributorConnection>;
  creationDate: Scalars['Date'];
  customFields?: Maybe<CustomFieldConnection>;
  dependencies?: Maybe<ProjectDependencyConnection>;
  description?: Maybe<ProjectDescription>;
  draftUpdate?: Maybe<DraftUpdate>;
  dueDate?: Maybe<TargetDate>;
  fusion?: Maybe<FusionDetails>;
  goals?: Maybe<GoalConnection>;
  icon?: Maybe<Icon>;
  iconData?: Maybe<Scalars['String']>;
  iconUrl?: Maybe<IconURIs>;
  id: Scalars['ID'];
  integration?: Maybe<ProjectFusion>;
  key: Scalars['String'];
  latestUpdateDate?: Maybe<Scalars['DateTime']>;
  latestUserUpdate?: Maybe<ProjectUpdate>;
  learnings?: Maybe<LearningConnection>;
  links?: Maybe<LinkConnection>;
  managerData?: Maybe<ManagerDataResponse>;
  members?: Maybe<UserConnection>;
  memberWorkspaceMemberships?: Maybe<WorkspaceMemberConnection>;
  milestones?: Maybe<MilestoneConnection>;
  msteamsChannels?: Maybe<MsteamsConnections>;
  name: Scalars['String'];
  newPhase?: Maybe<ProjectPhaseDetails>;
  owner?: Maybe<User>;
  phase?: Maybe<ProjectPhaseDetails>;
  private: Scalars['Boolean'];
  projectDepthData?: Maybe<Array<Maybe<ProjectDepthData>>>;
  projectMemberships?: Maybe<ProjectMemberConnection>;
  projectNumber: Scalars['Int'];
  projectTeamLinks?: Maybe<ProjectTeamLinkConnection>;
  relatedLearnings?: Maybe<LearningConnection>;
  risks?: Maybe<RiskConnection>;
  shipDate?: Maybe<Scalars['DateTime']>;
  slackChannels?: Maybe<SlackConnectionConnection>;
  startDate?: Maybe<Scalars['Date']>;
  state?: Maybe<ProjectState>;
  /** @deprecated Field no longer supported */
  status?: Maybe<Status>;
  tags?: Maybe<TagConnection>;
  tagsWatchedByUser?: Maybe<TagConnection>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateConfidence?: Maybe<Scalars['Int']>;
  targetDateSet: Scalars['Boolean'];
  teams?: Maybe<TeamConnection>;
  teamsWatchedByUser?: Maybe<TeamConnection>;
  theMeasurement?: Maybe<Scalars['String']>;
  theWhat?: Maybe<Scalars['String']>;
  theWhy?: Maybe<Scalars['String']>;
  type?: Maybe<ProjectType>;
  updates?: Maybe<ProjectUpdateConnection>;
  url?: Maybe<Scalars['String']>;
  userUpdateCount: Scalars['Int'];
  uuid: Scalars['UUID'];
  viewers?: Maybe<UserConnection>;
  watcherCount: Scalars['Int'];
  watchers?: Maybe<UserConnection>;
  watching: Scalars['Boolean'];
  workspace: Workspace;
  workspaceId: Scalars['Int'];
};


export type Project_changelogArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  hasUpdateId?: Maybe<Scalars['Boolean']>;
};


export type Project_commentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_contributorsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_customFieldsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  includedCustomFieldUuids?: Maybe<Array<Scalars['UUID']>>;
};


export type Project_dependenciesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_goalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_learningsArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  noUpdateAttached?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
  type?: Maybe<LearningType>;
};


export type Project_linksArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  type?: Maybe<LinkType>;
};


export type Project_membersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_memberWorkspaceMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_milestonesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_msteamsChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_projectDepthDataArgs = {
  managerAaids?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Project_projectMembershipsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_projectTeamLinksArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_relatedLearningsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
  type?: Maybe<LearningType>;
};


export type Project_risksArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  isResolved?: Maybe<Scalars['Boolean']>;
  noUpdateAttached?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
};


export type Project_slackChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_tagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_tagsWatchedByUserArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_teamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_teamsWatchedByUserArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_updatesArgs = {
  after?: Maybe<Scalars['String']>;
  createdAtOrAfter?: Maybe<Scalars['DateTime']>;
  createdAtOrBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_viewersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Project_watchersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type ProjectChangelogItem = Node & {
  __typename?: 'ProjectChangelogItem';
  accountId?: Maybe<Scalars['String']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  field?: Maybe<ProjectFusionField>;
  id: Scalars['ID'];
  newValue?: Maybe<Scalars['String']>;
  oldValue?: Maybe<Scalars['String']>;
  projectFusion?: Maybe<ProjectFusion>;
};

export type ProjectChangelogItemConnection = {
  __typename?: 'ProjectChangelogItemConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectChangelogItemEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectChangelogItemEdge = {
  __typename?: 'ProjectChangelogItemEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectChangelogItem>;
};

export type ProjectConnection = {
  __typename?: 'ProjectConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectDependency = Node & {
  __typename?: 'ProjectDependency';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  incomingProject?: Maybe<Project>;
  linkType?: Maybe<ProjectDependencyRelationship>;
  outgoingProject?: Maybe<Project>;
};

export type ProjectDependencyConnection = {
  __typename?: 'ProjectDependencyConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectDependencyEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectDependencyEdge = {
  __typename?: 'ProjectDependencyEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectDependency>;
};

export enum ProjectDependencyRelationship {
  DependedBy = 'DEPENDED_BY',
  DependsOn = 'DEPENDS_ON',
  Related = 'RELATED'
}

export type ProjectDepthData = {
  __typename?: 'ProjectDepthData';
  depth: Scalars['Int'];
  managerProfile?: Maybe<UserPII>;
};

export type ProjectDescription = {
  __typename?: 'ProjectDescription';
  measurement?: Maybe<Scalars['String']>;
  what?: Maybe<Scalars['String']>;
  why?: Maybe<Scalars['String']>;
};

export type ProjectEdge = {
  __typename?: 'ProjectEdge';
  cursor: Scalars['String'];
  node?: Maybe<Project>;
};

export type ProjectFusion = Node & {
  __typename?: 'ProjectFusion';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  fields?: Maybe<Array<Maybe<ProjectFusionField>>>;
  id: Scalars['ID'];
  issue?: Maybe<GqlJiraIssue>;
  issueId: Scalars['Int'];
  project?: Maybe<Project>;
};

export type ProjectFusionContext = {
  __typename?: 'ProjectFusionContext';
  orgId?: Maybe<Scalars['String']>;
  startDateFieldId?: Maybe<Scalars['String']>;
};

export type ProjectFusionCreationError = {
  __typename?: 'ProjectFusionCreationError';
  errorType?: Maybe<FusionCreationErrorType>;
};

export type ProjectFusionCreationResult = ProjectFusionCreationError | ProjectFusionCreationSuccess;

export type ProjectFusionCreationSuccess = {
  __typename?: 'ProjectFusionCreationSuccess';
  project?: Maybe<Project>;
};

export enum ProjectFusionField {
  DueDate = 'DUE_DATE',
  Followers = 'FOLLOWERS',
  Labels = 'LABELS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  Summary = 'SUMMARY'
}

export enum ProjectFusionObjectType {
  Issue = 'ISSUE'
}

export type ProjectFusionOngoingSyncOptionsInput = {
  dueDate?: Maybe<Scalars['String']>;
  labels: Scalars['Boolean'];
  startDate?: Maybe<Scalars['String']>;
  summary?: Maybe<Scalars['String']>;
};

export type ProjectFusionOptionsInput = {
  ongoing?: Maybe<ProjectFusionOngoingSyncOptionsInput>;
};

export enum ProjectFusionProviders {
  Jira = 'JIRA'
}

export type ProjectFusionTargetInput = {
  clientKey?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  objectId?: Maybe<Scalars['String']>;
  objectType?: Maybe<ProjectFusionObjectType>;
  provider?: Maybe<ProjectFusionProviders>;
};

export type ProjectMember = {
  __typename?: 'ProjectMember';
  id?: Maybe<ProjectMemberKey>;
  joined?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Project>;
  user?: Maybe<User>;
};

export type ProjectMemberConnection = {
  __typename?: 'ProjectMemberConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectMemberEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectMemberEdge = {
  __typename?: 'ProjectMemberEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectMember>;
};

export type ProjectMemberKey = {
  __typename?: 'ProjectMemberKey';
  projectId?: Maybe<Scalars['Int']>;
  userId?: Maybe<Scalars['Int']>;
};

export enum ProjectPhase {
  Done = 'done',
  InProgress = 'in_progress',
  Paused = 'paused',
  Pending = 'pending'
}

export type ProjectPhaseDetails = {
  __typename?: 'ProjectPhaseDetails';
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['Int'];
  name?: Maybe<ProjectPhase>;
};

export type ProjectPhaseDetailsConnection = {
  __typename?: 'ProjectPhaseDetailsConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectPhaseDetailsEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectPhaseDetailsEdge = {
  __typename?: 'ProjectPhaseDetailsEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectPhaseDetails>;
};

export enum ProjectRole {
  ProjectContributor = 'PROJECT_CONTRIBUTOR',
  ProjectViewer = 'PROJECT_VIEWER'
}

export enum ProjectSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  LatestUpdateDateAsc = 'LATEST_UPDATE_DATE_ASC',
  LatestUpdateDateDesc = 'LATEST_UPDATE_DATE_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  StartDateAsc = 'START_DATE_ASC',
  StartDateDesc = 'START_DATE_DESC',
  StatusAsc = 'STATUS_ASC',
  StatusDesc = 'STATUS_DESC',
  TargetDateAsc = 'TARGET_DATE_ASC',
  TargetDateDesc = 'TARGET_DATE_DESC',
  WatcherCountAsc = 'WATCHER_COUNT_ASC',
  WatcherCountDesc = 'WATCHER_COUNT_DESC',
  WatchingAsc = 'WATCHING_ASC',
  WatchingDesc = 'WATCHING_DESC'
}

export type ProjectState = {
  __typename?: 'ProjectState';
  atCompletionState?: Maybe<CompletionProjectState>;
  label?: Maybe<Scalars['String']>;
  localizedLabel?: Maybe<LocalizationField>;
  value?: Maybe<ProjectStateValue>;
};

export type ProjectStateConnection = {
  __typename?: 'ProjectStateConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectStateEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectStateEdge = {
  __typename?: 'ProjectStateEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectState>;
};

export enum ProjectStateValue {
  Archived = 'archived',
  AtRisk = 'at_risk',
  Cancelled = 'cancelled',
  Done = 'done',
  OffTrack = 'off_track',
  OnTrack = 'on_track',
  Paused = 'paused',
  Pending = 'pending'
}

export type ProjectTeamLink = {
  __typename?: 'ProjectTeamLink';
  creationDate?: Maybe<Scalars['DateTime']>;
  id?: Maybe<ProjectTeamLinkId>;
  project?: Maybe<Project>;
  team?: Maybe<Team>;
};

export type ProjectTeamLinkConnection = {
  __typename?: 'ProjectTeamLinkConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectTeamLinkEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectTeamLinkEdge = {
  __typename?: 'ProjectTeamLinkEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectTeamLink>;
};

export type ProjectTeamLinkId = {
  __typename?: 'ProjectTeamLinkId';
  projectId?: Maybe<Scalars['Int']>;
  teamId?: Maybe<Scalars['Int']>;
};

export enum ProjectType {
  Initiative = 'INITIATIVE',
  Program = 'PROGRAM',
  Project = 'PROJECT'
}

export type ProjectUpdate = Node & {
  __typename?: 'ProjectUpdate';
  ari: Scalars['String'];
  boosts?: Maybe<BoostConnection>;
  changelog?: Maybe<Array<Maybe<ProjectChangelogItem>>>;
  comments?: Maybe<CommentConnection>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  editDate?: Maybe<Scalars['DateTime']>;
  /** @deprecated removing this feature that was never launched */
  feedbackRequested?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  learnings?: Maybe<LearningConnection>;
  milestones?: Maybe<MilestoneConnection>;
  missedUpdate: Scalars['Boolean'];
  newDueDate?: Maybe<TargetDate>;
  newPhase?: Maybe<ProjectPhaseDetails>;
  newPhaseNew?: Maybe<ProjectPhaseDetails>;
  newState?: Maybe<ProjectState>;
  /** @deprecated Field no longer supported */
  newStatus?: Maybe<Status>;
  newTargetDate?: Maybe<Scalars['Date']>;
  newTargetDateConfidence: Scalars['Int'];
  notes?: Maybe<Array<Maybe<UpdateNote>>>;
  oldDueDate?: Maybe<TargetDate>;
  oldPhase?: Maybe<ProjectPhaseDetails>;
  oldPhaseNew?: Maybe<ProjectPhaseDetails>;
  oldState?: Maybe<ProjectState>;
  /** @deprecated Field no longer supported */
  oldStatus?: Maybe<Status>;
  oldTargetDate?: Maybe<Scalars['Date']>;
  oldTargetDateConfidence?: Maybe<Scalars['Int']>;
  project?: Maybe<Project>;
  summary?: Maybe<Scalars['String']>;
  updateType?: Maybe<UpdateType>;
  url?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
  views?: Maybe<UserViews>;
};


export type ProjectUpdate_boostsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type ProjectUpdate_commentsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type ProjectUpdate_learningsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
};


export type ProjectUpdate_milestonesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type ProjectUpdate_notesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type ProjectUpdateConnection = {
  __typename?: 'ProjectUpdateConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ProjectUpdateEdge>>>;
  pageInfo: PageInfo;
};

export type ProjectUpdateEdge = {
  __typename?: 'ProjectUpdateEdge';
  cursor: Scalars['String'];
  node?: Maybe<ProjectUpdate>;
};

export enum ProjectVisibilityFilter {
  All = 'ALL',
  OnlyPrivate = 'ONLY_PRIVATE',
  OnlyPublic = 'ONLY_PUBLIC'
}

export enum ProjectWatchingFilter {
  ExcludeContributingProjects = 'EXCLUDE_CONTRIBUTING_PROJECTS'
}

export type Query = {
  __typename?: 'Query';
  allGoalPhases?: Maybe<GoalPhaseDetailsConnection>;
  /** @deprecated This endpoint is deprecated, use helpPointerTql */
  allHelpPointers?: Maybe<HelpPointerConnection>;
  allProjectPhases?: Maybe<ProjectPhaseDetailsConnection>;
  allWatchedGoalUpdates?: Maybe<UpdateConnection>;
  allWatchedGoalUpdatesCount?: Maybe<Scalars['Int']>;
  allWatchedProjectUpdates?: Maybe<UpdateConnection>;
  allWatchedProjectUpdatesCount?: Maybe<Scalars['Int']>;
  allWatchedUpdatesV2?: Maybe<UpdateConnection>;
  allWorkspaces?: Maybe<WorkspaceConnection>;
  atlas?: Maybe<Townsquare>;
  bootstrap?: Maybe<AtlassianHomeBootstrap>;
  canCreateProjectFusion?: Maybe<CanCreateProjectFusionResponse>;
  currentUser?: Maybe<User>;
  currentUserAppCapabilities?: Maybe<UserCapabilities>;
  customFieldDefinitionTql?: Maybe<CustomFieldDefinitionConnection>;
  directoryViewByUuid?: Maybe<DirectoryView>;
  directoryViewTql?: Maybe<DirectoryViewConnection>;
  explainTql?: Maybe<TQLExplanation>;
  findParentGoalSuggestions?: Maybe<GoalConnection>;
  findSubGoalSuggestions?: Maybe<GoalConnection>;
  goal?: Maybe<Goal>;
  goalByKey?: Maybe<Goal>;
  goals?: Maybe<Array<Maybe<Goal>>>;
  goalSearch?: Maybe<GoalConnection>;
  goalSearchByOrgId?: Maybe<GoalConnection>;
  goalsForJiraIssue?: Maybe<GoalConnection>;
  goalsForJiraProject?: Maybe<GoalConnection>;
  goalStates?: Maybe<GoalStateConnection>;
  goalStatusSummary?: Maybe<StatusSummary>;
  goalStatusSummaryForTag?: Maybe<StatusSummaryForTag>;
  goalTql?: Maybe<GoalConnection>;
  goalTqlFullHierarchy?: Maybe<GoalConnection>;
  goalUpdate?: Maybe<GoalUpdate>;
  hasGoals?: Maybe<HasRecords>;
  hasProjects?: Maybe<HasRecords>;
  helpPointersByTeam?: Maybe<HelpPointerConnection>;
  helpPointerSearch?: Maybe<HelpPointerSearchResponse>;
  helpPointerTql?: Maybe<HelpPointerConnection>;
  isFeatureEnabled?: Maybe<IsFeatureEnabledResponse>;
  jiraIssueSearch?: Maybe<JiraIssueConnection>;
  kudosByUuid?: Maybe<Kudos>;
  kudosTql?: Maybe<KudosConnection>;
  learning?: Maybe<Learning>;
  learningsByTeam?: Maybe<LearningConnection>;
  learningTql?: Maybe<LearningConnection>;
  managersTql?: Maybe<CpusSearchUserConnection>;
  metricTql?: Maybe<MetricConnection>;
  msteamsChannelsByAri?: Maybe<MSTeamsChannelsResponse>;
  node?: Maybe<Node>;
  organizationsUserAdministers?: Maybe<AdminUserOrganizationConnection>;
  peopleFieldAggregates?: Maybe<PeopleFieldAggregateResponse>;
  peopleTql?: Maybe<CpusSearchUserConnection>;
  project?: Maybe<Project>;
  projectByKey?: Maybe<Project>;
  projectFusionContext?: Maybe<ProjectFusionContext>;
  projects?: Maybe<Array<Maybe<Project>>>;
  /** @deprecated Use of this function results in incorrect pagination. Replace with projectTql */
  projectSearch?: Maybe<ProjectConnection>;
  projectSearchByOrgId?: Maybe<ProjectConnection>;
  projectStates?: Maybe<ProjectStateConnection>;
  projectStatusSummary?: Maybe<StatusSummary>;
  projectStatusSummaryForTag?: Maybe<StatusSummaryForTag>;
  projectTql?: Maybe<ProjectConnection>;
  projectUpdate?: Maybe<ProjectUpdate>;
  recommendations?: Maybe<RecommendationConnection>;
  reportingLines?: Maybe<ReportingLines>;
  slackChannelsByAri?: Maybe<Array<Maybe<SlackChannel>>>;
  slackChannelsByAriWithRetryableErrors?: Maybe<SlackChannelQueryResponse>;
  statusSummary?: Maybe<StatusSummary>;
  suggestedContributors?: Maybe<Array<Maybe<UserPII>>>;
  suggestedFollowers?: Maybe<Array<Maybe<UserPII>>>;
  supportedCustomFields?: Maybe<SupportedCustomFields>;
  tag?: Maybe<Tag>;
  tagByAri?: Maybe<Tag>;
  tagByUuid?: Maybe<Tag>;
  tagsByAri?: Maybe<Array<Maybe<Tag>>>;
  tagsByUuid?: Maybe<Array<Maybe<Tag>>>;
  tagSearch?: Maybe<TagConnection>;
  tagSearchByCloudId?: Maybe<TagConnection>;
  tagTql?: Maybe<TagConnection>;
  teamByTeamId?: Maybe<Team>;
  teamHealthMonitorByUuid?: Maybe<TeamHealthMonitor>;
  teamHealthMonitorQuestions?: Maybe<Array<Maybe<TeamHealthMonitorQuestion>>>;
  teamHealthMonitorsByTeamId?: Maybe<TeamHealthMonitorConnection>;
  teamsByTeamIds?: Maybe<TeamConnection>;
  teamsTql?: Maybe<TeamSearchResponseConnection>;
  townsquare?: Maybe<Townsquare>;
  trendingTags?: Maybe<TagConnection>;
  updatesForSavedView?: Maybe<UpdateConnection>;
  updatesWithTag?: Maybe<UpdateConnection>;
  updatesWithTeam?: Maybe<UpdateConnection>;
  user?: Maybe<User>;
  userAccessStatus?: Maybe<UserAccessStatus>;
  userByAaid?: Maybe<User>;
  userOrganizationByContext?: Maybe<Organization>;
  userProfilesByAaids?: Maybe<UserPIIConnection>;
  userViews?: Maybe<UserViews>;
  /** @deprecated Please use userWorkspaceByContext instead. */
  userWorkspace?: Maybe<Workspace>;
  userWorkspaceByContext?: Maybe<UserWorkspaceByContextResponse>;
  workspace?: Maybe<Workspace>;
  workspaceByContext?: Maybe<WorkspaceByContextResponse>;
  workspaceByUuid?: Maybe<Workspace>;
  workspaceCount?: Maybe<RecordCount>;
  workspaceHadProductAtlasPreviously?: Maybe<Scalars['Boolean']>;
};


export type Query_allGoalPhasesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<PhaseSortEnum>>>;
};


export type Query_allHelpPointersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_allProjectPhasesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<PhaseSortEnum>>>;
};


export type Query_allWatchedGoalUpdatesArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  stateFilter?: Maybe<UpdateStateFilter>;
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_allWatchedGoalUpdatesCountArgs = {
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  stateFilter?: Maybe<UpdateStateFilter>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_allWatchedProjectUpdatesArgs = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
  stateFilter?: Maybe<UpdateStateFilter>;
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_allWatchedProjectUpdatesCountArgs = {
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  stateFilter?: Maybe<UpdateStateFilter>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_allWatchedUpdatesV2Args = {
  after?: Maybe<Scalars['String']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  first?: Maybe<Scalars['Int']>;
};


export type Query_allWorkspacesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  organisationId?: Maybe<Scalars['String']>;
};


export type Query_bootstrapArgs = {
  organizationContext?: Maybe<OrganizationContextInput>;
};


export type Query_canCreateProjectFusionArgs = {
  cloudId: Scalars['String'];
  issueKey: Scalars['String'];
  projectId: Scalars['ID'];
};


export type Query_currentUserArgs = {
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_currentUserAppCapabilitiesArgs = {
  containerAri?: Maybe<Scalars['String']>;
  filter?: Maybe<Array<Maybe<CapabilityContainer>>>;
};


export type Query_customFieldDefinitionTqlArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<CustomFieldSortEnum>>>;
  workspaceUuid: Scalars['UUID'];
};


export type Query_directoryViewByUuidArgs = {
  uuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};


export type Query_directoryViewTqlArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<DirectoryViewSortEnum>>>;
  workspaceUuid: Scalars['UUID'];
};


export type Query_explainTqlArgs = {
  directoryViewUuid?: Maybe<Scalars['UUID']>;
  entity?: Maybe<TqlEntity>;
  q?: Maybe<Scalars['String']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};


export type Query_findParentGoalSuggestionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  goalId: Scalars['ID'];
  q?: Maybe<Scalars['String']>;
};


export type Query_findSubGoalSuggestionsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  goalId: Scalars['ID'];
  q?: Maybe<Scalars['String']>;
};


export type Query_goalArgs = {
  id: Scalars['ID'];
};


export type Query_goalByKeyArgs = {
  key: Scalars['String'];
  trackViewEvent?: Maybe<TrackViewEvent>;
};


export type Query_goalsArgs = {
  ids: Array<Scalars['ID']>;
};


export type Query_goalSearchArgs = {
  after?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  createdAfter?: Maybe<Scalars['DateTime']>;
  createdBefore?: Maybe<Scalars['DateTime']>;
  expandGoals?: Maybe<Array<Maybe<Scalars['ID']>>>;
  first?: Maybe<Scalars['Int']>;
  owner?: Maybe<Array<Maybe<Scalars['String']>>>;
  phase?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  rootLevelGoal?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<GoalSortEnum>>>;
  tag?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  teamId?: Maybe<Array<Maybe<Scalars['String']>>>;
  watcher?: Maybe<Array<Maybe<Scalars['String']>>>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_goalSearchByOrgIdArgs = {
  after?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  organisationId: Scalars['String'];
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<GoalSortEnum>>>;
};


export type Query_goalsForJiraIssueArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  issueAri: Scalars['String'];
  sorts?: Maybe<Array<Maybe<GoalSortEnum>>>;
};


export type Query_goalsForJiraProjectArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  jiraProjectAri: Scalars['String'];
  showCompleted?: Maybe<Scalars['Boolean']>;
  sorts?: Maybe<Array<Maybe<GoalSortEnum>>>;
};


export type Query_goalStatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Query_goalStatusSummaryArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_goalStatusSummaryForTagArgs = {
  tagUuid?: Maybe<Scalars['UUID']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_goalTqlArgs = {
  after?: Maybe<Scalars['String']>;
  directoryViewUuid?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<Maybe<GoalSortEnum>>>;
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};


export type Query_goalTqlFullHierarchyArgs = {
  after?: Maybe<Scalars['String']>;
  childrenOf?: Maybe<Scalars['String']>;
  directoryViewUuid?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  managerAaids?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  sorts?: Maybe<Array<Maybe<GoalSortEnum>>>;
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};


export type Query_goalUpdateArgs = {
  id: Scalars['ID'];
};


export type Query_hasGoalsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_hasProjectsArgs = {
  includeArchived?: Maybe<Scalars['Boolean']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_helpPointersByTeamArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};


export type Query_helpPointerSearchArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_helpPointerTqlArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<HelpPointerSortEnum>>>;
  workspaceUuid: Scalars['UUID'];
};


export type Query_isFeatureEnabledArgs = {
  context?: Maybe<Array<Maybe<IsFeatureEnabledContextInput>>>;
  featureKey: Scalars['String'];
};


export type Query_jiraIssueSearchArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  includePartialSearch?: Maybe<Scalars['Boolean']>;
  issueTypeFilter: JiraIssueSearchIssueTypeFilter;
  q?: Maybe<Scalars['String']>;
};


export type Query_kudosByUuidArgs = {
  uuid: Scalars['UUID'];
};


export type Query_kudosTqlArgs = {
  after?: Maybe<Scalars['String']>;
  countOnly?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<KudosSortEnum>>>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_learningArgs = {
  id: Scalars['ID'];
};


export type Query_learningsByTeamArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};


export type Query_learningTqlArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<LearningSortEnum>>>;
  workspaceUuid: Scalars['UUID'];
};


export type Query_managersTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  organisationId: Scalars['String'];
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<DirectorySortEnum>>>;
};


export type Query_metricTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<MetricSortEnum>>>;
};


export type Query_msteamsChannelsByAriArgs = {
  azureId: Scalars['String'];
  channelNameQuery?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  resourceAri: Scalars['String'];
};


export type Query_nodeArgs = {
  id: Scalars['ID'];
};


export type Query_organizationsUserAdministersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Query_peopleFieldAggregatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  peopleFieldType?: Maybe<PeopleFieldType>;
  searchString?: Maybe<Scalars['String']>;
  sort?: Maybe<PeopleFieldAggregateSort>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_peopleTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  organisationId: Scalars['String'];
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<DirectorySortEnum>>>;
};


export type Query_projectArgs = {
  id: Scalars['ID'];
};


export type Query_projectByKeyArgs = {
  key: Scalars['String'];
  trackViewEvent?: Maybe<TrackViewEvent>;
};


export type Query_projectFusionContextArgs = {
  clientKey: Scalars['String'];
  issueKey?: Maybe<Scalars['String']>;
};


export type Query_projectsArgs = {
  ids: Array<Scalars['ID']>;
};


export type Query_projectSearchArgs = {
  after?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  commHealthRating?: Maybe<Array<Maybe<CommunicationHealth>>>;
  contributor?: Maybe<Array<Maybe<Scalars['String']>>>;
  createdAfter?: Maybe<Scalars['String']>;
  createdBefore?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  owner?: Maybe<Array<Maybe<Scalars['String']>>>;
  phase?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
  status?: Maybe<Array<Maybe<Scalars['String']>>>;
  tag?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  targetDateAfter?: Maybe<Scalars['String']>;
  targetDateBefore?: Maybe<Scalars['String']>;
  teamId?: Maybe<Array<Maybe<Scalars['String']>>>;
  visibility?: Maybe<ProjectVisibilityFilter>;
  watcher?: Maybe<Array<Maybe<Scalars['String']>>>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_projectSearchByOrgIdArgs = {
  after?: Maybe<Scalars['String']>;
  archived?: Maybe<Scalars['Boolean']>;
  first?: Maybe<Scalars['Int']>;
  organisationId: Scalars['String'];
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
};


export type Query_projectStatesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Query_projectStatusSummaryArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_projectStatusSummaryForTagArgs = {
  tagUuid?: Maybe<Scalars['UUID']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_projectTqlArgs = {
  after?: Maybe<Scalars['String']>;
  directoryViewUuid?: Maybe<Scalars['UUID']>;
  first?: Maybe<Scalars['Int']>;
  managerAaids?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  shouldRefetch?: Maybe<Scalars['Boolean']>;
  sort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
  workspaceId?: Maybe<Scalars['ID']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};


export type Query_projectUpdateArgs = {
  id: Scalars['ID'];
};


export type Query_recommendationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  orgId: Scalars['String'];
};


export type Query_reportingLinesArgs = {
  aaidOrHash?: Maybe<Scalars['String']>;
};


export type Query_slackChannelsByAriArgs = {
  channelNameQuery?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  resourceAri: Scalars['String'];
  teamId: Scalars['String'];
};


export type Query_slackChannelsByAriWithRetryableErrorsArgs = {
  channelNameQuery?: Maybe<Scalars['String']>;
  channelType?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  resourceAri: Scalars['String'];
  teamId: Scalars['String'];
};


export type Query_statusSummaryArgs = {
  endDate?: Maybe<Scalars['DateTime']>;
  startDate?: Maybe<Scalars['DateTime']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_suggestedContributorsArgs = {
  contributingEntityAri: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};


export type Query_suggestedFollowersArgs = {
  followingEntityAri: Scalars['String'];
  workspaceUuid: Scalars['UUID'];
};


export type Query_tagArgs = {
  id: Scalars['ID'];
};


export type Query_tagByAriArgs = {
  ari: Scalars['String'];
};


export type Query_tagByUuidArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type Query_tagsByAriArgs = {
  aris: Array<Scalars['String']>;
};


export type Query_tagsByUuidArgs = {
  uuids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
};


export type Query_tagSearchArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TagSortEnum>>>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_tagSearchByCloudIdArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<TagSortEnum>>>;
};


export type Query_tagTqlArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<TagSortEnum>>>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_teamByTeamIdArgs = {
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};


export type Query_teamHealthMonitorByUuidArgs = {
  uuid?: Maybe<Scalars['UUID']>;
};


export type Query_teamHealthMonitorsByTeamIdArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  sort?: Maybe<Array<Maybe<TeamHealthMonitorSortEnum>>>;
  teamId: Scalars['String'];
};


export type Query_teamsByTeamIdsArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  teamIds: Array<Maybe<Scalars['String']>>;
};


export type Query_teamsTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  organisationId: Scalars['String'];
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<DirectoryTeamSortEnum>>>;
};


export type Query_trendingTagsArgs = {
  first?: Maybe<Scalars['Int']>;
  workspaceUUID?: Maybe<Scalars['UUID']>;
};


export type Query_updatesForSavedViewArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  viewUuid: Scalars['UUID'];
  workspaceUuid: Scalars['UUID'];
};


export type Query_updatesWithTagArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['String']>;
  tagUuid?: Maybe<Scalars['UUID']>;
};


export type Query_updatesWithTeamArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
  workspaceUUID: Scalars['UUID'];
};


export type Query_userArgs = {
  id: Scalars['ID'];
};


export type Query_userByAaidArgs = {
  aaid: Scalars['String'];
  workspaceId?: Maybe<Scalars['ID']>;
};


export type Query_userOrganizationByContextArgs = {
  organizationContext?: Maybe<OrganizationContextInput>;
};


export type Query_userProfilesByAaidsArgs = {
  aaids: Array<Maybe<Scalars['String']>>;
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Query_userViewsArgs = {
  objectId: Scalars['String'];
  objectType: ViewObjectType;
  workspaceUuid: Scalars['UUID'];
};


export type Query_userWorkspaceArgs = {
  cloudId?: Maybe<Scalars['String']>;
  key?: Maybe<Scalars['String']>;
  tagId?: Maybe<Scalars['ID']>;
};


export type Query_userWorkspaceByContextArgs = {
  workspaceContext?: Maybe<WorkspaceContextInput>;
};


export type Query_workspaceArgs = {
  id: Scalars['ID'];
};


export type Query_workspaceByContextArgs = {
  workspaceContext?: Maybe<WorkspaceContextInput>;
};


export type Query_workspaceByUuidArgs = {
  uuid: Scalars['UUID'];
};


export type Query_workspaceCountArgs = {
  includePending?: Maybe<Scalars['Boolean']>;
};


export type Query_workspaceHadProductAtlasPreviouslyArgs = {
  cloudId: Scalars['String'];
};

export type Recommendation = {
  ari?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type RecommendationConnection = {
  __typename?: 'RecommendationConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<RecommendationEdge>>>;
  pageInfo: PageInfo;
};

export type RecommendationEdge = {
  __typename?: 'RecommendationEdge';
  cursor: Scalars['String'];
  node?: Maybe<Recommendation>;
};

export type RecordCount = {
  __typename?: 'RecordCount';
  recordCount: Scalars['Int'];
};

export type Relationship = {
  __typename?: 'Relationship';
  from: Scalars['String'];
  to: Scalars['String'];
};

export type RelationshipInput = {
  from: Scalars['String'];
  to: Scalars['String'];
};

export type removeCustomFieldDefinitionForEntityInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldId: Scalars['ID'];
  entity: CustomFieldEntity;
};

export type removeCustomFieldDefinitionForEntityPayload = {
  __typename?: 'removeCustomFieldDefinitionForEntityPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
};

export type removeGoalTeamLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalKey: Scalars['String'];
  teamId: Scalars['ID'];
};

export type removeGoalTeamLinkPayload = {
  __typename?: 'removeGoalTeamLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goalTeamEdge?: Maybe<GoalTeamEdge>;
};

export type removeMetricTargetInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  metricTargetId: Scalars['ID'];
  owningEntityId: Scalars['ID'];
};

export type removeMetricTargetPayload = {
  __typename?: 'removeMetricTargetPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  owningNode: Node;
};

export type removeProjectMemberInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  removeFromTeam?: Maybe<Scalars['String']>;
};

export type removeProjectMemberPayload = {
  __typename?: 'removeProjectMemberPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type removeProjectTeamLinkInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  removeMembers?: Maybe<Scalars['Boolean']>;
  teamId: Scalars['String'];
};

export type removeProjectTeamLinkPayload = {
  __typename?: 'removeProjectTeamLinkPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
  team?: Maybe<Team>;
};

export type removeTagsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tagUuids: Array<Maybe<Scalars['String']>>;
};

export type removeTagsPayload = {
  __typename?: 'removeTagsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type removeTeamMemberInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type removeTeamMemberPayload = {
  __typename?: 'removeTeamMemberPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
};

export type removeTeamWatcherInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  teamId: Scalars['String'];
};

export type removeTeamWatcherPayload = {
  __typename?: 'removeTeamWatcherPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type removeUserTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  tagUuid: Scalars['UUID'];
};

export type removeUserTagPayload = {
  __typename?: 'removeUserTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type removeWatcherAggInput = {
  ari: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
};

export type removeWatcherInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type removeWatcherPayload = {
  __typename?: 'removeWatcherPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  isWatching: Scalars['Boolean'];
};

export type renameTeamInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  teamId: Scalars['String'];
};

export type renameTeamPayload = {
  __typename?: 'renameTeamPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  team?: Maybe<Team>;
};

export type ReportCounts = {
  __typename?: 'ReportCounts';
  directReports: Scalars['Int'];
  totalReports: Scalars['Int'];
};

export type ReportingLines = {
  __typename?: 'ReportingLines';
  lastModifiedDate?: Maybe<Scalars['String']>;
  managers?: Maybe<Array<Maybe<ReportingLinesUser>>>;
  peers?: Maybe<Array<Maybe<ReportingLinesUser>>>;
  reports?: Maybe<Array<Maybe<ReportingLinesUser>>>;
  user?: Maybe<ReportingLinesUser>;
};

export type ReportingLinesUser = {
  __typename?: 'ReportingLinesUser';
  accountIdentifier?: Maybe<Scalars['String']>;
  identifierType?: Maybe<AccountIdentifierType>;
  numberOfDirectReports: Scalars['Int'];
  numberOfTotalReports: Scalars['Int'];
  pii?: Maybe<UserPII>;
};

export type Risk = Highlight & Node & {
  __typename?: 'Risk';
  ari: Scalars['String'];
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  id: Scalars['ID'];
  lastEditedBy?: Maybe<User>;
  lastEditedDate?: Maybe<Scalars['DateTime']>;
  project?: Maybe<Project>;
  resolvedDate?: Maybe<Scalars['DateTime']>;
  summary?: Maybe<Scalars['String']>;
  uuid: Scalars['UUID'];
};

export type RiskConnection = {
  __typename?: 'RiskConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<RiskEdge>>>;
  pageInfo: PageInfo;
};

export type RiskEdge = {
  __typename?: 'RiskEdge';
  cursor: Scalars['String'];
  node?: Maybe<Risk>;
};

export type saveDraftProjectUpdatePayload = {
  __typename?: 'saveDraftProjectUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  draftUpdate?: Maybe<DraftUpdate>;
};

export type saveUserOnboardingInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  onboardingKey?: Maybe<OnboardingItemKey>;
};

export type saveUserOnboardingPayload = {
  __typename?: 'saveUserOnboardingPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Onboarding>;
};

export type sendAtlasUpgradeRequestInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  feature?: Maybe<UpgradeRequestVariation>;
  workspaceUuid: Scalars['UUID'];
};

export type sendAtlasUpgradeRequestPayload = {
  __typename?: 'sendAtlasUpgradeRequestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isSend: Scalars['Boolean'];
};

export type sendSampleDigestInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  workspaceUuid?: Maybe<Scalars['UUID']>;
};

export type sendSampleDigestPayload = {
  __typename?: 'sendSampleDigestPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isSend: Scalars['Boolean'];
};

export type setGoalRollupProgressInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  enableRollupProgress: Scalars['Boolean'];
  goalId: Scalars['ID'];
};

export type setGoalRollupProgressPayload = {
  __typename?: 'setGoalRollupProgressPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal: Goal;
};

export type setIsGoalArchivedInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  isArchived: Scalars['Boolean'];
  updateDescendantGoals?: Maybe<Scalars['Boolean']>;
};

export type setIsGoalArchivedPayload = {
  __typename?: 'setIsGoalArchivedPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
};

export type setIsMetricArchivedInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  metricId: Scalars['ID'];
};

export type setIsMetricArchivedPayload = {
  __typename?: 'setIsMetricArchivedPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  metric?: Maybe<Metric>;
};

export type setIsProjectArchivedInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  isArchived: Scalars['Boolean'];
  projectId: Scalars['ID'];
};

export type setIsProjectArchivedPayload = {
  __typename?: 'setIsProjectArchivedPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type setParentGoalAggInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalAri: Scalars['String'];
  parentGoalAri?: Maybe<Scalars['String']>;
};

export type setParentGoalInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  goalId: Scalars['ID'];
  parentGoalId?: Maybe<Scalars['ID']>;
};

export type setParentGoalPayload = {
  __typename?: 'setParentGoalPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  goal?: Maybe<Goal>;
  parentGoal?: Maybe<Goal>;
};

export type setProjectDependencyInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  incomingProjectId: Scalars['ID'];
  outgoingProjectId: Scalars['ID'];
  type: ProjectDependencyRelationship;
};

export type setProjectDependencyPayload = {
  __typename?: 'setProjectDependencyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type setProjectOwnerInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
};

export type setProjectOwnerPayload = {
  __typename?: 'setProjectOwnerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type setProjectRoleInput = {
  accountId: Scalars['String'];
  clientMutationId?: Maybe<Scalars['String']>;
  projectKey: Scalars['String'];
  role: ProjectRole;
};

export type setProjectRolePayload = {
  __typename?: 'setProjectRolePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  project?: Maybe<Project>;
};

export type setTagsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  tagUuids: Array<Maybe<Scalars['String']>>;
};

export type setTagsPayload = {
  __typename?: 'setTagsPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  tags?: Maybe<Array<Maybe<Tag>>>;
};

export type setWorkspaceGlobalArchivedInput = {
  cloudId: Scalars['String'];
  isArchived?: Maybe<Scalars['Boolean']>;
};

export type setWorkspaceGlobalArchivedStatePayload = {
  __typename?: 'setWorkspaceGlobalArchivedStatePayload';
  workspace?: Maybe<Workspace>;
};

export type setWorkspaceOnboardedInput = {
  workspaceUuid: Scalars['UUID'];
};

export type setWorkspaceOnboardedPayload = {
  __typename?: 'setWorkspaceOnboardedPayload';
  workspace?: Maybe<Workspace>;
};

export type shareGoalInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailUserIdentifier>>>;
  goalKey: Scalars['String'];
  users: Array<Maybe<UserIdentifierInput>>;
};

export type shareGoalPayload = {
  __typename?: 'shareGoalPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailsAdded?: Maybe<Array<Maybe<shareProjectByEmailPayload>>>;
  goal?: Maybe<Goal>;
  usersAdded?: Maybe<Array<Maybe<shareProjectByAaidPayload>>>;
};

export type shareProjectByAaidPayload = {
  __typename?: 'shareProjectByAaidPayload';
  accountId?: Maybe<Scalars['String']>;
  addedAsWatcher?: Maybe<Scalars['Boolean']>;
  invitationStatus?: Maybe<InvitationStatus>;
  pendingWorkspaceMembershipCreated?: Maybe<Scalars['Boolean']>;
};

export type shareProjectByEmailPayload = {
  __typename?: 'shareProjectByEmailPayload';
  addedAsWatcher?: Maybe<Scalars['Boolean']>;
  email?: Maybe<Scalars['String']>;
  invitationStatus?: Maybe<InvitationStatus>;
  pendingWorkspaceMembershipCreated?: Maybe<Scalars['Boolean']>;
};

export type shareProjectInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  emails?: Maybe<Array<Maybe<EmailUserIdentifier>>>;
  projectKey: Scalars['String'];
  users: Array<Maybe<UserIdentifierInput>>;
};

export type shareProjectPayload = {
  __typename?: 'shareProjectPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  emailsAdded?: Maybe<Array<Maybe<shareProjectByEmailPayload>>>;
  project?: Maybe<Project>;
  usersAdded?: Maybe<Array<Maybe<shareProjectByAaidPayload>>>;
};

export type shareUpdateInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  recipientAccountId?: Maybe<Scalars['String']>;
  recipientEmailAddress?: Maybe<Scalars['String']>;
  updateId: Scalars['ID'];
};

export type shareUpdatePayload = {
  __typename?: 'shareUpdatePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  isShared: Scalars['Boolean'];
};

export enum ShortcutsTypeEnum {
  MultiKey = 'MULTI_KEY',
  SingleKey = 'SINGLE_KEY'
}

export type Site = {
  __typename?: 'Site';
  cloudId?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  productActivations?: Maybe<ProductActivationConnection>;
};


export type Site_productActivationsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export enum SiteInviteState {
  Failed = 'FAILED',
  Invited = 'INVITED',
  Pending = 'PENDING'
}

export type SlackChannel = {
  __typename?: 'SlackChannel';
  creator?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  loggedIn?: Maybe<Scalars['Boolean']>;
  name?: Maybe<Scalars['String']>;
  numMembers?: Maybe<Scalars['Int']>;
  private?: Maybe<Scalars['Boolean']>;
  slackTeamId?: Maybe<Scalars['String']>;
  slackTeamName?: Maybe<Scalars['String']>;
};

export type SlackChannelQueryResponse = {
  __typename?: 'SlackChannelQueryResponse';
  channels?: Maybe<Array<Maybe<SlackChannel>>>;
  error?: Maybe<Scalars['String']>;
};

export type SlackConnection = {
  __typename?: 'SlackConnection';
  channel?: Maybe<SlackChannel>;
  creationDate?: Maybe<Scalars['String']>;
  metadata?: Maybe<SlackConnectionMetadata>;
  subscriberAaid?: Maybe<Scalars['String']>;
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type SlackConnectionConnection = {
  __typename?: 'SlackConnectionConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<SlackConnectionEdge>>>;
  pageInfo: PageInfo;
};

export type SlackConnectionEdge = {
  __typename?: 'SlackConnectionEdge';
  cursor: Scalars['String'];
  node?: Maybe<SlackConnection>;
};

export type SlackConnectionMetadata = {
  __typename?: 'SlackConnectionMetadata';
  fieldTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type StandardDirectoryViewColumn = Node & {
  __typename?: 'StandardDirectoryViewColumn';
  id: Scalars['ID'];
  name?: Maybe<StandardDirectoryViewColumnName>;
};

export enum StandardDirectoryViewColumnName {
  Contributors = 'CONTRIBUTORS',
  FollowerCount = 'FOLLOWER_COUNT',
  Following = 'FOLLOWING',
  Goals = 'GOALS',
  LastUpdated = 'LAST_UPDATED',
  Links = 'LINKS',
  Owner = 'OWNER',
  Progress = 'PROGRESS',
  RelatedProjects = 'RELATED_PROJECTS',
  StartDate = 'START_DATE',
  Status = 'STATUS',
  SubGoals = 'SUB_GOALS',
  Tags = 'TAGS',
  TargetDate = 'TARGET_DATE',
  Teams = 'TEAMS',
  WorkTracking = 'WORK_TRACKING'
}

export type Status = Node & {
  __typename?: 'Status';
  displayName?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
  order: Scalars['Int'];
};

export enum StatusSortEnum {
  OrderAsc = 'ORDER_ASC',
  OrderDesc = 'ORDER_DESC'
}

export type StatusSummary = {
  __typename?: 'StatusSummary';
  active: Scalars['Int'];
  atRisk: Scalars['Int'];
  cancelled: Scalars['Int'];
  done: Scalars['Int'];
  missed: Scalars['Int'];
  offTrack: Scalars['Int'];
  onTrack: Scalars['Int'];
};

export type StatusSummaryForTag = {
  __typename?: 'StatusSummaryForTag';
  atRisk: Scalars['Int'];
  cancelled: Scalars['Int'];
  done: Scalars['Int'];
  offTrack: Scalars['Int'];
  onTrack: Scalars['Int'];
  paused: Scalars['Int'];
  pending: Scalars['Int'];
};

export type SupportedCustomFields = {
  __typename?: 'SupportedCustomFields';
  entities?: Maybe<Array<Maybe<CustomFieldEntity>>>;
  types?: Maybe<Array<Maybe<CustomFieldType>>>;
  typesConfig?: Maybe<Array<Maybe<CustomFieldTypeConfig>>>;
};

export type Tag = Node & {
  __typename?: 'Tag';
  ari: Scalars['String'];
  creationDate?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  goals?: Maybe<GoalConnection>;
  goalUsageCount: Scalars['Int'];
  helpLinks?: Maybe<HelpPointerConnection>;
  helpPointerUsageCount: Scalars['Int'];
  iconData?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  msteamsChannels?: Maybe<MsteamsConnections>;
  name?: Maybe<Scalars['String']>;
  projects?: Maybe<ProjectConnection>;
  projectUsageCount: Scalars['Int'];
  slackChannels?: Maybe<SlackConnectionConnection>;
  topContributors?: Maybe<UserConnection>;
  topTeams?: Maybe<TeamConnection>;
  url?: Maybe<Scalars['String']>;
  usageCount: Scalars['Int'];
  uuid?: Maybe<Scalars['UUID']>;
  watcherCount: Scalars['Int'];
  watchers?: Maybe<UserConnection>;
  watching?: Maybe<Scalars['Boolean']>;
  workspaceId: Scalars['Int'];
};


export type Tag_goalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_helpLinksArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_msteamsChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_projectsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_slackChannelsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_topContributorsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_topTeamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Tag_watchersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TagConnection = {
  __typename?: 'TagConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<TagEdge>>>;
  pageInfo: PageInfo;
};

export type TagEdge = {
  __typename?: 'TagEdge';
  cursor: Scalars['String'];
  node?: Maybe<Tag>;
};

export enum TagSortEnum {
  EntityCountDesc = 'ENTITY_COUNT_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC',
  NameAsc = 'NAME_ASC',
  NameDesc = 'NAME_DESC',
  UsageAsc = 'USAGE_ASC',
  UsageDesc = 'USAGE_DESC',
  WatcherAsc = 'WATCHER_ASC',
  WatcherDesc = 'WATCHER_DESC'
}

export type TargetDate = {
  __typename?: 'TargetDate';
  confidence?: Maybe<TargetDateType>;
  dateRange?: Maybe<TargetDateRange>;
  label?: Maybe<Scalars['String']>;
  localizedLabel?: Maybe<LocalizationField>;
  overdue?: Maybe<Scalars['Boolean']>;
};


export type TargetDate_labelArgs = {
  longFormat?: Maybe<Scalars['Boolean']>;
};


export type TargetDate_localizedLabelArgs = {
  longFormat?: Maybe<Scalars['Boolean']>;
};

export type TargetDateInput = {
  confidence?: Maybe<TargetDateType>;
  date?: Maybe<Scalars['Date']>;
};

export type TargetDateRange = {
  __typename?: 'TargetDateRange';
  end?: Maybe<Scalars['Instant']>;
  start?: Maybe<Scalars['Instant']>;
};

export enum TargetDateType {
  Day = 'DAY',
  Month = 'MONTH',
  Quarter = 'QUARTER'
}

export type Team = Node & {
  __typename?: 'Team';
  atlasMembers?: Maybe<TeamMemberConnection>;
  avatarUrl?: Maybe<Scalars['String']>;
  goals?: Maybe<GoalConnection>;
  helpPointers?: Maybe<HelpPointerConnection>;
  id: Scalars['ID'];
  members?: Maybe<TeamMemberConnection>;
  membersOfProject?: Maybe<UserConnection>;
  name?: Maybe<Scalars['String']>;
  permission?: Maybe<TeamPermission>;
  projects?: Maybe<ProjectConnection>;
  teamDetails?: Maybe<TeamDetails>;
  teamId?: Maybe<Scalars['String']>;
  watchers?: Maybe<UserConnection>;
  watching?: Maybe<Scalars['Boolean']>;
};


export type Team_atlasMembersArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
};


export type Team_goalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Team_helpPointersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Team_membersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type Team_membersOfProjectArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  key: Scalars['String'];
};


export type Team_permissionArgs = {
  cloudId?: Maybe<Scalars['String']>;
};


export type Team_projectsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  tql?: Maybe<Scalars['String']>;
};


export type Team_watchersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TeamConnection = {
  __typename?: 'TeamConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<TeamEdge>>>;
  pageInfo: PageInfo;
};

export type TeamDetails = {
  __typename?: 'TeamDetails';
  description?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  membershipSettings?: Maybe<TeamMembershipSettings>;
  name?: Maybe<Scalars['String']>;
};

export enum TeamDiscoverability {
  Discoverable = 'DISCOVERABLE'
}

export type TeamEdge = {
  __typename?: 'TeamEdge';
  cursor: Scalars['String'];
  node?: Maybe<Team>;
};

export enum TeamHealthAttribute {
  BalancedTeam = 'BALANCED_TEAM',
  ContinuousImprovement = 'CONTINUOUS_IMPROVEMENT',
  EncouragingDifference = 'ENCOURAGING_DIFFERENCE',
  EngagementAndSupport = 'ENGAGEMENT_AND_SUPPORT',
  SharedUnderstanding = 'SHARED_UNDERSTANDING',
  TeamCohesion = 'TEAM_COHESION',
  ValueAndMetrics = 'VALUE_AND_METRICS',
  WaysOfWorking = 'WAYS_OF_WORKING'
}

export type TeamHealthHealthyStatus = {
  __typename?: 'TeamHealthHealthyStatus';
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type TeamHealthMonitor = {
  __typename?: 'TeamHealthMonitor';
  completionDate?: Maybe<Scalars['DateTime']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  isComplete?: Maybe<Scalars['Boolean']>;
  owner?: Maybe<User>;
  recommendedPlays?: Maybe<Array<Maybe<TeamHealthPlay>>>;
  status?: Maybe<TeamHealthMonitorStatus>;
  team?: Maybe<Team>;
  teamHealthMonitorResults?: Maybe<Array<Maybe<TeamHealthMonitorResult>>>;
  uuid?: Maybe<Scalars['UUID']>;
};


export type TeamHealthMonitor_isCompleteArgs = {
  cloudId?: Maybe<Scalars['String']>;
};


export type TeamHealthMonitor_recommendedPlaysArgs = {
  cloudId?: Maybe<Scalars['String']>;
};


export type TeamHealthMonitor_teamArgs = {
  cloudId?: Maybe<Scalars['String']>;
};


export type TeamHealthMonitor_teamHealthMonitorResultsArgs = {
  cloudId?: Maybe<Scalars['String']>;
};

export type TeamHealthMonitorConnection = {
  __typename?: 'TeamHealthMonitorConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<TeamHealthMonitorEdge>>>;
  pageInfo: PageInfo;
};

export type TeamHealthMonitorEdge = {
  __typename?: 'TeamHealthMonitorEdge';
  cursor: Scalars['String'];
  node?: Maybe<TeamHealthMonitor>;
};

export enum TeamHealthMonitorFormField {
  TextNotes = 'TEXT_NOTES',
  TrafficLight = 'TRAFFIC_LIGHT'
}

export type TeamHealthMonitorQuestion = {
  __typename?: 'TeamHealthMonitorQuestion';
  attribute?: Maybe<TeamHealthAttribute>;
  description?: Maybe<Scalars['String']>;
  displayName?: Maybe<Scalars['String']>;
  displayNumber?: Maybe<Scalars['Int']>;
  formFields?: Maybe<Array<Maybe<TeamHealthMonitorFormField>>>;
};

export type TeamHealthMonitorRatingInput = {
  attribute: TeamHealthAttribute;
  notes?: Maybe<Scalars['String']>;
  rating: TeamHealthRating;
};

export type TeamHealthMonitorResult = {
  __typename?: 'TeamHealthMonitorResult';
  attribute?: Maybe<TeamHealthAttribute>;
  id: Scalars['ID'];
  notes?: Maybe<Scalars['String']>;
  rating?: Maybe<TeamHealthRating>;
  user?: Maybe<User>;
};

export enum TeamHealthMonitorSortEnum {
  CreationDateAsc = 'CREATION_DATE_ASC',
  CreationDateDesc = 'CREATION_DATE_DESC',
  IdAsc = 'ID_ASC',
  IdDesc = 'ID_DESC'
}

export enum TeamHealthMonitorStatus {
  Archived = 'ARCHIVED',
  Created = 'CREATED'
}

export type TeamHealthPlay = TeamHealthHealthyStatus | TeamHealthRecommendedPlay;

export enum TeamHealthRating {
  Green = 'GREEN',
  Red = 'RED',
  Yellow = 'YELLOW'
}

export type TeamHealthRecommendedPlay = {
  __typename?: 'TeamHealthRecommendedPlay';
  attributes?: Maybe<Array<Maybe<TeamHealthAttribute>>>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  playSize?: Maybe<Scalars['String']>;
  preparationTime?: Maybe<Scalars['String']>;
  sessionDuration?: Maybe<Scalars['String']>;
};

export type TeamMember = {
  __typename?: 'TeamMember';
  aaid?: Maybe<Scalars['String']>;
  pii?: Maybe<UserPII>;
  state?: Maybe<MembershipState>;
};

export type TeamMemberConnection = {
  __typename?: 'TeamMemberConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<TeamMemberEdge>>>;
  pageInfo: PageInfo;
};

export type TeamMemberEdge = {
  __typename?: 'TeamMemberEdge';
  cursor: Scalars['String'];
  node?: Maybe<TeamMember>;
};

export enum TeamMembershipSettings {
  AdminInvite = 'ADMIN_INVITE',
  External = 'EXTERNAL',
  MemberInvite = 'MEMBER_INVITE',
  Open = 'OPEN'
}

export enum TeamPermission {
  FullRead = 'FULL_READ',
  FullWrite = 'FULL_WRITE',
  None = 'NONE'
}

export type TeamResponseBody = {
  __typename?: 'TeamResponseBody';
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  slackUserId?: Maybe<Scalars['String']>;
};

export enum TeamRestriction {
  NoRestriction = 'NO_RESTRICTION',
  OrgMembers = 'ORG_MEMBERS'
}

export type TeamSearchResponse = {
  __typename?: 'TeamSearchResponse';
  description?: Maybe<Scalars['String']>;
  discoverable?: Maybe<TeamDiscoverability>;
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  isVerified?: Maybe<Scalars['Boolean']>;
  largeAvatarImageUrl?: Maybe<Scalars['String']>;
  members?: Maybe<TeamMemberConnection>;
  membershipSettings?: Maybe<TeamMembershipSettings>;
  organizationId?: Maybe<Scalars['String']>;
  restriction?: Maybe<TeamRestriction>;
  smallAvatarImageUrl?: Maybe<Scalars['String']>;
  state?: Maybe<TeamState>;
};


export type TeamSearchResponse_membersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TeamSearchResponseConnection = {
  __typename?: 'TeamSearchResponseConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<TeamSearchResponseEdge>>>;
  pageInfo: PageInfo;
};

export type TeamSearchResponseEdge = {
  __typename?: 'TeamSearchResponseEdge';
  cursor: Scalars['String'];
  node?: Maybe<TeamSearchResponse>;
};

export enum TeamState {
  Active = 'ACTIVE',
  Disbanded = 'DISBANDED',
  Purged = 'PURGED'
}

export type TenantResponseBody = {
  __typename?: 'TenantResponseBody';
  azureId?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
};

export type TextCustomField = CustomFieldNode & Node & {
  __typename?: 'TextCustomField';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  definition?: Maybe<CustomFieldDefinition>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['UUID']>;
  value?: Maybe<CustomFieldTextSavedValueNode>;
};

export type TextCustomFieldDefinition = CustomFieldDefinitionNode & Node & {
  __typename?: 'TextCustomFieldDefinition';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  linkedEntityTypes?: Maybe<Array<CustomFieldEntity>>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomFieldType>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type TextSelectCustomField = CustomFieldNode & Node & {
  __typename?: 'TextSelectCustomField';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  definition?: Maybe<CustomFieldDefinition>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['UUID']>;
  values?: Maybe<CustomFieldTextSavedValueConnection>;
};


export type TextSelectCustomField_valuesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type TextSelectCustomFieldDefinition = CustomFieldDefinitionNode & Node & {
  __typename?: 'TextSelectCustomFieldDefinition';
  allowedValues?: Maybe<CustomFieldTextAllowedValueConnection>;
  canSetMultipleValues?: Maybe<Scalars['Boolean']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  linkedEntityTypes?: Maybe<Array<CustomFieldEntity>>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomFieldType>;
  uuid?: Maybe<Scalars['UUID']>;
};


export type TextSelectCustomFieldDefinition_allowedValuesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type ThemeURIs = {
  __typename?: 'ThemeURIs';
  dark?: Maybe<Scalars['URI']>;
  light?: Maybe<Scalars['URI']>;
};

export enum TimelineDateSpan {
  Month = 'month',
  Week = 'week'
}

export type Townsquare = {
  __typename?: 'Townsquare';
  allWorkspacesForOrg?: Maybe<WorkspaceConnection>;
  allWorkspaceSummariesForOrg?: Maybe<WorkspaceSummaryConnection>;
  commentsByAri?: Maybe<Array<Maybe<Comment>>>;
  goal?: Maybe<Goal>;
  goalByKey?: Maybe<Goal>;
  goalsByAri?: Maybe<Array<Maybe<Goal>>>;
  goalSearch?: Maybe<GoalConnection>;
  goalTql?: Maybe<GoalConnection>;
  goalTypes?: Maybe<GoalTypeConnection>;
  helpPointersByCloudIds?: Maybe<HelpPointerConnection>;
  helpPointersByTeam?: Maybe<HelpPointerConnection>;
  helpPointerSearch?: Maybe<HelpPointerSearchResponse>;
  project?: Maybe<Project>;
  projectByKey?: Maybe<Project>;
  projectsByAri?: Maybe<Array<Maybe<Project>>>;
  projectSearch?: Maybe<ProjectConnection>;
  projectTql?: Maybe<ProjectConnection>;
};


export type Townsquare_allWorkspacesForOrgArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  organisationId?: Maybe<Scalars['String']>;
};


export type Townsquare_allWorkspaceSummariesForOrgArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
};


export type Townsquare_commentsByAriArgs = {
  aris?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Townsquare_goalArgs = {
  ari: Scalars['String'];
};


export type Townsquare_goalByKeyArgs = {
  key: Scalars['String'];
};


export type Townsquare_goalsByAriArgs = {
  aris?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Townsquare_goalSearchArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<GoalSortEnum>>>;
};


export type Townsquare_goalTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<GoalSortEnum>>>;
};


export type Townsquare_goalTypesArgs = {
  after?: Maybe<Scalars['String']>;
  containerId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  includeDisabled?: Maybe<Scalars['Boolean']>;
};


export type Townsquare_helpPointersByCloudIdsArgs = {
  after?: Maybe<Scalars['String']>;
  cloudIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  first?: Maybe<Scalars['Int']>;
};


export type Townsquare_helpPointersByTeamArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  teamId: Scalars['String'];
};


export type Townsquare_helpPointerSearchArgs = {
  after?: Maybe<Scalars['String']>;
  cloudIds?: Maybe<Array<Maybe<Scalars['String']>>>;
  first?: Maybe<Scalars['Int']>;
  q?: Maybe<Scalars['String']>;
};


export type Townsquare_projectArgs = {
  ari: Scalars['String'];
};


export type Townsquare_projectByKeyArgs = {
  key: Scalars['String'];
};


export type Townsquare_projectsByAriArgs = {
  aris?: Maybe<Array<Maybe<Scalars['String']>>>;
};


export type Townsquare_projectSearchArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  first?: Maybe<Scalars['Int']>;
  phase?: Maybe<Array<Maybe<Scalars['String']>>>;
  q?: Maybe<Scalars['String']>;
  sort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
};


export type Townsquare_projectTqlArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  containerId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  q: Scalars['String'];
  sort?: Maybe<Array<Maybe<ProjectSortEnum>>>;
};

export type TownsquareGoalRecommendation = {
  ari?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type TownsquareGoalWentOffTrackAtRiskRecommendation = Recommendation & TownsquareGoalRecommendation & {
  __typename?: 'TownsquareGoalWentOffTrackAtRiskRecommendation';
  ari?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
};

export type TownsquareMutationApi = {
  __typename?: 'TownsquareMutationApi';
  addWatcherByAccountIds?: Maybe<addWatcherByAccountIdsPayload>;
  archiveGoal?: Maybe<setIsGoalArchivedPayload>;
  createGoal?: Maybe<createGoalPayload>;
  createRelationships?: Maybe<createRelationshipsPayload>;
  deleteRelationships?: Maybe<deleteRelationshipsPayload>;
  editGoal?: Maybe<editGoalPayload>;
  editGoalType?: Maybe<editGoalTypePayload>;
  removeWatcher?: Maybe<removeWatcherPayload>;
  setParentGoal?: Maybe<setParentGoalPayload>;
  unarchiveGoal?: Maybe<setIsGoalArchivedPayload>;
};


export type TownsquareMutationApi_addWatcherByAccountIdsArgs = {
  input: addWatcherByAccountIdsAggInput;
};


export type TownsquareMutationApi_archiveGoalArgs = {
  input: archiveGoalAggInput;
};


export type TownsquareMutationApi_createGoalArgs = {
  input: createGoalAggInput;
};


export type TownsquareMutationApi_createRelationshipsArgs = {
  input: createRelationshipsInput;
};


export type TownsquareMutationApi_deleteRelationshipsArgs = {
  input: deleteRelationshipsInput;
};


export type TownsquareMutationApi_editGoalArgs = {
  input: editGoalAggInput;
};


export type TownsquareMutationApi_editGoalTypeArgs = {
  input: editGoalTypeAggInput;
};


export type TownsquareMutationApi_removeWatcherArgs = {
  input: removeWatcherAggInput;
};


export type TownsquareMutationApi_setParentGoalArgs = {
  input?: Maybe<setParentGoalAggInput>;
};


export type TownsquareMutationApi_unarchiveGoalArgs = {
  input: archiveGoalAggInput;
};

export type TownsquareProductActivation = ProductActivation & {
  __typename?: 'TownsquareProductActivation';
  cloudId?: Maybe<Scalars['String']>;
};

export enum TqlEntity {
  CustomField = 'CUSTOM_FIELD',
  DirectoryView = 'DIRECTORY_VIEW',
  Goal = 'GOAL',
  HelpPointer = 'HELP_POINTER',
  Kudos = 'KUDOS',
  Learning = 'LEARNING',
  Person = 'PERSON',
  Project = 'PROJECT',
  Tag = 'TAG',
  Team = 'TEAM',
  Unknown = 'UNKNOWN'
}

export type TQLExplanation = {
  __typename?: 'TQLExplanation';
  availableFields?: Maybe<Array<Maybe<TQLExplanationField>>>;
  costThreshold: Scalars['Int'];
  entity?: Maybe<TqlEntity>;
  explanation?: Maybe<Scalars['String']>;
  logSafeQuery?: Maybe<Scalars['String']>;
  overBudget: Scalars['Boolean'];
  queryCost: Scalars['Int'];
};

export type TQLExplanationField = {
  __typename?: 'TQLExplanationField';
  aliases?: Maybe<Array<Maybe<Scalars['String']>>>;
  cost: Scalars['Int'];
  description?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  operators?: Maybe<Array<Maybe<Scalars['String']>>>;
  type?: Maybe<Type>;
};

export type trackInviteInput = {
  inviteAction: Scalars['String'];
  invitedAccountId: Scalars['String'];
  workspaceUuid: Scalars['String'];
};

export type trackInvitePayload = {
  __typename?: 'trackInvitePayload';
  success?: Maybe<Scalars['Boolean']>;
};

export enum TrackViewEvent {
  Direct = 'DIRECT',
  Indirect = 'INDIRECT',
  Skip = 'SKIP'
}

export enum Type {
  Integer = 'Integer',
  Numeric = 'Numeric',
  String = 'String',
  User = 'User'
}

export type Update = GoalUpdate | ProjectUpdate;

export type updateCommentInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  commentText: Scalars['JSONString'];
  id?: Maybe<Scalars['ID']>;
};

export type updateCommentPayload = {
  __typename?: 'updateCommentPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  comment?: Maybe<Comment>;
};

export type UpdateConnection = {
  __typename?: 'UpdateConnection';
  edges?: Maybe<Array<Maybe<UpdateEdge>>>;
  pageInfo: PageInfo;
};

export type UpdateCustomFieldDefinitionError = CustomFieldNameAlreadyExistsError;

export type updateCustomFieldDefinitionInput = {
  allowedValueChanges?: Maybe<allowedValueChangesInput>;
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  name?: Maybe<Scalars['String']>;
};

export type updateCustomFieldDefinitionPayload = {
  __typename?: 'updateCustomFieldDefinitionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customFieldDefinition?: Maybe<CustomFieldDefinition>;
  error?: Maybe<UpdateCustomFieldDefinitionError>;
};

export type updateCustomFieldFreeformValueData = {
  number?: Maybe<Scalars['Float']>;
  text?: Maybe<Scalars['String']>;
  user?: Maybe<Scalars['String']>;
};

export type updateCustomFieldInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  operation: updateCustomFieldOperationInput;
};

export type updateCustomFieldOperationInput = {
  addFreeformValue?: Maybe<updateCustomFieldFreeformValueData>;
  addPredefinedValue?: Maybe<Scalars['ID']>;
  removeValue?: Maybe<Scalars['ID']>;
};

export type updateCustomFieldPayload = {
  __typename?: 'updateCustomFieldPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  customField?: Maybe<CustomField>;
};

export type updateDecisionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  summary?: Maybe<Scalars['String']>;
};

export type updateDecisionPayload = {
  __typename?: 'updateDecisionPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  decision?: Maybe<Decision>;
};

export type UpdateEdge = {
  __typename?: 'UpdateEdge';
  cursor: Scalars['String'];
  node?: Maybe<Update>;
};

export type updateHelpPointerInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  icon?: Maybe<IconInput>;
  id: Scalars['ID'];
  link?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  newTeamName?: Maybe<Scalars['String']>;
  tagUuids?: Maybe<Array<Maybe<Scalars['UUID']>>>;
  teamId?: Maybe<Scalars['String']>;
  type?: Maybe<HelpPointerType>;
};

export type updateHelpPointerPayload = {
  __typename?: 'updateHelpPointerPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  helpPointer?: Maybe<HelpPointer>;
};

export type UpdateHighlightInput = {
  description: Scalars['String'];
  summary: Scalars['String'];
  type: LearningType;
};

export type updateLearningInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  isResolved?: Maybe<Scalars['Boolean']>;
  summary?: Maybe<Scalars['String']>;
  type?: Maybe<LearningType>;
};

export type updateLearningPayload = {
  __typename?: 'updateLearningPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  learning?: Maybe<Learning>;
};

export type updateMilestoneInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  completionDate?: Maybe<Scalars['Date']>;
  isCompleted?: Maybe<Scalars['Boolean']>;
  milestoneId: Scalars['ID'];
  status?: Maybe<MilestoneStatus>;
  targetDate?: Maybe<Scalars['Date']>;
  targetDateType?: Maybe<TargetDateType>;
  title?: Maybe<Scalars['String']>;
};

export type updateMilestonePayload = {
  __typename?: 'updateMilestonePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  milestone?: Maybe<Milestone>;
};

export type UpdateNote = Node & {
  __typename?: 'UpdateNote';
  archived: Scalars['Boolean'];
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  goalUpdate?: Maybe<GoalUpdate>;
  id: Scalars['ID'];
  index?: Maybe<Scalars['Int']>;
  projectUpdate?: Maybe<ProjectUpdate>;
  summary?: Maybe<Scalars['String']>;
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type UpdateNoteInput = {
  archived?: Maybe<Scalars['Boolean']>;
  summary: Scalars['String'];
  title?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type updateResourceInvitationSettingsInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  domainsApprovedForDESPromotion?: Maybe<Array<Maybe<Scalars['String']>>>;
  location: DomainEnabledSignupLocation;
  openInviteMode?: Maybe<OpenInviteMode>;
};

export type updateResourceInvitationSettingsPayload = {
  __typename?: 'updateResourceInvitationSettingsPayload';
  approvedDomainStatusCode?: Maybe<Scalars['Int']>;
  clientMutationId?: Maybe<Scalars['String']>;
  openInviteStatusCode?: Maybe<Scalars['Int']>;
};

export type updateRiskInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  summary?: Maybe<Scalars['String']>;
};

export type updateRiskPayload = {
  __typename?: 'updateRiskPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  risk?: Maybe<Risk>;
};

export type updateSlackSubscriptionInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  connectionUuid: Scalars['String'];
  metadata?: Maybe<updateSlackSubscriptionInputMetadata>;
  slackTeamId: Scalars['String'];
  types: Array<Scalars['String']>;
};

export type updateSlackSubscriptionInputMetadata = {
  fieldTypes: Array<Scalars['String']>;
};

export type updateSlackSubscriptionPayload = {
  __typename?: 'updateSlackSubscriptionPayload';
  data?: Maybe<updateSlackSubscriptionPayloadResponse>;
  error?: Maybe<Scalars['String']>;
};

export type updateSlackSubscriptionPayloadMetadata = {
  __typename?: 'updateSlackSubscriptionPayloadMetadata';
  fieldTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type updateSlackSubscriptionPayloadResponse = {
  __typename?: 'updateSlackSubscriptionPayloadResponse';
  clientMutationId?: Maybe<Scalars['String']>;
  subscription?: Maybe<updateSlackSubscriptionPayloadSubscription>;
};

export type updateSlackSubscriptionPayloadSubscription = {
  __typename?: 'updateSlackSubscriptionPayloadSubscription';
  creationDate: Scalars['String'];
  metadata?: Maybe<updateSlackSubscriptionPayloadMetadata>;
  resourceAri?: Maybe<Scalars['String']>;
  resourceId: Scalars['String'];
  resourceType: Scalars['String'];
  resourceUuid?: Maybe<Scalars['String']>;
  slackChannelId: Scalars['String'];
  slackTeamId: Scalars['String'];
  subscriberAaid: Scalars['String'];
  types?: Maybe<Array<Maybe<Scalars['String']>>>;
  updatedAt?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  workspace?: Maybe<updateSlackSubscriptionPayloadWorkspace>;
};

export type updateSlackSubscriptionPayloadWorkspace = {
  __typename?: 'updateSlackSubscriptionPayloadWorkspace';
  cloudId: Scalars['String'];
  organisationId: Scalars['String'];
  uuid: Scalars['String'];
};

export enum UpdateStateFilter {
  Active = 'ACTIVE',
  AtRisk = 'AT_RISK',
  Cancelled = 'CANCELLED',
  Done = 'DONE',
  NoUpdate = 'NO_UPDATE',
  OffTrack = 'OFF_TRACK',
  OnTrack = 'ON_TRACK',
  Paused = 'PAUSED',
  Pending = 'PENDING'
}

export type updateTeamHealthMonitorStatusInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitorStatus: TeamHealthMonitorStatus;
  teamHealthMonitorUUID: Scalars['UUID'];
};

export type updateTeamHealthMonitorStatusPayload = {
  __typename?: 'updateTeamHealthMonitorStatusPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  teamHealthMonitor?: Maybe<TeamHealthMonitor>;
};

export enum UpdateType {
  System = 'SYSTEM',
  User = 'USER'
}

export type updateUserOrganizationPreferenceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  orgId: Scalars['String'];
};

export type updateUserOrganizationPreferencePayload = {
  __typename?: 'updateUserOrganizationPreferencePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  organization?: Maybe<Organization>;
};

export type updateUserPreferencesGloballyInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  emailReminderEnabled?: Maybe<Scalars['Boolean']>;
  emailSummaryEnabled?: Maybe<Scalars['Boolean']>;
  msteamsReminderEnabled?: Maybe<Scalars['Boolean']>;
  msteamsSummaryEnabled?: Maybe<Scalars['Boolean']>;
  shortcutsType?: Maybe<ShortcutsTypeEnum>;
  slackReminderEnabled?: Maybe<Scalars['Boolean']>;
  slackSummaryEnabled?: Maybe<Scalars['Boolean']>;
};

export type updateUserPreferencesGloballyPayload = {
  __typename?: 'updateUserPreferencesGloballyPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<UserPreferences>;
};

export type updateUserPreferencesInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  emailReminderEnabled?: Maybe<Scalars['Boolean']>;
  emailSummaryEnabled?: Maybe<Scalars['Boolean']>;
  msteamsReminderEnabled?: Maybe<Scalars['Boolean']>;
  msteamsSummaryEnabled?: Maybe<Scalars['Boolean']>;
  slackReminderEnabled?: Maybe<Scalars['Boolean']>;
  slackSummaryEnabled?: Maybe<Scalars['Boolean']>;
};

export type updateUserPreferencesPayload = {
  __typename?: 'updateUserPreferencesPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type updateUserShortcutsPreferenceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  shortcutsType: ShortcutsTypeEnum;
};

export type updateUserShortcutsPreferencePayload = {
  __typename?: 'updateUserShortcutsPreferencePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type updateUserTagInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  matchable: Scalars['Boolean'];
  tagUuid: Scalars['UUID'];
};

export type updateUserTagPayload = {
  __typename?: 'updateUserTagPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<User>;
};

export type updateUserWorkspacePreferenceInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  workspaceId: Scalars['ID'];
};

export type updateUserWorkspacePreferencePayload = {
  __typename?: 'updateUserWorkspacePreferencePayload';
  clientMutationId?: Maybe<Scalars['String']>;
  result?: Maybe<Workspace>;
};

export enum UpgradeRequestVariation {
  AtlasPremium = 'ATLAS_PREMIUM',
  CustomFields = 'CUSTOM_FIELDS',
  Generic = 'GENERIC',
  GoalScoringMode = 'GOAL_SCORING_MODE',
  PrivateProjects = 'PRIVATE_PROJECTS'
}



export type User = Node & {
  __typename?: 'User';
  aaid?: Maybe<Scalars['String']>;
  adminOrgs?: Maybe<AdminUserOrganizationConnection>;
  /** @deprecated use preferences object instead */
  emailReminderEnabled: Scalars['Boolean'];
  /** @deprecated use preferences object instead */
  emailSummaryEnabled: Scalars['Boolean'];
  goals?: Maybe<GoalConnection>;
  goalsWatching?: Maybe<GoalConnection>;
  id: Scalars['ID'];
  memberOfProjects?: Maybe<ProjectConnection>;
  /** @deprecated use preferences object instead */
  msteamsReminderEnabled: Scalars['Boolean'];
  /** @deprecated use preferences object instead */
  msteamsSummaryEnabled: Scalars['Boolean'];
  msteamsTenants?: Maybe<Array<Maybe<TenantResponseBody>>>;
  onboarded: Scalars['Boolean'];
  onboarding?: Maybe<OnboardingConnection>;
  pii?: Maybe<UserPII>;
  preferences?: Maybe<UserPreferences>;
  projects?: Maybe<ProjectConnection>;
  /** @deprecated use preferences object instead */
  shortcutsType: ShortcutsTypeEnum;
  /** @deprecated use preferences object instead */
  slackReminderEnabled: Scalars['Boolean'];
  /** @deprecated use preferences object instead */
  slackSummaryEnabled: Scalars['Boolean'];
  slackTeams?: Maybe<Array<Maybe<TeamResponseBody>>>;
  tags?: Maybe<UserTagConnection>;
  tagsWatching?: Maybe<TagConnection>;
  teams?: Maybe<TeamConnection>;
  teamsWatching?: Maybe<TeamConnection>;
  watching?: Maybe<ProjectConnection>;
};


export type User_adminOrgsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type User_goalsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId: Scalars['ID'];
};


export type User_goalsWatchingArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId: Scalars['ID'];
};


export type User_memberOfProjectsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type User_onboardingArgs = {
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<OnboardingFilterInput>;
  first?: Maybe<Scalars['Int']>;
};


export type User_projectsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type User_tagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type User_tagsWatchingArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type User_teamsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  organisationId?: Maybe<Scalars['String']>;
  workspaceId?: Maybe<Scalars['ID']>;
};


export type User_teamsWatchingArgs = {
  after?: Maybe<Scalars['String']>;
  cloudId?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};


export type User_watchingArgs = {
  after?: Maybe<Scalars['String']>;
  filter?: Maybe<ProjectWatchingFilter>;
  first?: Maybe<Scalars['Int']>;
  workspaceId?: Maybe<Scalars['ID']>;
};

export type UserAccessStatus = {
  __typename?: 'UserAccessStatus';
  pendingInvites?: Maybe<Array<Maybe<PendingInviteStatus>>>;
};

export type UserCapabilities = {
  __typename?: 'UserCapabilities';
  capabilities?: Maybe<Array<Maybe<Capability>>>;
};

export type UserConnection = {
  __typename?: 'UserConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<UserEdge>>>;
  pageInfo: PageInfo;
};

export type UserCustomField = CustomFieldNode & Node & {
  __typename?: 'UserCustomField';
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  definition?: Maybe<CustomFieldDefinition>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  uuid?: Maybe<Scalars['UUID']>;
  values?: Maybe<UserConnection>;
};


export type UserCustomField_valuesArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type UserCustomFieldDefinition = CustomFieldDefinitionNode & Node & {
  __typename?: 'UserCustomFieldDefinition';
  canSetMultipleValues?: Maybe<Scalars['Boolean']>;
  creationDate?: Maybe<Scalars['DateTime']>;
  creator?: Maybe<User>;
  description?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  lastModifiedDate?: Maybe<Scalars['DateTime']>;
  linkedEntityTypes?: Maybe<Array<CustomFieldEntity>>;
  name?: Maybe<Scalars['String']>;
  token?: Maybe<Scalars['String']>;
  type?: Maybe<CustomFieldType>;
  uuid?: Maybe<Scalars['UUID']>;
};

export type UserEdge = {
  __typename?: 'UserEdge';
  cursor: Scalars['String'];
  node?: Maybe<User>;
};

export type UserIdentifierInput = {
  accountId?: Maybe<Scalars['String']>;
  addAsWatcher?: Maybe<Scalars['Boolean']>;
  createPendingWorkspaceMembership: Scalars['Boolean'];
};

export type UserInvite = Node & {
  __typename?: 'UserInvite';
  applied: Scalars['Boolean'];
  creationDate?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  invitedAs?: Maybe<InviteTypeEnum>;
  invitedBy?: Maybe<User>;
  invitedTo?: Maybe<Scalars['ID']>;
  siteInviteState?: Maybe<SiteInviteState>;
  updatedDate?: Maybe<Scalars['DateTime']>;
};

export type UserPII = {
  __typename?: 'UserPII';
  accountId?: Maybe<Scalars['String']>;
  accountStatus?: Maybe<AccountStatus>;
  accountType?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  extendedProfile?: Maybe<ExtendedProfile>;
  locale?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nickname?: Maybe<Scalars['String']>;
  orgId?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  zoneinfo?: Maybe<Scalars['String']>;
};

export type UserPIIConnection = {
  __typename?: 'UserPIIConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<UserPIIEdge>>>;
  pageInfo: PageInfo;
};

export type UserPIIEdge = {
  __typename?: 'UserPIIEdge';
  cursor: Scalars['String'];
  node?: Maybe<UserPII>;
};

export type UserPreferences = {
  __typename?: 'UserPreferences';
  emailReminderEnabled: Scalars['Boolean'];
  emailSummaryEnabled: Scalars['Boolean'];
  id: Scalars['ID'];
  msteamsReminderEnabled: Scalars['Boolean'];
  msteamsSummaryEnabled: Scalars['Boolean'];
  shortcutsType: ShortcutsTypeEnum;
  slackReminderEnabled: Scalars['Boolean'];
  slackSummaryEnabled: Scalars['Boolean'];
};

export type UserTag = Node & {
  __typename?: 'UserTag';
  created?: Maybe<Scalars['DateTime']>;
  id: Scalars['ID'];
  matchable: Scalars['Boolean'];
  tag?: Maybe<Tag>;
  user?: Maybe<User>;
};

export type UserTagConnection = {
  __typename?: 'UserTagConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<UserTagEdge>>>;
  pageInfo: PageInfo;
};

export type UserTagEdge = {
  __typename?: 'UserTagEdge';
  cursor: Scalars['String'];
  node?: Maybe<UserTag>;
};

export type UserViews = Node & {
  __typename?: 'UserViews';
  containerId?: Maybe<Scalars['String']>;
  id: Scalars['ID'];
  objectId: Scalars['String'];
  objectType?: Maybe<ViewObjectType>;
  viewCount?: Maybe<Scalars['Int']>;
  viewed?: Maybe<Scalars['Boolean']>;
  viewers?: Maybe<ViewerConnection>;
};


export type UserViews_viewersArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type UserWorkspaceByContextResponse = {
  __typename?: 'UserWorkspaceByContextResponse';
  requestedContextMatchedType?: Maybe<WorkspaceContextType>;
  workspace?: Maybe<Workspace>;
};

export type UserWorkspacePermissions = {
  __typename?: 'UserWorkspacePermissions';
  admin: Scalars['Boolean'];
  billingAdmin: Scalars['Boolean'];
};


export type Viewer = Node & {
  __typename?: 'Viewer';
  aaid: Scalars['String'];
  id: Scalars['ID'];
  pii?: Maybe<UserPII>;
  user?: Maybe<User>;
};

export type ViewerConnection = {
  __typename?: 'ViewerConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<ViewerEdge>>>;
  pageInfo: PageInfo;
};

export type ViewerEdge = {
  __typename?: 'ViewerEdge';
  cursor: Scalars['String'];
  node?: Maybe<Viewer>;
};

export type viewInput = {
  clientMutationId?: Maybe<Scalars['String']>;
  containerId: Scalars['String'];
  objectId: Scalars['String'];
  objectType: ViewObjectType;
  workspaceUuid: Scalars['UUID'];
};

export enum ViewObjectType {
  GoalUpdate = 'GOAL_UPDATE',
  ProjectUpdate = 'PROJECT_UPDATE'
}

export type viewPayload = {
  __typename?: 'viewPayload';
  clientMutationId?: Maybe<Scalars['String']>;
  views?: Maybe<UserViews>;
};

export type Workspace = Node & {
  __typename?: 'Workspace';
  activationId?: Maybe<Scalars['String']>;
  aiConfig?: Maybe<WorkspaceAIConfig>;
  archivedGlobally?: Maybe<Scalars['Boolean']>;
  cloudId: Scalars['String'];
  cloudName?: Maybe<Scalars['String']>;
  cloudUrl?: Maybe<Scalars['String']>;
  creationDate: Scalars['DateTime'];
  currentUserAppCapabilities?: Maybe<UserCapabilities>;
  featureContext?: Maybe<FeatureContext>;
  goalScoringMode: GoalScoringMode;
  hasWorkspaceOnboarded?: Maybe<Scalars['Boolean']>;
  id: Scalars['ID'];
  keyNumber: Scalars['Int'];
  keyPrefix: Scalars['String'];
  mediaClientId: Scalars['String'];
  name: Scalars['String'];
  openInviteSetting?: Maybe<OpenInviteModeResponse>;
  organisationId: Scalars['String'];
  site?: Maybe<Site>;
  tags?: Maybe<TagConnection>;
  timezone: Scalars['String'];
  /** @deprecated only for internal team use */
  type: WorkspaceType;
  userHasInvitePermission?: Maybe<Scalars['Boolean']>;
  userIsOrgAdmin: Scalars['Boolean'];
  userWorkspacePermissions?: Maybe<UserWorkspacePermissions>;
  uuid: Scalars['UUID'];
};


export type Workspace_tagsArgs = {
  after?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
};

export type WorkspaceAIConfig = {
  __typename?: 'WorkspaceAIConfig';
  enabled: Scalars['Boolean'];
};

export type WorkspaceByContextAccessDeniedResponse = {
  __typename?: 'WorkspaceByContextAccessDeniedResponse';
  reason?: Maybe<Scalars['String']>;
};

export type WorkspaceByContextErrorResponse = {
  __typename?: 'WorkspaceByContextErrorResponse';
  reason?: Maybe<Scalars['String']>;
};

export type WorkspaceByContextProductRecommendationResponse = {
  __typename?: 'WorkspaceByContextProductRecommendationResponse';
  cloudId?: Maybe<Scalars['String']>;
};

export type WorkspaceByContextResponse = WorkspaceByContextAccessDeniedResponse | WorkspaceByContextErrorResponse | WorkspaceByContextProductRecommendationResponse | WorkspaceByContextSuccessfulResponse;

export type WorkspaceByContextSuccessfulResponse = {
  __typename?: 'WorkspaceByContextSuccessfulResponse';
  workspace?: Maybe<Workspace>;
  workspaceCount?: Maybe<Scalars['Int']>;
};

export type WorkspaceConnection = {
  __typename?: 'WorkspaceConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<WorkspaceEdge>>>;
  pageInfo: PageInfo;
};

export type WorkspaceContextInput = {
  contextType: WorkspaceContextType;
  identifier?: Maybe<Scalars['String']>;
};

export enum WorkspaceContextType {
  Default = 'Default',
  DirectoryViewUuid = 'DirectoryViewUuid',
  GoalKey = 'GoalKey',
  ProjectKey = 'ProjectKey',
  TagId = 'TagId',
  TagUuid = 'TagUuid',
  WorkspaceCloudId = 'WorkspaceCloudId',
  WorkspaceIdentifier = 'WorkspaceIdentifier',
  WorkspaceInOrgByCloudId = 'WorkspaceInOrgByCloudId'
}

export type WorkspaceEdge = {
  __typename?: 'WorkspaceEdge';
  cursor: Scalars['String'];
  node?: Maybe<Workspace>;
};

export type WorkspaceFeatures = {
  __typename?: 'WorkspaceFeatures';
  customFieldsLimit: Scalars['Int'];
  goalScoringMode: Scalars['Boolean'];
  privateProjects: Scalars['Boolean'];
};

export type WorkspaceMember = Node & {
  __typename?: 'WorkspaceMember';
  id: Scalars['ID'];
  status?: Maybe<WorkspaceMembershipStatusType>;
  type?: Maybe<WorkspaceMemberType>;
  user?: Maybe<User>;
  workspace?: Maybe<Workspace>;
};

export type WorkspaceMemberConnection = {
  __typename?: 'WorkspaceMemberConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<WorkspaceMemberEdge>>>;
  pageInfo: PageInfo;
};

export type WorkspaceMemberEdge = {
  __typename?: 'WorkspaceMemberEdge';
  cursor: Scalars['String'];
  node?: Maybe<WorkspaceMember>;
};

export enum WorkspaceMembershipStatusType {
  Active = 'ACTIVE',
  None = 'NONE',
  Pending = 'PENDING'
}

export enum WorkspaceMemberType {
  User = 'user'
}

export type WorkspaceSummary = Node & {
  __typename?: 'WorkspaceSummary';
  activationId?: Maybe<Scalars['String']>;
  cloudId: Scalars['String'];
  cloudName?: Maybe<Scalars['String']>;
  goalScoringMode: GoalScoringMode;
  id: Scalars['ID'];
  keyPrefix: Scalars['String'];
  name: Scalars['String'];
  organisationId: Scalars['String'];
  timezone: Scalars['String'];
  /** @deprecated only for internal team use */
  type: WorkspaceType;
  userCapabilities?: Maybe<UserCapabilities>;
  uuid: Scalars['UUID'];
};

export type WorkspaceSummaryConnection = {
  __typename?: 'WorkspaceSummaryConnection';
  count: Scalars['Int'];
  edges?: Maybe<Array<Maybe<WorkspaceSummaryEdge>>>;
  pageInfo: PageInfo;
};

export type WorkspaceSummaryEdge = {
  __typename?: 'WorkspaceSummaryEdge';
  cursor: Scalars['String'];
  node?: Maybe<WorkspaceSummary>;
};

export enum WorkspaceType {
  GlobalExperience = 'GLOBAL_EXPERIENCE',
  Product = 'PRODUCT'
}
