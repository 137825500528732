import {
  formatIsoLocalDateTime,
  getUtcEndOfGoalMonth,
  getUtcEndOfWeekDate,
  parseGoalUpdatesFeedStartDate,
  parseProjectUpdatesFeedStartDate,
} from '@townsquare/date-utils';
import { convertQueryParamToUpdateStateFilter, getValidUpdateQueryParamFilter } from '@townsquare/home-view';
import { createRelayResource } from '@townsquare/relay-utils';
import { ResourceContext } from '@townsquare/router';

import ReadingModeFullScreenQuery, {
  type ReadingModeFullScreenQuery as ReadingModeFullScreenQueryType,
} from '../ui/__generated__/ReadingModeFullScreenQuery.graphql';

export const readingModeResource = createRelayResource<ReadingModeFullScreenQueryType>({
  getQuery: (routerContext, resourceContext) => {
    const context = resourceContext as ResourceContext;
    const updateType = routerContext.match.params.updateType ?? '';
    const isProjectUpdates = updateType === 'projects';
    const startQueryParam = routerContext.query.start;

    let startDate;
    let endDate;

    if (isProjectUpdates) {
      startDate = parseProjectUpdatesFeedStartDate(startQueryParam);
      endDate = getUtcEndOfWeekDate(startDate);
    } else {
      startDate = parseGoalUpdatesFeedStartDate(startQueryParam);
      endDate = getUtcEndOfGoalMonth(startDate);
    }

    const filter = routerContext.query.filter
      ? convertQueryParamToUpdateStateFilter(getValidUpdateQueryParamFilter(routerContext.query.filter))
      : undefined;

    return {
      query: ReadingModeFullScreenQuery,
      variables: {
        isProjectUpdates: isProjectUpdates,
        stateFilter: filter,
        createdAfter: formatIsoLocalDateTime(startDate),
        createdBefore: formatIsoLocalDateTime(endDate),
        workspaceUUID: context.workspace.UUID ?? '',
      },
      options: {
        fetchPolicy: 'store-and-network',
      },
    };
  },
});
